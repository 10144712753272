import { AuthApi } from '@/api'
import {
  PERSONAL_INFO,
  UPDATE_PERSONAL_INFO,
  IS_PERSONAL_INFO_PENDING,
  SET_IS_PERSONAL_INFO_PENDING,
  DROP_PERSONAL_INFO,
  IS_LOGGED,
  LACKS_INFO,
  UserInfo,
  USER_EMAIL,
  SET_USER_EMAIL,
  IS_EMPTY_USER_ADDRESS,
  MUST_CHANGE_PASSWORD,
  SET_MUST_CHANGE_PASSWORD,
  HAS_DEPOSIT,
  SET_HAS_DEPOSIT,
} from '@/constants'
import {
  pipe,
  path,
  when,
  isPlainObject,
  isEmpty,
  isNil,
  isEmptyOrNil,
} from '@/helpers'

const DATA = 'data'

export const user = {
  namespaced: true,
  state: {
    [USER_EMAIL]: null,
    [PERSONAL_INFO]: {},
    [IS_PERSONAL_INFO_PENDING]: false,
    [MUST_CHANGE_PASSWORD]: false,
    [HAS_DEPOSIT]: false,
  },
  mutations: {
    [UPDATE_PERSONAL_INFO](state, { value }) {
      state[PERSONAL_INFO] = value
    },
    [SET_IS_PERSONAL_INFO_PENDING](state, { value }) {
      state[IS_PERSONAL_INFO_PENDING] = value
    },
    [SET_USER_EMAIL](state, { email }) {
      state[USER_EMAIL] = email
    },
    [SET_MUST_CHANGE_PASSWORD](state, value) {
      state[MUST_CHANGE_PASSWORD] = value
    },
    [SET_HAS_DEPOSIT](state, value) {
      state[HAS_DEPOSIT] = value
    },
  },
  actions: {
    async [UPDATE_PERSONAL_INFO](context) {
      try {
        context.commit(SET_IS_PERSONAL_INFO_PENDING, { value: true })

        const response = await AuthApi.getUserInfo()

        pipe(
          path([DATA]),
          when(isPlainObject, (data) => {
            context.commit(UPDATE_PERSONAL_INFO, {
              value: { ...data?.address, ...data?.user },
            })
            context.commit(SET_IS_PERSONAL_INFO_PENDING, { value: false })
          }),
        )(response)
      } catch (e) {
        console.dir(e)
      }
    },
    [SET_USER_EMAIL](context, { email }) {
      context.commit(SET_USER_EMAIL, { email })
    },

    [DROP_PERSONAL_INFO](context) {
      context.commit(SET_USER_EMAIL, { email: null })
      context.commit(UPDATE_PERSONAL_INFO, { value: {} })
    },
  },
  getters: {
    [IS_LOGGED]: (state) => {
      return !isEmpty(state[PERSONAL_INFO])
    },
    [LACKS_INFO]: (state) =>
      Object.values(UserInfo).some((item) => isNil(state[PERSONAL_INFO][item])),
    [IS_EMPTY_USER_ADDRESS]: (state) => {
      return isEmptyOrNil(state[PERSONAL_INFO]?.[UserInfo.ADDRESS])
    },
  },
}
