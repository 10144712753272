import { Digit } from '@/constants'

export const setCalendarList = ({ start = Digit.ONE, end }) => {
  const list = []

  if (start < end) {
    for (let item = start; item <= end; item++) {
      list.push(String(item))
    }
  }
  if (start > end) {
    for (let item = start; item >= end; item--) {
      list.push(String(item))
    }
  }

  return list
}
