import { Digit, DateFormat } from '@/constants'
import { formatDate, isEmptyOrNil, isEmpty, callFn } from '@/helpers'
import { formMixin } from './formMixin'

const name = {
  DATE_FROM: 'dateFrom',
  DATE_TO: 'dateTo',
}
const sevenDaysAgoDate = new Date(
  Date.now() -
    Digit.SEVEN *
      Digit.TWENTY_FOUR *
      Digit.SIXTY *
      Digit.SIXTY *
      Digit.ONE_THOUSAND,
)
const formData = {
  [name.DATE_FROM]: formatDate({
    format: DateFormat.YYYY_MM_DD,
    date: sevenDaysAgoDate,
  }),
  [name.DATE_TO]: formatDate({
    format: DateFormat.YYYY_MM_DD,
    date: Date.now(),
  }),
}

export const periodFormMixin = {
  mixins: [formMixin],
  data: () => ({
    formData: { ...formData },
    list: null,
    pagination: {
      page: Digit.ONE,
      limit: Digit.FIVE,
      total: Digit.NOUGHT,
    },
  }),
  computed: {
    name: () => name,
    request() {
      return {
        [name.DATE_FROM]: this.formData[name.DATE_FROM],
        [name.DATE_TO]: this.formData[name.DATE_TO],
      }
    },
    isEmptyList() {
      return !this.isSubmitting && isEmpty(this.list)
    },
    rendersTable() {
      return !this.isSubmitting && !isEmptyOrNil(this.list)
    },
  },
  watch: {
    formData: {
      deep: true,
      handler() {
        this.message && (this.message = null)
      },
    },
  },
  methods: {
    async getData({ action, args = {} }) {
      this.isSubmitting = true

      try {
        const { state, data, msg } = await callFn(action, {
          dateFrom: this.formData[this.name.DATE_FROM],
          dateTo: this.formData[this.name.DATE_TO],
          page: this.pagination.page,
          limit: this.pagination.limit,
          ...args,
        })

        if (state === this.responseState.OK) {
          this.list = data?.items
          this.pagination = {
            page: data?.currentPage,
            limit: data?.itemsPerPage,
            total: data?.total,
          }
        }

        if (this.responseState.ERROR === state && msg) {
          this.message = msg
        }
      } catch (error) {
        console.dir(error)
      }

      this.isSubmitting = false
    },
  },
}
