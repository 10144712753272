import {
  CURRENT_LOCATION,
  PREVIOUS_LOCATION,
  UPDATE_LOCATION,
  IS_LOGIN_PAGE,
  RouteName,
  SLASH,
  Module,
  IS_LOGGED,
  EMPTY_STRING,
  SEARCH_QUERY,
} from '@/constants'
import { dest } from '@/helpers'
import { AuthApi } from '@/api'

export const location = {
  namespaced: true,
  state: {
    [CURRENT_LOCATION]: { fullPath: SLASH, params: {}, path: SLASH, query: {} },
    [PREVIOUS_LOCATION]: {},
  },
  mutations: {
    [UPDATE_LOCATION](state, { value }) {
      state[PREVIOUS_LOCATION] = value.from
      state[CURRENT_LOCATION] = value.to
    },
  },
  actions: {
    async [UPDATE_LOCATION]({ commit, rootGetters }, { value }) {
      const isLogged = rootGetters[dest([Module.USER, IS_LOGGED])]

      isLogged &&
        (await AuthApi.postBrowserHistory({ path: value.to.fullPath }))
      commit(UPDATE_LOCATION, { value })
    },
  },
  getters: {
    [IS_LOGIN_PAGE]: (state) => {
      const { name } = state[CURRENT_LOCATION] || {}
      return name === RouteName.LOGIN || name === RouteName.REGISTRAION_CONFIRM
    },
    [SEARCH_QUERY]: (state) => {
      const { name, query } = state[CURRENT_LOCATION] || {}

      return (name === RouteName.SEARCH_RESULT && query?.search) || EMPTY_STRING
    },
  },
}
