import { isPlainObject } from './isPlainObject'
import { curry } from '../basic/curry'

const _mergeDeep = (source, target) => {
  let output = Object.assign({}, target)
  if (isPlainObject(target) && isPlainObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isPlainObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] })
        else output[key] = mergeDeep(target[key], source[key])
      } else {
        Object.assign(output, { [key]: source[key] })
      }
    })
  }
  return output
}

const _mergeDeepLeft = (target, source) => _mergeDeep(source, target)
export const mergeDeep = curry(_mergeDeep)
export const mergeDeepLeft = curry(_mergeDeepLeft)
