import { curry } from "../basic/curry";

const _prop = (property, object) => {
  return object[property];
};

/**
 * Returns a function that when supplied an object returns the indicated property of that object, if it exists.
 * prop('x', {x: 100}); //=> 100
 * prop('x', {}); //=> undefined
 * prop(0, [100]); //=> 100

 * @param {string} property field name which will be returned from the given object if exists;                           
 * @param {object} object an object, whose specified property value will be returned 
 * @returns {any} value of the specified property from the object.
 *
 * The function is curried.
 */

export const prop = curry(_prop);
