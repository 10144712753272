import axios from 'axios'
import { interceptUrl, interceptResponse, interceptError } from './interceptors'

const createApi = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    withCredentials: true,
  })

  axiosInstance.interceptors.request.use(interceptUrl)
  axiosInstance.interceptors.response.use(interceptResponse, interceptError)

  return axiosInstance
}

export const api = createApi()
