import { callFn } from './callFn'
import { Digit } from '@/constants'

const raf =
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.oRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  function (callback) {
    return window.setTimeout(callback, Digit.ONE_THOUSAND / Digit.SIXTY)
  }

const caf =
  window.cancelAnimationFrame ||
  window.webkitCancelAnimationFrame ||
  window.mozCancelAnimationFrame ||
  window.oCancelAnimationFrame ||
  window.msCancelAnimationFrame ||
  function (id) {
    window.clearTimeout(id)
  }

export const requestInterval = function (fn, delay) {
  const dateNow = Date.now

  let start = dateNow()
  let stop
  const intervalFunc = function () {
    dateNow() - start < delay || ((start += delay), callFn(fn))

    if (stop) {
      caf(intervalFunc)
      return
    }
    raf(intervalFunc)
  }

  raf(intervalFunc)
  return {
    clear: function () {
      stop = true
    },
  }
}

export const cancelTimeout = (frame) => caf(frame.id)

export const requestTimeout = (callback, delay) => {
  let start
  Promise.resolve().then(() => {
    start = Date.now()
  })

  const timeout = () => {
    if (Date.now() - start >= delay) {
      callback.call()
    } else {
      frame.id = raf(timeout)
    }
  }

  const frame = {
    id: raf(timeout),
  }

  return frame
}
