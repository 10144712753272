import { UPDATE_LOCATION, Module } from '@/constants'
import { dispatchAction } from './dispatchAction'
import { pick } from '../pipeline/object/pick'

const NAME = 'name'
const PATH = 'path'
const PARAMS = 'params'
const QUERY = 'query'
const FULL_PATH = 'fullPath'

export const updateLocation = ({ to, from }) => {
  dispatchAction([Module.LOCATION, UPDATE_LOCATION], {
    value: {
      from: pick([NAME, PATH, PARAMS, QUERY, FULL_PATH], from),
      to: pick([NAME, PATH, PARAMS, QUERY, FULL_PATH], to),
    },
  })
}
