import {
  USER_NOTIFICATION_LIST,
  UPDATE_USER_NOTIFICATION_LIST,
  IS_USER_NOTIFICATION_LIST_PENDING,
  SET_IS_USER_NOTIFICATION_LIST_PENDING,
  DROP_USER_NOTIFICATION,
} from '@/constants'

const notificationInitialValue = null

export const userNotification = {
  namespaced: true,
  state: {
    [USER_NOTIFICATION_LIST]: notificationInitialValue,
    [IS_USER_NOTIFICATION_LIST_PENDING]: false,
  },
  mutations: {
    [UPDATE_USER_NOTIFICATION_LIST](state, { value }) {
      state[USER_NOTIFICATION_LIST] = value
    },
    [SET_IS_USER_NOTIFICATION_LIST_PENDING](state, { value }) {
      state[IS_USER_NOTIFICATION_LIST_PENDING] = value
    },
  },
  actions: {
    //TODO: it can be refactered without action, only mutation.
    [DROP_USER_NOTIFICATION](context) {
      context.commit(UPDATE_USER_NOTIFICATION_LIST, {
        value: notificationInitialValue,
      })
    },
  },
}
