import { GameApi } from '@/api'
import {
  RECENT_GAME_DICTIONARY,
  UPDATE_RECENT_GAME_DICTIONARY,
  IS_RECENT_GAME_LIST_PENDING,
  SET_IS_RECENT_GAME_LIST_PENDING,
  ResponseState,
  LAST_LOADED_PAGE,
  PAGE_TOTAL,
  HAS_MORE_PAGES,
  RECENT_GAME_TOTAL,
  RECENT_GAME_DICTIONARY_BY_ID,
  ITEMS_PER_PAGE,
  RECENT_GAME_STATIC_PENDING_LIST,
  RESET_RECENT_GAME_DICTIONARY,
  EMPTY_OBJECT,
  URL_SLUG,
  ID,
  Digit,
} from '@/constants'
import {
  gameDictionaryInitialValue,
  isGamePendingInitialValue,
  gameTotalInitialValue,
  lastLoadedPageInitialValue,
  pageTotalInitialValue,
  itemsPerPageInitialValue,
  staticPendingListInitialValue,
} from '@/values'
import { indexBy, pipe, path, map, populateNewArray, isNil } from '@/helpers'
import { GameModel } from '@/models/Game'

export const recentGame = {
  namespaced: true,
  state: {
    [RECENT_GAME_DICTIONARY]: gameDictionaryInitialValue,
    [IS_RECENT_GAME_LIST_PENDING]: isGamePendingInitialValue,
    [RECENT_GAME_TOTAL]: gameTotalInitialValue,
    [LAST_LOADED_PAGE]: lastLoadedPageInitialValue,
    [PAGE_TOTAL]: pageTotalInitialValue,
    [ITEMS_PER_PAGE]: itemsPerPageInitialValue,
    [RECENT_GAME_STATIC_PENDING_LIST]: staticPendingListInitialValue,
  },
  mutations: {
    [UPDATE_RECENT_GAME_DICTIONARY](state, payload) {
      state[RECENT_GAME_DICTIONARY] = {
        ...(isNil(state[RECENT_GAME_DICTIONARY])
          ? EMPTY_OBJECT
          : state[RECENT_GAME_DICTIONARY]),
        ...payload[RECENT_GAME_DICTIONARY],
      }
      state[PAGE_TOTAL] = payload[PAGE_TOTAL]
      state[LAST_LOADED_PAGE] = payload[LAST_LOADED_PAGE]
      state[RECENT_GAME_TOTAL] = payload[RECENT_GAME_TOTAL]
      state[ITEMS_PER_PAGE] = payload[ITEMS_PER_PAGE]

      if (payload[RECENT_GAME_STATIC_PENDING_LIST]) {
        state[RECENT_GAME_STATIC_PENDING_LIST] =
          payload[RECENT_GAME_STATIC_PENDING_LIST]
      }
    },
    [SET_IS_RECENT_GAME_LIST_PENDING](state, value) {
      state[IS_RECENT_GAME_LIST_PENDING] = value
    },
    [RESET_RECENT_GAME_DICTIONARY](state) {
      state[RECENT_GAME_DICTIONARY] = gameDictionaryInitialValue
      state[RECENT_GAME_TOTAL] = gameTotalInitialValue
      state[LAST_LOADED_PAGE] = lastLoadedPageInitialValue
      state[PAGE_TOTAL] = pageTotalInitialValue
    },
  },
  actions: {
    async [UPDATE_RECENT_GAME_DICTIONARY](
      { commit, state: store, getters },
      { resetsData } = {},
    ) {
      if (resetsData) {
        commit(RESET_RECENT_GAME_DICTIONARY)
      }
      if (!getters[HAS_MORE_PAGES]) {
        return
      }

      try {
        commit(SET_IS_RECENT_GAME_LIST_PENDING, true)

        const page = store[LAST_LOADED_PAGE] + Digit.ONE
        const { data, state } = await GameApi.getRecentGameList({ page })

        if (state === ResponseState.OK && Array.isArray(data?.items)) {
          const { items, itemsPerPage, total } = data
          const totalNumber = Number(total)
          const itemsPerPageNumber = Number(itemsPerPage)

          const payload = {
            [RECENT_GAME_DICTIONARY]: pipe(
              map(GameModel),
              indexBy(URL_SLUG),
            )(items),
            [PAGE_TOTAL]: Math.ceil(total / itemsPerPage),
            [LAST_LOADED_PAGE]: page,
            [RECENT_GAME_TOTAL]: totalNumber,
            [ITEMS_PER_PAGE]: itemsPerPageNumber,

            ...(store[ITEMS_PER_PAGE] !== itemsPerPageNumber
              ? {
                  [RECENT_GAME_STATIC_PENDING_LIST]: populateNewArray({
                    length: itemsPerPageNumber,
                  }),
                }
              : {}),
          }

          commit(UPDATE_RECENT_GAME_DICTIONARY, payload)
          commit(SET_IS_RECENT_GAME_LIST_PENDING, isGamePendingInitialValue)
        }
      } catch (e) {
        console.dir(e)
      }
    },
  },
  getters: {
    [HAS_MORE_PAGES]: (state) => {
      return state[PAGE_TOTAL] > state[LAST_LOADED_PAGE]
    },
    [RECENT_GAME_DICTIONARY_BY_ID]: (state) => {
      return pipe(
        path([RECENT_GAME_DICTIONARY]),
        Object.values,
        indexBy(ID),
      )(state)
    },
  },
}
