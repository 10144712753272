import { Type } from '../../../constants'

/**
 * @param {any} value
 * @returns {boolean} returns true if the value is of type object but null
 */

export const isPlainObject = (value) => {
  return typeof value === Type.OBJECT && value !== null
}

export const isNotPlainObject = (value) => !isPlainObject(value);
