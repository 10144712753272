import { isPrimitive } from "../basic/isPrimitive";
import { isArray } from "./isArray";

/**
 * @param {any} value
 * @returns {boolean} returns true is the value is an empty array or array with primitives only.
 */

export const isArrayOfPrimitives = (value) => {
  return isArray(value) && value.every(isPrimitive);
};
