import * as ComponentName from '@/constants/componentName'
import * as ModalName from '@/constants/modalName'
import {
  LimitType,
  FORM_ERROR,
  // TITLE,
  Filter,
  FilterByFilter,
  OrderByFilter,
  RouteName,
  GamePlayCategoryEnum,
} from '@/constants'

export const fi = {
  [FORM_ERROR]: {
    field_is_required: 'This field is required.',
    checkbox_must_be_checked: 'This checkbox must be checked.',
    enter_email: 'Please enter a valid email address.',
    password_confirmation_does_not_match_password:
      'The password confirmation does not match the password.',
    password_wrong:
      'Password must be between 6 and 30 characters long and contain at least one number and letters in mixed case.',
    choose_file: 'Please choose a file.',
    must_not_exceed_min: 'The value must be more or equal to allowed minimum',
    must_not_exceed_max: 'The value must be less or equal to allowed maximum',
    must_be_number: 'The value must be number',
  },
  formMixin: {
    something_went_wrong: 'Something went wrong',
  },
  [ComponentName.ABOUT_US]: {
    casino_five: 'CasinoFive',
    a_dream_come_true: 'A dream come true',
    welcome_to_the_exciting_world:
      'Welcome to the exciting world of CasinoFive. Born out of passion and love for the game, run by 5 musketeers with years of iGaming experience and the enthusiasm to match.',
    our_dream_was_born:
      'Our dream was born in 2021 in our official casino idea-hatchery. In the summer of 2022, the time had finally come, and we opened our virtual CasinoFive doors, with big stars in our eyes and maybe even a tear or two',
    casinofive_stands_for: `CasinoFive stands for reliability, respectability and innovation and focuses on first-class slot machines and an ultimate gaming experience. Hundreds of favourite titles from NetEnt, Play'n GO, Yggdrasil, Microgaming, Red Tiger, Big Time Gaming and many more star providers, welcome you in our digital gaming lobby. They continuously provide you as a player with exciting offers, promotions, tournaments, and a constantly growing repertoire of slot games. Player satisfaction is one of our main goals, which is why we are constantly on the lookout for the latest and most exciting online slots. Whether you are a Megaways slot enthusiast, a retro slot lover or rather keen on the most popular modern and/or latest titles, from Book of Dead to Gonzo's Quest, Reactoonz, Monopoly to of course everyone's all-time favourite Starburst, it’s all here! Your virtual gaming experience far surpasses a real casino visit, because only here you can choose from thousands of slot machines in the comfort and safety of your own home.`,
    are_you_on_the_road: `Are you on the road a lot but don't want to miss out on your gaming fun? No problem! We've designed CasinoFive with mobile devices in mind, so you can play on your mobile, tablet or desktop browser while enjoying a seamless, secure gaming experience. Anytime, anywhere!`,
    the_security_of_our_players: `The security of our players is our top priority and is guaranteed at all times, with the help of specific control mechanisms that are open and transparent for everyone. Through strict contracts with our various payment providers and complex encryption processes, our players' data is always protected and shielded from any kind of abuse. Speaking of payment providers, you are naturally spoilt for choice at CasinoFive when it comes to payment methods. We only work with the most trusted, reliable, and secure payment providers. From classic bank transfers, VISA and Mastercard to e-wallets such as Skrill, Neteller and Co., you'll find it all here.`,
    since_service_and_quality: `Since service and quality, along with the safety of our players, form our essential foundation, we place great value on the individual support of our customers. Our professionally trained and supportive customer service is available in various languages, 15 hours a day via live chat. In addition, there is the possibility of e-mail correspondence as well as contact via Facebook. So, if you are looking for targeted and swift problem solving, understanding, competence, reliability, and fast processing times, then our CasinoFive customer service team is your ideal contact.`,
    another_aspect: `Another aspect that is important to us as a responsible gaming provider is the topic of responsible gaming. Particularly, the protection of minors in general is close to our hearts. The minimum age for gambling of any kind is 18 years. For this reason, CasinoFive reserves the right to check details within the legal framework to ensure that minors are excluded from playing.`,
    if_your_thirst: `If your thirst for knowledge is still not satisfied and you would like to learn more about CasinoFive, you can contact our customer service at any time, directly via email or live chat. We look forward to hearing from you.`,
  },
  [ComponentName.ACCOUNT_VERIFICATION]: {
    title: 'Verify Account',
    description_1:
      'You have won and want to payout your winnings? That is not a problem at all!',
    description_2:
      'In order to identify you within the CasinoFive website, and due to official security regulations, we require that you submit a legible copy of your official, photographic ID, as well as a copy of a second document stating your address.',
    description_3:
      'These measures are in place solely for your safety, in order to ensure that no unauthorised third parties can use your access data and that no unauthorised transactions can take place. We ask for your understanding for these security measures.',
    description_4:
      'How does it work? We only need three files. Sounds complicated, but is simple as it gets.',
    subtitle: 'Upload Documents',
    list_1_title: 'Photo ID',
    list_1_item_1: 'Passport',
    list_1_item_2: 'Personal ID card',

    list_2_title: 'Proof of address',
    list_2_subtitle: 'One of the following',
    list_2_item_1:
      'Correspondence from official bodies (stating your first name, surname and address, one site document, no older than 3 months)',
    list_2_item_2:
      'Legible bank statement stating your address (amounts covered, no older than three months)',
    list_2_item_3:
      'Utilities bill (telephone/electricity/gas, no older than eight weeks, no older than three months)',
    list_3_title: 'Verification of payment method',
    list_3_item_1:
      'Legible bank or credit card statement stating your address (amounts covered)',
    list_3_item_2:
      'Copy of your credit or banker’s card (please cover your CVV and the first 12 digits of your credit card number)',
    list_3_item_3:
      'Screenshot of your Skrill or Neteller accounts (stating your name and account ID)',
    upload_doctuments_bttn_label: 'Upload Documents',
  },
  [ComponentName.BREADCRUMBS]: {
    category: 'Category',
    play_now: 'Pelaa nyt',
    new_games: 'Uudet pelit',
    top_games: 'Top Slotti Pelit',
    search: 'Etsi peli',
    home: 'Koti',
    lobby: 'Aula',
    search_result: 'Game: filters',
  },
  [ComponentName.CHANGE_PASSWORD]: {
    my_profile: 'Change Password',
    description:
      'For your own safety, we recommend that you change your password on a regular basis. Simply fill in the following fields. For security reasons, we will send you an e-mail to confirm the change of your password.',
    platin_account_password: 'Platin Account Password',
    current_password: 'Current Password',
    new_password: 'New Password',
    repeat_new_password: 'Repeat New Password',
    update_password: 'Update Password',
  },
  [ComponentName.CLUB_CARD]: {
    id: 'ID:',
    since: 'Since:',
  },
  [ComponentName.COOKIE_ACCEPT]: {
    text_1: 'We are using cookies on this website.',
    text_2: 'If you agree with the use of cookies ',
    text_3: 'and want to continue with all the features of the site,',
    text_4: 'agree to our',
    privacy: 'Privacy',
    cookie_policy: 'Cookie Policy',
    button_label: 'I Agree',
  },
  [ComponentName.DASHBOARD]: {
    balance: 'Balance',
    deposit: 'Deposit',
    platin_points: 'Platin Points',
    redeem: 'Reedem',
    notifications: 'Notifications',
    view: 'View',
    platin_club: 'CasinoFive Club',
    loyalty_program: 'Loyalty Program',
    more: 'More',
    rewards: 'Rewards',
    carnival: 'Carnival',
    free_spins: 'Free Spins',
    special_offer: 'Special Offer',
    last_used_bonus_codes: 'Last used bonus codes:',
    recently_played: 'Recently Played',
    favourites: 'Favourites',
  },
  [ComponentName.DEPOSIT]: {
    deposit: 'Deposit',
    select_deposit_method: 'Select Deposit Method',
    min: 'Min',
    max: 'Max',
    deposit_amount: 'Deposit Amount',
    deposit_details: 'Deposit Details',
    acknowledgement: 'Acknowledgement',
    deposit_method: 'Deposit Method',
    total_available_balance: 'Total Available Balance',
    play_game: 'Start playing',
    amount_to_deposit_euro: 'Amount to Deposit (€)',
    bonus_code: 'Bonus Code',
  },
  [ComponentName.DEPOSIT_HISTORY]: {
    title: 'History of deposit',
    description: 'Detailed list of deposit history',
    filter_deposit: 'Filter Deposit',
    showing_results: 'Showing Results',
    date_from: 'Date From',
    date_to: 'Date to',
    submit: 'Show Results',
    method_name: 'Method name',
    start_date: 'Start date',
    end_date: 'End date',
    currency: 'Currency',
    amount: 'Amount',
    status: 'Status',
    no_deposits: 'There are no deposits to show.',
    bttn_label: 'Show Results',
  },
  [ComponentName.FILE_FIELD]: {
    choose_file: 'Choose File',
  },
  [ComponentName.FOOTER]: {
    text_1: 'CASINO ONLINE GAMES AT CASINOFIVE.COM',
    description_1: `CasinoFive was founded in 2012 and took its games online in 2013. Looking back on its extensive experience in the gaming industry, our team has as its declared goal to bring an exciting and action-packed gaming environment to the player. We only work with top-notch gaming software and deliver all our casino online games in HD. In cooperation with well-known manufacturers we are pleased to exclusively present to you the renowned casino games of Merkur. At CasinoFive, you will enjoy the prickling atmosphere of round-the-clock casino action whatever location you are at.`,
    text_2: 'SLOT MACHINES AND MANY OTHER CASINO GAMES ONLINE',
    description_2: `Next to the traditional standbys of casino games, we at CasinoFive are first and foremost specializing in slot machines. At our site, you’ll find the best-known games by outstanding manufacturers, for example Merkur games. The company has already been well-known due to its presence in brick-and-mortar gaming arcades. Conversely, in the internet, casino online games under that name are offered by a few vendors only. The reason is that the Merkur brand considers only the most reputable casinos online as partners. It follows that a partnership with CasinoFive was a logical next step. At our site, you’ll now can exclusively enjoy the casino online games by Merkur like Double Triple Chance and many more.`,
    text_3: 'CASINO ONLINE GAMES FREE OF CHARGE AND WITHOUT LOGGING IN',
    description_3: `We are eager to always offer you an exciting and action-packed gaming atmosphere with fun as the dominant factor. For these reasons, you’ll be able to play at our site all casino games online free of charge and without the need to log in. Test all games at your leisure and without any risk. Besides, at CasinoFive you’ll benefit from the superior customer service, which will be at your beck and call. Uncompromising transparency, prompt remittances and great bonuses are additional incentives to decide in favor of casino online games by CasinoFive.`,
    text_4:
      'CASINOFIVE IS SURE TO IMPRESS WITH ITS TOP SERVICE AND WIDE RANGE OF GAMES.',
    description_4_paragraph_1: `Another special feature offered to new customers by CasinoFive is a generous bonus programme. The bonus is ideal for online casino newbies, as it’s very transparent and easy to understand. CasinoFive will match your credit top-ups 100% for any payments up to 100 euros. This 100% bonus isn’t just for newbies though; it’s for any new players that want to try out CasinoFive’s wide range of games. Decide on your bonus when registering with CasinoFive and turn your living room into your very own casino!`,
    description_4_paragraph_2: `See? Besides the generous range of online casino games, there are plenty of other attractive reasons to enjoy a special gaming experience at CasinoFive.`,
    footer_left_text: `CasinoFive.com is operated by Red Rhino Limited, a company
    registered in Malta with registration number C67666 and address 6
    Investor House (Suite 3), Triq il-Fikus, San Gwann SGN 2461,
    Malta. Red Rhino Ltd. is licensed and regulated by the Malta
    Gaming Authority (MGA), and holds license number MGA/B2C/398/2017,
    issued on 01/08/2018.`,
    footer_left_ending: '© 2022 Red Rhino Ltd. All Rights reserved.',
    footer_right_text: `Responsibility for your safe and secure gaming environment at
    CasinoFive.com is our commitment and of highest possible
    priority. We want to entertain you and offer fun and excitement.
    But casino gaming always contains risks. Be aware that it is great
    to win, but you could also lose. Play only with money you have
    left to spend. Gaming can be addictive, please play responsibly.`,
    footer_right_ending: `In case of worries or questions concerning gaming behavior, our
    customer support team is there to help you out.`,
    follow_jack_wild: 'Follow Jack Wild',
  },
  [ComponentName.FOOTER_NAVBAR]: {
    deposit: 'Deposit',
    account_balance: 'Account balance:',
    real_money: 'Real money:',
    bonus_money: 'Bonus money:',
  },
  [ComponentName.GAME_BOX_WITH_HOVER]: {
    play_now: 'Play Now',
    try_me: 'Try Me',
    hot: 'Hot',
    new: 'New',
  },
  [ComponentName.GAME_INFO]: {
    start_playing: 'Start Playing',
    free_play: 'Free Play',
    play_for_real: 'Play for Real',
  },
  [ComponentName.GAME_LISTS]: {
    empty_list: 'The game list is empty.',
  },
  [ComponentName.GAME_PLAY]: {
    no_iframe: 'Pelin iframe ei latautunut. Kokeile toista peliä.',
  },
  [ComponentName.GAME_PLAY_NAVIGATION]: {
    [GamePlayCategoryEnum.RECENT]: 'Äskettäin pelattu',
    [GamePlayCategoryEnum.TOP]: 'Top Slotti Pelit',
    [GamePlayCategoryEnum.FAVOURITE]: 'Suosikit',
    [GamePlayCategoryEnum.RECOMMENDED]: 'Suositellaan',
  },
  [ComponentName.GAME_SIDE_MENU]: {
    play_now: 'Pelaa nyt',
    try_me: 'Kokeile minua',
    balance: 'Saldo',
    deposit: 'Tallettaa',
  },
  [ComponentName.HOME]: {
    home: 'Home',
    providers: 'Providers:',
    top_games: 'Top Slotti Pelit',
    recently_played: 'Recently Played',
    favourites: 'Favourites',
    new_games: 'Uudet pelit',
    live_casino: 'Live Casino',
    slots: 'Slots',
  },
  [ComponentName.HORIZONTAL_LIST]: {
    show_all: 'Show All',
    empty_list: 'This list is empty.',
  },
  [ComponentName.MAIN_MENU]: {
    lobby: 'Aula',
    games: 'Pelit',
    games_providers: 'Pelien tarjoajat',
    offers: 'Tarjoukset',
    rewards_carnival: 'Palkinnot karnevaalit',
    platin_club: 'CasinoFive Club',
    casino_five_club: 'CasinoFive Club',
    dashboard: 'Kojelauta',
    deposit: 'Tallettaa',
    platin_coins: 'CasinoFive Coins',
    already_registered: 'Already Registered?',
    register_description:
      'A warm welcome to CasinoFive. Start your journey to happiness with our Welcome Bonus!',
    register: 'Register',
    click_here_to_login: 'Click Here to login',
    login: 'Login',
    support: 'Support',
    information: 'Information',
    logout: 'Logout',
    language: 'Language',
    deposit_and_play: 'Deposit & Play',
  },
  [ComponentName.MONEY_COUNTER]: {
    min: 'Min',
    max: 'Max',
  },
  [ComponentName.MY_ACCOUNT_SIDE_MENU]: {
    my_account: 'My Account',
  },
  [ComponentName.NAVBAR]: {
    [RouteName.OFFERS]: 'Promotions',
    [RouteName.OFFERS_VIEW]: 'Promotion Info',
    [RouteName.REWARD_CARNIVAL]: 'Rewards Carnival',
    [RouteName.USER_ACCOUNT]: 'My Profile',
    [RouteName.USER_ACCOUNT_BALANCE]: 'Account Balance',
    [RouteName.USER_CHANGE_PASSWORD]: 'Password',
    [RouteName.USER_GAME_ROUNDS]: 'Game Rounds',
    [RouteName.USER_TRANSACTIONS]: 'Transactions',
    [RouteName.USER_NEWSLETTER]: 'Newsletter',
    [RouteName.USER_LIMITS]: 'Limits',
    [RouteName.USER_ACCOUNT_VERIFICATION]: 'Verify Account',
    [RouteName.USER_DOCUMENTS]: 'My Documents',
    [RouteName.USER_LIMIT_EDIT]: 'Set Limit',
    [RouteName.USER_SELF_EXCLUSION]: 'Self-exclusion',
    [RouteName.DEPOSIT]: 'Deposit',
    [RouteName.WITHDRAWAL]: 'Withdrawal',
    [RouteName.WITHDRAWAL_ADD]: 'Withdrawal', // 'Withdrawal Add'
  },
  [ComponentName.NAVBAR__ACCOUNT_BALANCE]: {
    add_deposit: 'Add Deposit',
  },
  [ComponentName.NAVBAR__PAY_AND_PLAY_BUTTON]: {
    pay_and_play: 'Maksa ja pelaa',
  },
  [ComponentName.NAVBAR__GAME_PANEL]: {
    add_deposit: 'Lisää talletus',
    createAccount: 'Luo tili',
    login: 'Kirjaudu sisään',
  },
  [ComponentName.NAVBER__LOGIN_BUTTON]: {
    login_btn_label: 'Kirjaudu sisään',
  },
  [ComponentName.NAVBAR__MAIN_MENU_BUTTON]: {
    menu: 'Menu',
  },
  [ComponentName.NAVBAR__REGISTER_BUTTON]: {
    createAccount: 'Luo tili',
    register: 'Rekisteröidy',
  },
  [ComponentName.NAVBAR__SEARCH_INPUT]: {
    play_now: 'Pelaa nyt',
    try_me: 'Kokeile minua',
    browse_games: 'Etsi pelejä',
    show_results_text_1: 'Näytä kaikki',
    show_results_text_2: 'tuloksia',
    no_results: 'Ei tuloksia',
  },
  [ComponentName.PAGE_404]: {
    header: 'Hups, näyttää siltä, että olemme eksyksissä!',
    help_btn_label: 'Tarvitsetko apua?',
    home_btn_label: 'Mene takaisin kotiin',
  },
  [ComponentName.PAGINATOR]: {
    rows_per_page: 'Rivit per sivu:',
    of: 'of',
  },
  [ComponentName.PLATINCLUB]: {
    platinclub_benefits: 'Platinclub benefits',
    our_exclusive_loyalty:
      'our exclusive loyalty program for the CasinoFive players',
    join_the_platinclub: `Join the PlatinClub, our exclusive loyalty program for the CasinoFive
    players. From your very first deposit. What’s in it for you? More and
    more with every status you reach – from extended support and special
    promotions, to the most amazing offers in our REWARDS CARNIVAL shop.
    Review your current status and unlock further benefits right here from
    the Club-Portal. Welcome to the PlatinClub!`,
    vip_experiences: `VIP experiences &\u00A0special promotions`,
    our_platinclub_team: `Our PlatinClub team continuously creates new promotions and special
    benefits for you. From automated credits for reaching the next
    Club-status to very personal and exclusive gifts. Make sure you
    don't miss a reward: register for our marketing offers by newsletter
    today.`,
    platinclub_support: 'Platinclub support',
    our_support_team: `Our Support-Team is always at your side, no matter your status.
    While climbing the status ladder, we offer you additional contact
    options, extended support hours and even your personal PlatinClub
    Concierge for any request.`,
    collect_and_redeem: 'collect and redeem platincoins',
    you_play_we_pay: `You play, we pay! Any real money stake you make on the CasinoFive
    slots will be converted to PlatinCoins. You can use the coins to
    shop in our REWARDS CARNIVAL: bonuses, freegames and many more
    presents. The more you progress through the statuses, you will need
    less bets to collect further coins.`,
    platinclub_status: 'Platinclub status',
    welcome_to_the_platin_club: 'Welcome to the CasinoFive Club!',
    from_your_very_first_deposit: `From your very first deposit we say: Welcome to the PlatinClub! You will
    be starting your status journey from BRONZE with a very nice welcome
    gift to ease your way up. Our promise: the more you play - the more we
    pay! Progress through the statuses ladder all the way up to our mystic
    BLACK status, a luxurious invite-only members clique, with legendary
    prestigious benefits`,
    reached: 'Reached',
    your_status: 'Your Status',
    reach_status_now: 'Reach Status Now',
    the_platin_status_will_be_granted: `*The PLATIN status will be granted on the day after reaching the deposit
    amount of min. € 1,000 in the recent month and is granted until the end
    of the next month. PLATIN status cannot be reached in the month of the
    first ever deposit. To keep the status, the amount must be confirmed in
    every month.`,
    your_benefits_at_a_glance: 'Your benefits at a glance',
    whats_in_it:
      'What’s in it for you? More and more with every status you reach',
    exclusive_and_personal_offers: 'exclusive & personal offers',
    enable_to_stay_updated: 'Enable to stay updated to CasinoFive Club offer',
    name: 'CasinoFive Club',
    yourStatusIcon: 'Your Status Icon',
    platinClubWelcomeGift: 'CasinoFive Club Welcome Gift',
    betAmountForOnePlatinCoin: 'Bet amount for 1 Platin Coin',
    AutomatedGifts: 'Automated Gifts',
    AnniversaryPromotions: 'Anniversary Promotions',
    noDepositBonusWager: 'No Deposit Bonus Wager',
    PreferredWithdrwal: 'Preferred Withdrawal***',
    expiryOfPlatinCoinsAfter: 'Expiry of Platin Coins after',
    conciergeService: 'Concierge Service',
    black: 'Black',
    vip_platin_club: 'VIP CasinoFive Club',
    invite_only: 'Invite Only',
    for_a_minimum_deposit: `* For a minimum deposit of € 10 per week. The freegames will be granted on Book of Dead (bonus money wager 75, max. withdrawable amount € 100)`,
    cashback_is_granted: `** Cashback is granted on the amount of potential real money losses (without a connection to a bonus) within one week (Mon - Sun). The cashback amount has to be wagered only once. The max. withdrawable amount equals the cashback amount.`,
    preferred_wihdrawal_only: `*** Preferred withdrawal only possible with a verified account`,
    make_sure:
      'Make sure to opt-in to PlatinNews and never miss any PlatinClub offer or special promotion by email!',
    check_my_setting: 'Check my setting',
    conditions_platinclub: 'Conditons PlatinClub – General',
    loyalty_program:
      'PlatinClub is the CasinoFive loyalty program, that offers you various player benefits. The more you play and deposit at CasinoFive, the higher your VIP status and the wider your personal benefits and offers.',
    general_condition_1: `CasinoFive displays the requirements and conditions for each VIP status on the PlatinClub website. You can reach the status Bronze, Silver and Gold (lifetime), as well as Platin automatically and independently by your player behaviour and activity. You can check your recent status in your player account anytime. The allocation of a new VIP status happens automatically within the technical system on the day after reaching or passing the hurdle for the next status.`,
    general_condition_2: `The Welcome Gift for reaching a new VIP status will be granted for each first time you reach a status and is valid once per player or PlatinClub member.`,
    general_condition_3: `The calculation of your VIP status and benefits will be based upon the amounts of deposit, the date range and real money stakes, as well as your account- and verification status (KYC).`,
    general_condition_4: `You can qualify for status Platin by reaching the monthly minimum deposit amount. The qualification will be valid until the end of the next month. In case you lose your Platin status, you will be downgraded to status Gold on the 1st day of the new month. You can be upgraded back to Platin daily.`,
    general_condition_5: `To progress to the Black status, you will need a personal invitation by a CasinoFive team member. The selection of the Black members will be exclusively performed by CasinoFive. Only in case you receive and invitation, the Black status will be granted. The status will appear in your account.`,
    general_condition_6: `There is no entitlement to membership in the PlatinClub and/or Black status. All offers are non-binding and non-transferable. Legal recourse is excluded.`,
    general_condition_7: `The PlatinClub incl. the VIP statuses exists from its foundation until further notice. CasinoFive reserves the right to interrupt or terminate the PlatinClub program with all related offers and content at any time, with players informed at least 7 days in advance.`,
    general_condition_8: `CasinoFive reserves the right to expand or otherwise change the content of the PlatinClub at any time, e.g. the requirements to achieve a certain VIP status and the associated benefits. Information on this can be found on the PlatinClub website.`,
    general_condition_9: `If you decide to close your CasinoFive account or your account is closed by the CasinoFive team, you will lose your VIP status and all associated benefits. Your status will not be transferable even if you open a new account at CasinoFive.
    CasinoFive reserves the right to terminate your PlatinClub membership at any time and withdraw your current VIP status.`,
    general_condition_10: `Unless otherwise stated, the PlatinClub and its content and benefits are governed by the terms and conditions or bonus conditions of CasinoFive or Red Rhino Ltd.
    CasinoFive will terminate your PlatinClub membership if we determine that you violate the PlatinClub conditions, other bonus terms and conditions, or the CasinoFive terms and conditions and/or otherwise act against the interests of CasinoFive. Such violation will result in the withdrawal of your VIP status and all associated benefits. CasinoFive also reserves the right to retroactively withdraw any bonuses already awarded and close your player account if necessary.`,
    general_condition_11: `In addition, CasinoFive may suspend your PlatinClub membership at any time, i.e. for a period of time or until further notice, if necessary to protect our interests. During this time, your VIP status will be revoked and all benefits suspended.`,
    platincoins_conditions: 'PlatinCoins – Conditions, Collect and Exchange',
    every_real_money: `Every real money bet you make on slot machines as a player at CasinoFive is tracked by our system and converted into PlatinCoins, our loyalty currency. The PlatinCoins are part of the PlatinClub offer - the higher your PlatinClub status, the more PlatinCoins you collect for your bets. You can find out exactly how high your stake must be in order to collect a PlatinCoin in your personal VIP status in the advantages table on our PlatinClub website. In our Rewards Carnival Shop you can exchange your collected coins for bonuses, freegames and many other rewards.`,
    platincoins_condition_1:
      'PlatinCoins are calculated from your first real money bet with us – even if you have not yet reached PlatinClub status.',
    platincoins_condition_2:
      'Only your real money bets in slot machines are used to calculate coins. Bets placed on table games, live casino games, other games or sportsbook will not be counted.',
    platincoins_condition_3:
      'You will find an overview of your PlatinCoins balance and the coins collected or redeemed so far in your account.',
    platincoins_condition_4:
      'You can use your PlatinCoins for between 30 and 90 days, depending on your Club status. The period is calculated from the crediting of your last PlatinCoin. After that your coins expire and will be cancelled. When you are in status Platin, your coins can be used for an unlimited period of time, but only until your PlatinClub membership is terminated or your account is closed.',
    platincoins_condition_5: `The PlatinCoins are not transferable to other players or accounts. If you decide to close your CasinoFive account and open a new CasinoFive account at a later date, your coins will not be transferred.
    CasinoFive assumes no responsibility for the loss of PlatinCoins or any late or missing credit of coins due to data transmission problems.`,
    platincoins_condition_6: `The granting of PlatinCoins, the applicable terms and conditions (e.g. wager amounts) and their exchange for rewards at the CasinoFive Rewards Carnival Shop are at the sole discretion of CasinoFive and may be changed and terminated at any time without cause. This may be general, but may also be at the single player level. No information will be provided in advance.`,
    platincoins_condition_7:
      'In the event of a suspension, i.e. a temporal exclusion from the PlatinClub, the use of your collected PlatinCoins and already acquired rewards will be suspended, but these will remain until the suspension is lifted or your membership in the PlatinClub is terminated.',
  },
  [ComponentName.PROFILE_ACCOUNT_BALANCE]: {
    my_account: 'My Account Balance',
    withdrawal_balance: 'Withdrawal Balance:',
    non_withdrawalable: 'Non-withdrawalable balance tied to bonus:',
    bonus_balance: 'Bonus balance:',
    attention:
      'Attention: You have the possibility to cancel the current bonus',
    click_here_to_cancel: 'Click here to cancel your current bonus',
    required_turnover: 'Required turnover:',
    maximum_conversion: 'Maximum conversion amount for this bonus:',
    before_wager: `Before the wager from the bonus balance is made, the wager from
    the real money balance is wagered.`,
  },
  [ComponentName.PROMOTIONS]: {
    more_info: 'More Info',
    fairness_transparency: 'Fairness & Transparency',
  },
  [ComponentName.PROMOTION_VIEW]: {
    text_1:
      'Start the easy way - deposit easy and 100% secure - just feel WELCOME!',
    text_2: `CasinoFive offers a bonus to every new player registered: 100% up to
    100 EUR on your first deposit!`,
    text_3: `Enter your deposit amount from 10 EUR in field "Enter amount"`,
    text_4: `Enter WELCOME in field "Bonuscode"`,
    text_5: `100% of your deposit amount up to 100 EUR total will be
    automatically credited to your account`,
    text_6: `Use our WELCOME gift - and double your first deposit for twice the fun
    playing at CasinoFive. Your amount, your choice of risk, your fun
    playing your favorite Merkur slots.`,
    text_7: 'Here come all offer details at a glance:',
    text_8: `The Welcome Bonus is only valid on your first deposit and will be
    awarded only once per player, household and IP address. You may not
    claim additional Welcome Bonuses. The bonus and deposit amount must be
    wagered at least 25 times within 14 days. All money stakes are
    included, but are weighted differently according to the type of game
    (see below).`,
    show_results: 'Showing Results',
    weighted: '% Weighted',
    game: 'Game',
    slots: 'Slots',
    other_games: 'Other Games',
    rouletter: 'Roulette, Baccarat',
    sic_bo: 'Sic Bo',
    blackjack: 'Blackjack & Craps',
    stud_poker: 'Stud Poker',
    rosk_ladder: 'Rosk ladder, double-or-nothing, card risk',
    text_9: 'These stakes are first taken from your real money account.',
    text_10: `When your real money account is empty, your bonus credits are used.
    Winnings from stakes placed with real money are added to your
    balance available for withdrawal. Winnings above your deposit amount
    are credited to your bonus account. Winnings from games played with
    your bonus are credited to your bonus account and can only be paid
    out when the bonus conditions have been fulfilled. Your bonus can
    only be withdrawn when you have met the bonus conditions described
    above: your initial deposit and bonus amount must have been wagered
    at least 25 times within 14 days. You may only withdraw winnings
    from your real-money deposit prior to meeting this condition. Please
    note, that an early withdrawal means that the bonus is forfeited. We
    reserve the right to decline or withhold withdrawals in the case of
    misuse of the bonus, chargeback, or other exceptional circumstances
    unfavourable to the casino such as may arise.`,
    text_11: 'General bonus conditions:',
    text_12: `If you play with bonus money, all winnings will be credited to your
    bonus account. Bonus money can be converted into real money after
    fulfilling the bonus conditions. Bonus money is a bonus until it has
    been actively converted into the user account and can therefore only
    be paid out after the conversion and fulfillment of the bonus
    conditions. For this, the entire bonus amount a certain number must be
    wagered. The bonus amount is the amount of the deposit plus the
    granted bonus amount. Example: You make a deposit of € 50.00 and
    receive an additional € 50.00 bonus credit - thus the total bonus
    amount of € 100.00 are converted accordingly often.`,
    text_13: 'Freegames conditions:',
    text_14: `20 Freespins on slot Book of Aztec with a maximum cashable amount of €
    100 and a wager of 75.`,
  },
  [ComponentName.REGISTER_SIDE_MENU]: {
    register_account: 'Register Account',
  },
  [ComponentName.REGISTRATION_BOX]: {
    left_title: 'The CasinoFive Security Promise',
    left_text_1: '100% secure payments with leading payment providers',
    left_text_2: 'Highest data security of your personal data',
    left_text_3: 'Fast payout, secure deposit',
    middle_title: 'The CasinoFive Game Variety',
    middle_text_1:
      'Limitless Casino fun, whether on mobile devices, Mac or pc!',
    middle_text_2: 'Regular great promotions',
    middle_text_3:
      'Huge selection of games from the most famous premium providers',
    right_title: 'For Members Only The CasinoFive Club',
    right_text_1: 'Casino news & information first',
    right_text_2: 'Preferred payout of your winnings',
    right_text_3: 'Special promotions & competitions',
  },
  [ComponentName.REGISTRATION_FORM]: {
    resend_confirmation_mail: 'Resend Confirmation Email',
    click_here: 'Click Here',
    correct_following_errors: 'Please correct the following error(s):',
    register_btn_label: 'Register',
    email_label: 'E-mail',
    password_label: 'Password',
    password_confirmation_label: 'Repeat password',
    newsletter_subscription_label:
      'Yes! I want to receive the latest news, competitions and bonus offers by email.',
    terms_label:
      'Hereby I confirm that I´m older than 18 years and that I´ve read the Terms and Conditions and the Privacy Policy of Red Rhino Ltd. for the usage of CasinoFive.com and that I´ve understood and accepted them.',
    login: 'Login',
  },
  [ComponentName.RESEND_CONFIRMATION]: {
    back_to_registration: 'back to the registration page',
    send_confirmation_again: 'Send the confirmation email again',
    description: `If you have not received the confirmation email, you can resend the
    email here.`,
    resend_link: 'Resend confirmation link',
    email: 'E-mail',
  },
  [ComponentName.RESPONSIBLE_GAMING]: {
    responsible_gaming: 'Responsible Gaming',
    for_us_1: `For us at `,
    for_us_2: `, responsible gaming means that we support our
  customers in ensuring safe, responsible gaming behaviour so that right
  decisions can be made in a timely manner.`,
    we_recommend: `We recommend that the following principles are followed so that gaming is
  purely a source of exciting entertainment and does not result in any
  problems:`,
    always_remember: `Always remember that gaming is only for our entertainment and not a source
  of income.`,

    set_yourself_a_limit: `Set yourself a limit and do not wager more than you’re prepared to spend on entertainment.`,

    never_play_with_money: `Never play with money that you need for living expenses or food.`,

    do_not_take_out_loans: `Do not take out loans and do not borrow money from other sources for gambling.`,

    plan_ahead_how_much: `Plan ahead how much time you would like to spend on gambling.`,

    stick_to_these_plans: `Stick to these plans regardless of whether you win or lose.`,

    do_not_gamble_if_you_are_irritated: `Do not gamble if you are irritated or under the influence of alcohol or medication. Take regular breaks.`,

    do_not_gamble_to_help_distract: `Do not gamble to help distract from disappointments, arguments or problems.`,

    do_not_neglect_your_social_environment: `Do not neglect your social environment, take reactions from your partner, family members and friends seriously.`,

    do_not_play_in_secret: `Do not play in secret and do not neglect other hobbies.`,

    self_assessmet: `Self-Assessment`,
    are_you_at_rist: `Are you at risk?`,
    think_about_the_past: `Think about the past 12 months and answer the following questions
  honestly.`,
    have_you_ever_bet: `1. Have you ever bet more than you could afford to lose?`,
    have_you_borrowed_money: `2. Have you borrowed money, taken out a loan, or sold anything to get
  money to gamble?`,
    has_gambling_ever_caused: `3. Has gambling ever caused problems in your home or at work?`,
    have_you_ever_gambled: `4. Have you ever gambled just to pay off debts or solve financial
  difficulties?`,
    has_your_performance_decreased: `5. Has your performance decreased with gaming?`,
    after_playing: `6. After playing, do you feel you need to return to the game as soon as
  possible to win back your losses?`,
    do_you_regularly_play: `7. Do you regularly play longer than planned?`,
    have_you_ever_had_trouble: `8. Have you ever had trouble sleeping due to gamling?`,
    have_you_had_any_other_health_problems: `9. Have you had any other health problems due to gambling, for example stress or anxieties?`,
    do_you_regularly_gamble: `10. Do you regularly gamble until you run out of money?`,
    have_you_ever_found: `11. Have you ever found your own gambling behavior self-destructive?`,
    have_you_ever_felt_guilty: `12. Have you ever felt guilty about your gambling behavior or guilty about
  what happens when you gamble?`,
    in_case_you_answer: `In case you answer more than 7 questions with a “Yes” you should reflect
  on your gambling activities and check the tools we offer or scroll down to
  addiction advice centres.`,
    responsible_gambling_tools: `Responsible Gambling Tools`,
    with_us_you_always_have_1: `With us you always have the option to set daily, weekly or monthly limits via {url}.`,
    my_profile_limits: `"My Profile > Limits”`,
    with_us_you_always_have_2: '',
    if_you_lower_your_limits: `If you lower your limits, the change will take effect
    immediately. If you increase your limit, a seven-day transition period
    will start. After the seven days, your new limit will be activated.`,
    you_can_also_set: `You can also set your own game breaks.`,
    cool_offs_and_self_exclusion: `Cool Offs and Self-Exclusion`,
    cool_off: `Cool-Off`,
    you_can_set_up_a_time_limited_cool_off_period_1: `You can set up a time-limited cool off period yourself using the function in your player account`,
    you_can_set_up_a_time_limited_cool_off_period_2: '.',
    my_profile_self_exclusion: '"My profile" > "Self-exclusion"',
    with_the_cool_off: `With the cool off, you can choose an exclusion period of up to 30 days.
  During this period, you will not be able to play or deposit funds into
  your gaming account. At the end of this selected period, the account will
  be automatically unblocked.`,
    unrestricted_block: `Unrestricted Block`,
    if_you_want_to_exclude_yourself_1: `If you want to exclude yourself from playing and put a ban that remains active until you revoke it yourself, you can contact our customer service
    via chat or `,
    if_you_want_to_exclude_yourself_2: `e-mail. During this period you can neither play nor deposit funds into your gaming account.`,
    of_course_we_also_offer_1: `Of course, we also offer the option of permanently blocking you. This is
    also possible via a request to our customer service via chat or `,
    of_course_we_also_offer_2: `e-mail. We will then try to track down all your newly registered
    accounts and close them. You will no longer receive promotional emails.`,
    limits: 'Limits',
    deposit_limits: 'Deposit Limits',
    deposit_limits_help:
      'Deposit limits help you set how much money you can deposit into your player account per month, week, or day. By limiting your deposit, you can limit your gambling expenses in a very simple way.',
    loss_limits: 'Loss Limits',
    we_offer_loss_limits: `We offer loss limits which allow you to set a personal real money loss
    limit per day, week or month.`,
    stake_limits: `Stake Limits`,
    as_part_of_a_stake_limit: `As part of a stake limit, all your bets per day, week or month are summed
    up. As soon as these have reached the set limit, further bets are
    prevented.`,
    time_limits: `Time Limits`,
    time_limits_allow_you: `Time limits allow you to better plan the time you spend in a game session.
    If you set yourself a time limit, you will automatically be reminded of
    the set limit after this period has finished and you will be automatically
    logged out.`,
    addiction_advice_centres: `Addiction Advice Centres`,
    if_you_feel_that_you_are_gambling: `If you feel that you are gambling too much and are looking for professional help, contact the GamCare National Helpline on 0808 8020 133.
    Lines are open from 8am to midnight UK time`,
    the_following_organisations_provide: `The following organisations provide psychological help, professional advice, and assistance in dealing with gambling addition:`,
    link_1_label: `Gamble Aware`,
    link_1_url: `https://www.begambleaware.org/`,
    link_2_label: `Gamblers Anonymous`,
    link_2_url: `http://www.gamblersanonymous.org/ga/`,
    last_text: '', //NOTE: this property is needed for the German translation
  },
  [ComponentName.REGISTRATION_SUCCESS]: {
    check_your_email: 'Check your email',
    description: `An e-mail with further instructions has been sent to your email account. Click on the activation link to activate your account.`,
    finish: 'Finish',
    need_help: 'Need Help?',
    click_here: 'Click Here',
  },
  [ComponentName.REWARD_BREADCRUMBS]: {
    rewards: 'Rewards',
    platin_coins: 'Platin Coins',
  },
  [ComponentName.REWARD_CARD]: {
    buy: 'Buy',
    coins: 'coins',
  },
  [ComponentName.REWARD_CARNIVAL]: {
    text_1: `From now on, you will be rewarded for your game in our Platin REWARDS
    CARNIVAL. Each bet on your favorite slots will count towards and fill
    your PlatinCoins account.`,
    text_2: `With your collected PlatinCoins you can shop in the REWARDS CARNIVAL
    Shop for additional free spins, bonuses, real money credits or exclusive
    items such as vouchers for your next supermarket visit.`,
    text_3: `You can find out how many PlatinCoins you receive by checking your
    PlatinClub status. Depending on your status, collecting coins can be up
    to 10 times faster.`,
    text_4: `You can see your personal PlatinCoins balance in your account overview
    and displayed above the REWARDS CARNIVAL Shop. So you always have an eye
    on the bonus you can sweeten your day with.`,
    text_5: `Briefly explained how the purchase of shop offers works:`,
    text_6: `You can see at a glance how many PlatinCoins you need for an item. The
    number is shown on the "Buy" button. If you click this button, you will
    be asked to confirm your purchase again. Now your offer will be
    activated for you and will be displayed as a new "Notifications". From
    there, you can now simply activate it. Purchases of special offers, such
    as vouchers, can be picked up from our support team.`,
  },
  [ComponentName.SEARCH]: {
    search: 'Search',
    search_input_options: 'Search Filter Options',
    reset_filter: 'Reset Filter',
    [Filter.ALL_CATEGORIES]: 'All Categories',
    [Filter.ALL_PROVIDERS]: 'All Providers',
    [Filter.FILTER_BY]: 'Filter By',
    [Filter.ORDER_BY]: 'Order By',
    [FilterByFilter.TOP_GAMES]: 'Top Game',
    [FilterByFilter.NEW_GAMES]: 'New Games',
    [OrderByFilter.A_Z]: 'A-Z',
    [OrderByFilter.Z_A]: 'Z-A',
  },
  [ComponentName.SUPPORT_COMPONENT]: {
    go_to_section: 'Go to Section',
  },
  TabPanels: {
    back: 'Takaisin',
  },
  [ComponentName.UPDATE_PROFILE_BUTTON]: {
    bttn_label: 'Update profiles',
  },
  [ComponentName.USER]: {
    go_to_section: 'Go to Section',
  },
  [ComponentName.USER_ACCOUNT_MENU_LIST]: {
    my_profile: 'My Profile',
    change_password: 'Change Password',
    game_rounds: 'Game Rounds',
    transactions: 'Transactions',
    newsletter: 'Newsletter',
    limits: 'Limits',
    verify_account: 'Verify Account',
    documents: 'Documents',
    self_exclusion: 'Self exclusion',
    my_account_balance: 'My Account Balance',
    deposit: 'Deposit',
    withdrawal: 'Withdrawal',
  },
  [ComponentName.USER_ACCOUNT]: {
    title: 'My Profile',
    description:
      'For changes in your address and/or personal data, please contact our customer service.',
    personal_data: 'Personal Data',
    address: 'Address',
    address_secondary: 'Address Secondary',
    dashboard_settings: 'Dashboard Settings',
    additional_data: 'Additional Data',
    notice: 'Successfully updated account.',
    error: 'An error occurred while saving your account',
    edit_account_global: 'Account processing',
    back_to_dashboard: 'Back to the dashboard',
    save_change: 'Update profiles',
    email: 'E-mail',
    sex: 'Greeting',
    first_name: 'First name',
    last_name: 'Last name',
    date_of_birth: 'Date of birth',
    phone: 'Phone',
    business_phone: 'Business phone',
    mobile_phone_number: 'Cellphone',
    citizenship: 'Citizenship',
    dashboard_culture: 'Dashboard culture',
    birth_country: 'Birth country',
    birth_place: 'Birth place',
    street_and_house_number: 'Street & House Number',
    zip_code: 'Zip Code',
    city: 'City',
    country: 'Country',
    birth_date: 'Date of Birth',
    mr: 'Mr',
    mrs: 'Mrs',
  },
  [ComponentName.USER_DOCUMENTS]: {
    title: 'Documents',
    description: '',
    type: 'Type',
    select_file: 'Select file',
    file_name: 'File Name',
    upload_date: 'Upload Date',
    expiry_date: 'Expiry Date',
    issue_date: 'Issue Date',
    status: 'Status',
    my_documents: 'My Documents',
    no_documents: 'There are no documents to show yet.',
  },
  [ComponentName.USER_DOCUMENT_FORM]: {
    upload_file: 'Upload File',
    choose_file: 'Choose File',
    upload_restrictions:
      'Max file size: 8.00MB, Allowed file types: .jpg, .jpeg, .png, .pdf, .gif',
    type: 'Type',
    upload_date: 'Upload Date',
    expiry_date: 'Expiry Date',
    issue_date: 'Issue Date',
    please_select: 'Ole hyvä ja valitse',
  },
  [ComponentName.USER_EDIT]: {
    my_profile: 'My Profile',
    description:
      'For changes in your address and/or personal data, please contact our customer service.',
    personal_data: 'Personal Data',
    address: 'Address',
    street_and_house_number_label: 'Street & House Number',
    salutation_label: 'Salutation',
    salutation_placeholder: 'Please select',
    zip_code_label: 'Zip code',
    first_name_label: 'First Name',
    last_name_label: 'Last Name',
    date_of_birth_label: 'Date of Birth',
    citizenship_label: 'Citizenship',
    city_label: 'City',
    country_label: 'Country',
    mr: 'Mr',
    mrs: 'Mrs',
    to_be_able: `To be able to use all the features of the casino please fill in your details, this is required.`,
  },
  [ComponentName.USER_GAME_ROUNDS]: {
    title: 'Game Rounds',
    description: '',
    filter_game_rounds: 'Filter Game Rounds',
    showing_results: 'Showing Results',
    game_token: 'Game#',
    time: 'Time',
    game: 'Game',
    balance: 'Balance (begin/end)',
    bet: 'Bet',
    win: 'Win',
    no_game_rounds: 'There are no game rounds to show.',
    date_from: 'Date From',
    date_to: 'Date to',
    bttn_label: 'Show Results',
  },
  [ComponentName.USER_LIMIT_EDIT]: {
    title: 'Limits',
    description: '',
    define_limits: 'Define your self-protection limits.',
    deposit_limit: 'Raised limits will be activated after one week!',
    cancel_bttn_label: 'Cancel',
    submit_bttn_label: 'Set Limit',
    form_amount_label: 'Deposit Maximum €',
    form_amount_placeholder: 'Enter limit',
    form_period_label: 'Per',
    month: 'month',
    week: 'week',
    day: 'day',
    hour: 'hour',
    minute: 'minute',
    [LimitType.DEPOSIT]: 'Deposit',
    [LimitType.WAGER]: 'Wager',
    [LimitType.LOSS]: 'Loss',
    [LimitType.TIME]: 'Time',
    session_time_maximum: 'Session time Maximum',
  },
  [ComponentName.USER_LIMITS]: {
    title: 'Limits',
    description: '',
    success: 'Limit successfully updated',
    error: 'An error occurred while saving limit',
    [LimitType.DEPOSIT]: 'Deposit',
    [LimitType.WAGER]: 'Wager',
    [LimitType.LOSS]: 'Loss',
    [LimitType.TIME]: 'Time',
    no_limit: 'no limit set',
    account_limits: 'Account Limits',
    type: 'Type',
    period: 'Period',
    limit: 'Limit',
    remaining: 'Remaining',
    valid_from: 'Valid From',
    valid_to: 'Valid To',
    options: 'Options',
  },
  [ComponentName.USER_MENU]: {
    withdraw: 'Withdraw',
    deposit: 'Deposit',
    my_account: 'My Account',
    transactions: 'Transactions',
    game_rounds: 'Game Rounds',
    support: 'Support',
    information: 'Information',
    logout: 'Logout',
  },
  [ComponentName.USER_NEWSLETTER]: {
    newsletter: 'Newsletter',
    description: '',
    stay_updated: 'Stay Updated with our special offers',
    email_label: 'E-mail Newsletter',
    sms_label: 'Mobile SMS',
    update_bttn_label: 'Update',
  },
  [ComponentName.USER_NOTIFICATION]: {
    bonus: 'Bonus:',
    amount: 'Amount:',
    code: 'Code:',
    use: 'Use',
    percent: 'Prosentti:',
    free_spin: 'Ilmainen pyöräytys:',
  },
  [ComponentName.USER_NOTIFICATIONS]: {
    notifications: 'Notifications',
    empty_list: 'Your account do not have any notifications yet.',
  },
  [ComponentName.USER_SELF_EXCLUSION]: {
    title: 'Self exclusion',
    subtitle: 'Deactivate user account temporarily or permanently',
    description:
      'You can block your user account for a certain period of time. Your user account will be activated again automatically after the time has elapsed. If you confirm a permanent block, your user account will be blocked for further activities. Logging in is then no longer possible.',
    form_title: 'Select self exclusion settings',
    success: 'Self exclusion successfully save',
    error: 'An error occurred while save self exclusion',
    form_period_label: 'Duration of deactivation',
    form_reason_for_deactivation: 'Reason for deactivation',
    submit_bttn_label: 'Setup exclusion',
    day: 'Day',
    three_days: '3 days',
    week: 'Week',
    month: 'Month',
    three_months: '3 months',
    six_months: '6 months',
    year: 'Year',
    notice:
      'To permanently block your account, please contact our support team.',
  },
  [ComponentName.USER_TRANSACTIONS]: {
    title: 'Transactions',
    description: '',
    filter_transactions: 'Filter Transactions',
    showing_results: 'Showing Results',
    date: 'Date',
    method: 'Method',
    status: 'Status',
    bonus_amount: 'Bonus Amount',
    bonus_balance: 'Bonus Balance',
    amount: 'Amount',
    balance: 'Balance',
    currency: 'Currency',
    no_transactions: 'There are no transactions to show yet.',
    date_from: 'Date From',
    date_to: 'Date to',
    bttn_label: 'Show Results',
  },
  [ComponentName.WITHDRAWAL_PANEL]: {
    title: 'Withdrawal',
    description: '',
    select_withdrawal_method: 'Select Withdrawal Method',
    no_deposit:
      'No deposit has been made. In order to be able to withdraw funds, you must top up your account.',
    withdrawal_confirmation: 'Withdrawal confirmation',
    min: 'Min',
    max: 'Max',
    amount: 'Amount',
    submit: 'Submit',
    withdrawal_method_not_found:
      'The withdrawal method is wrong or not selected. Please select withdrawal method in step 1 first.',
    currency: 'Currency',
    payment_method_name: 'Payment method name',
    owner_name: 'Owner name',
    payment_card_summary: 'Payment card number',
    payment_card_expire_date: 'Payment card expire date',
    your_account_balance: 'Your account balance',
    account_status: 'Account status',
    payment_bank_account_number: 'Payment bank account number',
  },
  [ComponentName.WITHDRAWAL_HISTORY]: {
    title: 'History of withdrawal',
    description: 'Detailed list of withdrawal history',
    cancel: 'Cancel withdrawal',
    currency: 'Currency',
    amount: 'Amount',
    created: 'Start date',
    state: 'State',
    action: 'Action',
  },
  [ModalName.PAY_AND_PLAY_MODAL]: {
    deposit_and_play: 'talleta ja pelaa',
    deposit_amount: 'talletus määrä',
    bonus_code: 'Bonus koodi',
  },
  [ModalName.REALITY_CHECK_MODAL]: {
    reality_check: 'Reality Check',
    confirm: 'Confirm',
    leave: 'Leave',
    question: 'Are you still playing?',
    bet: 'BET:',
    win: 'WIN:',
  },
  [ModalName.LOGIN_MODAL]: {
    form_h1: 'Please log in',
    form_label_email: 'E-mail address',
    form_label_passwd: 'Password',
    forgot_your_password: 'Forgot your password?',
    click_here: 'Click Here',
    submit_login_btn_label: 'Login',
    no_account: 'No account?',
    register_now: 'Register Now',
    german_notice: '',
  },
  [ModalName.PAY_AND_PLAY_SESSION_DETAILS]: {
    pay_and_play_session_details: 'Pay-and-play-istunnon tiedot',
    due_to_regulations: 'Säännösten takia meidän pitää kysyä sukupuolesi.',
    salutation_label: 'Tervehdys',
    salutation_placeholder: 'Ole hyvä ja valitse',
    mr: 'Herra',
    mrs: 'Neiti',
    email: 'Sähköposti',
    to_stay_up_to_date:
      'Pysyäksesi kärryillä uutisista ja tarjouksistä, lisää sähköpostisi tähän',
    i_agree:
      'Hyväksyn että CasinoFive pitää minut ajantasalla uutisista ja tarjouksista. Voin perua hyväksyntäni milloin tahansa omalta tililtäni.',
    save_information: 'Tallenna tiedot',
  },
  [ModalName.PNP_ACCOUNT_MODAL]: {
    pay_and_play: 'Pay and play',
    select_module: 'Select module before login or registration.',
    main: 'main',
    login: 'login',
    registration: 'registration',
  },
  [ModalName.RESEND_CONFIRMATION_EMAIL_MODAL]: {
    title: 'Resend Activation Link',
    email: 'E-mail',
    resend: 'Resend',
    need_help: 'Need Help?',
    click_here: 'Click Here',
  },
  [ModalName.FORGOT_PASSWORD_MODAL]: {
    title: 'Forgot Password?',
    email: 'E-mail your account email',
    resend: 'Submit',
    need_help: 'Need Help?',
    click_here: 'Click Here',
  },
  [ModalName.STORE_UPDATE_MODAL]: {
    title: 'Version Update',
    line_1: 'Your current website version is outdated.',
    line_2:
      'In order for the website to work properly, it needs to be updated.',
    update: 'Update',
  },
  [ModalName.REGISTER_MODAL]: {
    register_account: 'Register Account',
    german_notice: '',
  },
}
