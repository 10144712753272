import { isFunction, isNotFunction } from "./isFunction";

const _pipe = (result, nextFn, ...moreFns) => {
  return isNotFunction(nextFn) ? result : _pipe(nextFn(result), ...moreFns);
};

/**
 * Performs left-to-right function composition.
 *
 * Pipe accepts as many functions as needed as its arguments; (below mentioned as "function list".)
 * and returns a funtion which accepts as many arguments as needed. (Below mentioned as "initial argument list".)
 *
 * The returned function (below "function returned by pipe") calls the first function from "function list" and passes all its arguments (which are "initial argument list") to it.
 * The returned result of the first function from "function list" will be passed as an argument to the next function from "function list" and invoke the latter.
 * The returned result of the second function from "function list" will be passed as an argument to the next function from "function list" and invoke the latter, and on.
 *
 * The first function of "function list" may have any arity [can have as many arguments as needed]. It will receive "initial argument list" as its arguments.
 * The remaining functions from "function list" must be unary [accept only one argument].
 *
 * If the first argument is not function, it will cause an error. If any remaining arguments are not functions, it will be filtered out from the "function list" without causing any errors.
 *
 *
 * @param {function} initialFunction the first item from "function list"
 * The first function in the list of arguments ("function list") will be performed firstly. It may have any arity [can have any number of arguments].
 * If it is the only argument in "pipe", its result will be returned by the function returned by "pipe".
 * Accepts the arguments from the "function returned by pipe" and must return something.
 * If "pipe" does not have any other arguments, the sequence ends.
 * If "pipe" has other arguments, the result of "initialFunction" will be passed as a single argument to the next function from pipe's argument list ("function list")
 *
 * @param {function} nextFunction all other items from "function list"
 * Any remaining argument in the "pipe". Each ot them accepts single argument from the previous function in the pipe's sequence
 * and passes its result to the following function in the sequence.
 * If it is the last function in the sequence, its result will be the result of the whole sequence.
 *
 * @returns {function} "function returned by pipe"
 * It can accept any list of arguments (abovementioned "initial argument list")
 * and returns the result of performance of all sequence of functions, accepted by "pipe" as arguments.
 **/

export const pipe = (initialFn, ...moreFns) => {
  const filteredFns = moreFns.filter((fn) => isFunction(fn));

  return (...args) => {
    return _pipe(initialFn(...args), ...filteredFns);
  };
};


export default pipe;
