import { api } from './axios'
import { Endpoint } from '../constants'
import { ApiService } from './apiService'

export class RewardApi extends ApiService {
  static getRewardSliderShow() {
    return api.get(Endpoint.API_OPEN_REWARDS_SLIDE_SHOW)
  }
  static getRewardList() {
    return api.get(Endpoint.API_OPEN_REWARDS_LIST)
  }
  static getRewardCategories() {
    return api.get(Endpoint.API_OPEN_REWARDS_CATEGORIES)
  }
}
