import { curry } from '../basic/curry'

const _equals = function (o1, o2) {
  for (let p in o1) {
    if (Object.prototype.hasOwnProperty.call(o1, p)) {
      if (o1[p] !== o2[p]) {
        return false
      }
    }
  }
  for (let p in o2) {
    if (Object.prototype.hasOwnProperty.call(o2, p)) {
      if (o1[p] !== o2[p]) {
        return false
      }
    }
  }
  return true
}

export const equals = curry(_equals)
