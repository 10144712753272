import {
  Module,
  DROP_PERSONAL_INFO,
  DROP_CASINO_INFO,
  DROP_GAME_PLAY_DICTIONARY,
  DROP_DEPOSIT_METHOD_DICTIONARY,
  DROP_NEWSLETTER,
  DROP_LIMIT,
  DROP_DOC_TYPE,
  DROP_DOCUMENT,
  DROP_FLASH,
  DROP_WITHDRAWAL_METHOD,
  DROP_USER_NOTIFICATION,
  DROP_MENU,
  ADD_FLASH,
  IS_LOGGED,
  FlashType,
  FlashId,
  ResponseState,
  RouteName,
} from '@/constants'
import { commitMutation } from './store/commitMutation'
import { dispatchAction } from './store/dispatchAction'
import { getGetter } from './store/getGetter'
import { LoginApi } from '@/api'
import { navigateTo } from './navigateTo'
import { load } from './load'
import { getTitle } from '../router/getTitle'
import router from '../router'

const homeRoute = { name: RouteName.HOME }

export const logout = async ({ nextRoute = homeRoute } = {}) => {
  const isLogged = getGetter([Module.USER, IS_LOGGED])
  const currentRoute = router?.history?.current

  if (!isLogged) {
    return
  }

  commitMutation([Module.FLASH, DROP_FLASH])

  await load([
    dispatchAction([Module.USER, DROP_PERSONAL_INFO]),
    dispatchAction([Module.CASINO_INFO, DROP_CASINO_INFO]),
    dispatchAction([Module.GAME_PLAY, DROP_GAME_PLAY_DICTIONARY]),
    dispatchAction([Module.DEPOSIT_METHOD, DROP_DEPOSIT_METHOD_DICTIONARY]),
    dispatchAction([Module.NEWSLETTER, DROP_NEWSLETTER]),
    dispatchAction([Module.LIMIT, DROP_LIMIT]),
    dispatchAction([Module.DOC_TYPE, DROP_DOC_TYPE]),
    dispatchAction([Module.DOCUMENT, DROP_DOCUMENT]),
    dispatchAction([Module.WITHDRAWAL_METHOD, DROP_WITHDRAWAL_METHOD]),
    dispatchAction([Module.USER_NOTIFICATION, DROP_USER_NOTIFICATION]),
    commitMutation([Module.MAIN, DROP_MENU]),
  ])

  await navigateTo(nextRoute)

  if (currentRoute && currentRoute.name === nextRoute?.name) {
    getTitle(currentRoute)
  }

  const { state, msg } = await LoginApi.logoutSession()
  const [message] = msg
  const type = state === ResponseState.OK ? FlashType.OK : FlashType.ERROR

  commitMutation([Module.FLASH, ADD_FLASH], {
    message,
    type,
    id: FlashId.LOGOUT_SUCCESS,
  })
}
