export const RouteName = {
  BACKOFFICE_SUPPORT_ACCESS: 'BackofficeSupportAccess',
  BONUS_CANCEL: 'BonusCancel',
  CMS_LIST: 'CmsList',
  DASHBOARD: 'Dashboard',
  DEPOSIT: 'Deposit',
  FORGOT_PASSWORD: 'ForgotPassword',
  GAME: 'Game',
  GAME_CATEGORY: 'GameCateogry',
  GAME_DEMO: 'GameDemo',
  GAME_FREE_PLAY: 'GameFreePlay',
  GAME_INFO: 'GameInfo',
  GAME_LISTS: 'GameLists',
  GAME_NEW_GAMES: 'NewGames',
  GAME_PLAY: 'GamePlay',
  GAME_TOP_GAMES: 'TopGames',
  GAME_VENDOR: 'GameVendor',
  HOME: 'Home',
  LOGIN: 'Login',
  NEED_HELP: 'NeedHelp',
  OFFERS: 'Offers',
  OFFERS_VIEW: 'OffersView',
  PAGE_404: 'Page404',
  PLATINCLUB: 'Platinclub',
  PNP_ACCOUNT: 'PnpAccount',
  REGISTRATION: 'Registration',
  REGISTRAION_CONFIRM: 'RegistrationConfirm',
  REGISTRATION_SUCCESS: 'RegistrationSuccess',
  RESEND_CONFIRM_MAIL: 'ResendConfirmMail',
  REWARD_CARNIVAL: 'RewardCarnival',
  SEARCH_RESULT: 'SearchResult',
  SUPPORT: 'Support',
  USER: 'User',
  USER_ACCOUNT: 'UserAccount',
  USER_ACCOUNT_BALANCE: 'UserAccountBalance',
  USER_ACCOUNT_VERIFICATION: 'AccountVerification',
  USER_CHANGE_PASSWORD: 'ChangePassword',
  USER_DEPOSIT_HISTORY: 'Deposit History',
  USER_DOCUMENTS: 'UserDocuments',
  USER_GAME_ROUNDS: 'UserGameRounds',
  USER_EDIT: 'UserEdit',
  USER_LIMIT_EDIT: 'UserLimitEdit',
  USER_LIMITS: 'UserLimits',
  USER_NEWSLETTER: 'UserNewsletter',
  USER_NOTIFICATIONS: 'UserNotifications',
  USER_REGISTER_PROGRESS: 'UserRegisterProgress',
  USER_SELF_EXCLUSION: 'UserSelfExclusion',
  USER_TRANSACTIONS: 'UserTransactions',
  VENDOR_LIST: 'VendorList',
  WITHDRAWAL: 'Withdrawal',
  WITHDRAWAL_ADD: 'WithdrawalAdd',
  WITHDRAWAL_HISTORY: 'WithdrawalHistory',
  WITHDRAWAL_STATUS: 'WithdrawalStatus',
}
