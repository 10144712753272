import { COLON } from '@/constants'

/**
 * This method is and must remain case sensitive.
 */
export class ApiService {
  static formatUrl(url, paramObj) {
    let formattedUrl = url

    Object.keys(paramObj).forEach((key) => {
      const pathParamKey = `${COLON}${key}`

      if (formattedUrl.includes(pathParamKey)) {
        formattedUrl = formattedUrl.replace(pathParamKey, paramObj[key])
      }
    })

    return formattedUrl
  }
}
