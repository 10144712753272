import { GameApi } from '@/api'
import {
  VENDOR_DICTIONARY,
  UPDATE_VENDOR_DICTIONARY,
  IS_VENDOR_LIST_PENDING,
  SET_IS_VENDOR_LIST_PENDING,
  VENDOR_TOTAL,
  LAST_LOADED_PAGE,
  PAGE_TOTAL,
  HAS_MORE_PAGES,
  ResponseState,
  VENDOR_DICTIONARY_BY_ID,
  ITEMS_PER_PAGE,
  VENDOR_STATIC_PENDING_LIST,
  RESET_VENDOR_DICTIONARY,
  EMPTY_OBJECT,
  Digit,
  SLUG,
  URL_SLUG,
  ID,
  SLASH,
} from '@/constants'
import {
  lastLoadedPageInitialValue,
  pageTotalInitialValue,
  itemsPerPageInitialValue,
  staticPendingListInitialValue,
} from '@/values'
import { indexBy, pipe, path, map, populateNewArray } from '@/helpers'

const vendorDictionaryInitialValue = EMPTY_OBJECT
const isPendingInitialValue = false
const vendorTotalInitialValue = Digit.NOUGHT

// NOTE: I don't need pagination because I always need a full list of vendors at once.

export const vendor = {
  namespaced: true,
  state: {
    [VENDOR_DICTIONARY]: vendorDictionaryInitialValue,
    [IS_VENDOR_LIST_PENDING]: isPendingInitialValue,
    [VENDOR_TOTAL]: vendorTotalInitialValue,
    [LAST_LOADED_PAGE]: lastLoadedPageInitialValue,
    [PAGE_TOTAL]: pageTotalInitialValue,
    [ITEMS_PER_PAGE]: itemsPerPageInitialValue,
    [VENDOR_STATIC_PENDING_LIST]: staticPendingListInitialValue,
  },
  mutations: {
    [UPDATE_VENDOR_DICTIONARY](state, payload) {
      state[VENDOR_DICTIONARY] = {
        ...state[VENDOR_DICTIONARY],
        ...payload[VENDOR_DICTIONARY],
      }
      state[PAGE_TOTAL] = payload[PAGE_TOTAL]
      state[LAST_LOADED_PAGE] = payload[LAST_LOADED_PAGE]
      state[VENDOR_TOTAL] = payload[VENDOR_TOTAL]
      state[ITEMS_PER_PAGE] = payload[ITEMS_PER_PAGE]

      if (payload[VENDOR_STATIC_PENDING_LIST]) {
        state[VENDOR_STATIC_PENDING_LIST] = payload[VENDOR_STATIC_PENDING_LIST]
      }
    },
    [SET_IS_VENDOR_LIST_PENDING](state, value) {
      state[IS_VENDOR_LIST_PENDING] = value
    },
    [RESET_VENDOR_DICTIONARY](state) {
      state[VENDOR_DICTIONARY] = vendorDictionaryInitialValue
      state[VENDOR_TOTAL] = vendorTotalInitialValue
      state[LAST_LOADED_PAGE] = lastLoadedPageInitialValue
      state[PAGE_TOTAL] = pageTotalInitialValue
    },
  },
  actions: {
    async [UPDATE_VENDOR_DICTIONARY](
      { commit, state: store, getters },
      { resetsData } = {},
    ) {
      if (resetsData) {
        commit(RESET_VENDOR_DICTIONARY)
      }

      if (!getters[HAS_MORE_PAGES]) {
        return
      }

      try {
        commit(SET_IS_VENDOR_LIST_PENDING, true)

        const page = store[LAST_LOADED_PAGE] + Digit.ONE
        const { data, state } = await GameApi.getGameVendors({ page })

        if (state === ResponseState.OK && Array.isArray(data?.items)) {
          const { items, itemsPerPage, total } = data
          const totalNumber = Number(total)
          const itemsPerPageNumber = Number(itemsPerPage)

          const payload = {
            [VENDOR_DICTIONARY]: pipe(
              indexBy(SLUG),
              map((vendor) => ({
                ...vendor,
                path: 'game/vendor' + SLASH + vendor.urlSlug,
                games: indexBy(URL_SLUG)(vendor.games),
              })),
            )(items),
            [PAGE_TOTAL]: Math.ceil(total / itemsPerPageNumber),
            [LAST_LOADED_PAGE]: page,
            [VENDOR_TOTAL]: totalNumber,
            [ITEMS_PER_PAGE]: itemsPerPageNumber,

            ...(store[ITEMS_PER_PAGE] !== itemsPerPageNumber
              ? {
                  [VENDOR_STATIC_PENDING_LIST]: populateNewArray({
                    length: itemsPerPageNumber,
                  }),
                }
              : {}),
          }

          commit(UPDATE_VENDOR_DICTIONARY, payload)
          commit(SET_IS_VENDOR_LIST_PENDING, isPendingInitialValue)
        }
      } catch (e) {
        console.dir(e)
      }
    },
  },
  getters: {
    [HAS_MORE_PAGES]: (state) => {
      return state[PAGE_TOTAL] > state[LAST_LOADED_PAGE]
    },
    [VENDOR_DICTIONARY_BY_ID]: (state) => {
      return pipe(path([VENDOR_DICTIONARY]), Object.values, indexBy(ID))(state)
    },
  },
}
