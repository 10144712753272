import { AuthApi, GameApi } from '@/api'
import {
  GAME_PLAY_DICTIONARY,
  SET_GAME_PLAY_DICTIONARY,
  IS_GAME_PLAY_PENDING,
  SET_IS_GAME_PLAY_PENDING,
  UPDATE_GAME_FAVOURITE_STATUS,
  DROP_GAME_PLAY_DICTIONARY,
  ResponseState,
  ADD_FLASH,
  Module,
  FlashId,
  FlashType,
  RouteName,
  EMPTY_OBJECT,
  IS_GAME_FAV_STATUS_PENDING,
  SET_IS_GAME_FAV_STATUS_PENDING,
  IS_GAME_FAVORITE,
} from '@/constants'
import { commitMutation, navigateTo } from '@/helpers'

export const gamePlay = {
  namespaced: true,
  state: {
    [GAME_PLAY_DICTIONARY]: EMPTY_OBJECT,
    [IS_GAME_PLAY_PENDING]: false,
    [IS_GAME_FAV_STATUS_PENDING]: false,
  },
  mutations: {
    [SET_GAME_PLAY_DICTIONARY](state, { dictionary }) {
      state[GAME_PLAY_DICTIONARY] = dictionary
    },
    [SET_IS_GAME_PLAY_PENDING](state, { value }) {
      state[IS_GAME_PLAY_PENDING] = value
    },
    [SET_IS_GAME_FAV_STATUS_PENDING](state, { value }) {
      state[IS_GAME_FAV_STATUS_PENDING] = value
    },
  },
  actions: {
    async [SET_GAME_PLAY_DICTIONARY](context, { urlSlug }) {
      let state, data

      try {
        context.commit(SET_IS_GAME_PLAY_PENDING, { value: true })

        const response = await Promise.all([
          AuthApi.getGamePlay({ urlSlug }),
          GameApi.getGameDetails({ slugName: urlSlug }),
        ])

        state = response.reduce((prev, cur) =>
          prev.state === ResponseState.ERROR ||
          cur.state === ResponseState.ERROR
            ? ResponseState.ERROR
            : ResponseState.OK,
        )
        data = response.reduce((prev, cur) => ({ ...prev?.data, ...cur?.data }))

        if (state === ResponseState.OK) {
          const dictionary = {
            ...context.state[GAME_PLAY_DICTIONARY],
            [urlSlug]: data,
          }

          context.commit(SET_GAME_PLAY_DICTIONARY, { dictionary })
        }

        if (gamePlay.state === ResponseState.WARNING) {
          navigateTo(
            { name: RouteName.USER_EDIT },
            {
              onNavigate: commitMutation([Module.FLASH, ADD_FLASH], {
                message: gamePlay.msg[0],
                type: FlashType[gamePlay.state],
                id: FlashId.INSUFFICIENT_USER_DATA,
              }),
            },
          )
        }

        context.commit(SET_IS_GAME_PLAY_PENDING, { value: false })
      } catch (e) {
        console.dir(e)
      }

      return {
        state,
        data,
      }
    },
    async [UPDATE_GAME_FAVOURITE_STATUS](context, { id, urlSlug, isFavorite }) {
      try {
        context.commit(SET_IS_GAME_FAV_STATUS_PENDING, { value: true })

        const { state } = isFavorite
          ? await AuthApi.addGameToFavourite({ id })
          : await AuthApi.removeGameFromFavourite({ id })

        if (state === ResponseState.OK) {
          const dictionary = {
            ...context.state[GAME_PLAY_DICTIONARY],
            [urlSlug]: {
              ...context.state[GAME_PLAY_DICTIONARY][urlSlug],
              isFavorite,
            },
          }

          context.commit(SET_GAME_PLAY_DICTIONARY, { dictionary })
        }
      } catch (e) {
        console.dir(e)
      } finally {
        context.commit(SET_IS_GAME_FAV_STATUS_PENDING, { value: false })
      }
    },
    [DROP_GAME_PLAY_DICTIONARY](context) {
      context.commit(SET_GAME_PLAY_DICTIONARY, {
        dictionary: EMPTY_OBJECT,
      })
    },
  },
  getters: {
    [IS_GAME_FAVORITE]: (state) => (slug) => {
      return state[GAME_PLAY_DICTIONARY]?.[slug]?.isFavorite
    },
  },
}
