import { Digit } from '@/constants'

export const getIsElementVisible = (el) => {
  const isElTopHigherThanScreenBottom =
    el.getBoundingClientRect().y - window.screen.height < Digit.NOUGHT
  const isElBottomLowerThanScreenTop =
    el.getBoundingClientRect().bottom > Digit.NOUGHT

  return isElTopHigherThanScreenBottom && isElBottomLowerThanScreenTop
}
