export const LanguageKey = {
  EN: 'en',
  DE: 'de',
  FI: 'fi'
}

export const Language = {
  [LanguageKey.EN]: 'English',
  [LanguageKey.DE]: 'Deutsch',
  [LanguageKey.FI]: 'Finland'
}
