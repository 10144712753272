export const Game = {
  image: 'image',
  urlSlug: 'urlSlug',
  name: 'name',
  id: 'id',
  vendorLogo: 'vendorLogo',
  buttons: 'buttons',
  flags: 'flags',
  rating: 'rating',
}

export const GameButton = {
  demo: 'demo',
  play: 'play',
}

export const GameFlag = {
  new: 'new',
  hot: 'hot',
}

const GameButtonModel = (data) => {
  return {
    [GameButton.demo]: data?.demo,
    [GameButton.play]: data?.play,
  }
}

const GameFlagModel = (data) => {
  return {
    [GameFlag.new]: data?.new,
    [GameFlag.hot]: data?.hot,
  }
}

export const GameModel = (data) => {
  return {
    [Game.image]: data?.image,
    [Game.urlSlug]: data?.urlSlug,
    [Game.name]: data?.name,
    [Game.id]: data?.id,
    [Game.vendorLogo]: data?.vendorLogo,
    [Game.rating]: data?.rating,
    [Game.buttons]: GameButtonModel(data?.buttons || {}),
    [Game.flags]: GameFlagModel(data?.flags || {}),
  }
}
