import { Type } from '../constants'

export const extractNumberFromString = (value) => {
  value = typeof value !== Type.STRING ? '' : value

  return value
    .split('')
    .filter((item) => !isNaN(parseInt(item)))
    .join('')
}
