export const Digit = {
  NOUGHT: 0,
  NOUGHT_FIVE: 0.5,
  ONE: 1,
  ONE_FIVE: 1.5,
  TWO: 2,
  TWO_FIVE: 2.5,
  THREE: 3,
  THREE_FIVE: 3.5,
  FOUR: 4,
  FOUR_FIVE: 4.5,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  TWELVE: 12,
  FOURTEEN: 14,
  FOURTEEN__FIVE: 14.5,
  FIFTEEN: 15,
  EIGHTEEN: 18,
  TWENTY: 20,
  TWENTY_EIGHT: 28,
  TWENTY_FOUR: 24,
  THIRTY: 30,
  THIRTY_ONE: 31,
  FORTY: 40,
  FIFTY: 50,
  FIFTY_NINE: 59,
  SIXTY: 60,
  SEVENTY: 70,
  EIGHTY: 80,
  NINETY: 90,
  ONE_HUNDRED: 100,
  ONE_HUNDRED_TWENTY_TWO: 122,
  ONE_HUNDRED_THIRTY: 130,
  TWO_HUNDRED: 200,
  THREE_HUNDRED: 300,
  FOUR_HUNDRED: 400,
  FIVE_HUNDRED: 500,
  FIVE_HUNDRED_TWENTY_FIVE: 525,
  FIVE_HUNDRED_TWENTY_NINE: 529,
  FIVE_HUNDRED_THIRTY: 530,
  SIX_HUNDRED_EIGHTY: 680,
  SIX_HUNDRED_EIGHTY_ONE: 681,
  SEVEN_HUNDRED_SIXTY_EIGHT: 768,
  ONE_THOUSAND: 1000,
  ONE_THOUSAND_ONE_HUNDRED_SIXTY: 1160,
  ONE_THOUSAND_TWO_HUNDRED: 1200,
  TWO_THOUSAND: 2000,
  FIVE_THOUSAND: 5000,
}
