import { Currency } from '@/constants'
import { pipe } from './pipeline/basic/pipe'
import { when } from './pipeline/basic/when'
import { always } from './pipeline/basic/always'
import { isString } from './pipeline/basic/isString'

const LOCALE = 'EN'
const CURRENCY = 'currency'
const turnIntoDecimalNumber = (number) => number * 0.01

export const formatNumberLocale = ({
  locale = window.i18n.locale.toLocaleUpperCase() || LOCALE,
  currency = Currency.EUR,
  number,
  needsCoins = true,
  ...others
}) => {
  const options = { style: CURRENCY, currency, ...others }

  return when(
    isFinite,
    pipe(
      when(isString, Number),
      when(always(needsCoins), turnIntoDecimalNumber),
      (number) => number.toLocaleString(locale, options),
    ),
  )(number)
}
