import { DateFormat, Digit } from '@/constants'

const handleOneDigit = (value) => (value < Digit.TEN ? `0${value}` : value)

export const formatDate = ({ format = DateFormat.DD_MM_YY, date }) => {
  const dateObj = new Date(date)

  const hour = handleOneDigit(dateObj.getHours() + Digit.ONE)
  const minute = handleOneDigit(dateObj.getMinutes())
  const day = handleOneDigit(dateObj.getDate())
  const month = handleOneDigit(dateObj.getMonth() + Digit.ONE)
  const year = dateObj.getFullYear()

  if (format === DateFormat.DD_MM_YY) {
    return `${day}/${month}/${year}`
  }

  if (format === DateFormat.HHcMM_DDsMMsYY) {
    return `${hour}:${minute} ${day}/${month}/${year}`
  }

  if (format === DateFormat.YYYY_MM_DD) {
    return `${year}-${month}-${day}`
  }

  return date
}
