import { curry } from '../basic/curry'
import { isPlainObject } from './isPlainObject'

/*
    "alignsWith" methods is not the same as "equals".
    Unlike "equals", it compares only properties that "item2" has.
    The comparison is deep.
*/

const _alignsWith = (item1, item2) => {
  if (!isPlainObject(item1) || !isPlainObject(item2)) {
    return item1 === item2
  }

  for (let prop in item2) {
    if (Object.prototype.hasOwnProperty.call(item2, prop)) {
      if (isPlainObject(item1[prop]) && isPlainObject(item2[prop])) {
        return _alignsWith(item1[prop], item2[prop])
      }

      if (item1[prop] !== item2[prop]) {
        return false
      }
    }
  }
  return true
}

export const alignsWith = curry(_alignsWith)
