import { mapState } from 'vuex'
import {
  Module,
  CURRENT_LOCATION,
  RouteName,
  GAME_PLAY,
  MessageTypeEnum,
  EMPTY_STRING,
} from '@/constants'
import {
  setBodyOverflow,
  stopGameSession,
  toggleFullScreenMode,
  // exitFullScreenMode,
} from '@/helpers'

const ROW = 'row'
const CONTAINER_GAME_PLAY = 'container-game-play'
const CSN_GAME_PLAY_CONTAINER_DEFAULT = 'csn-game-play-container-default'

export const gameMixin = {
  data() {
    return {
      screenBasedStyle: EMPTY_STRING,
    }
  },
  computed: {
    ...mapState(Module.LOCATION, [CURRENT_LOCATION]),
    gameRouteName: () => RouteName.GAME,
    msgType: () => MessageTypeEnum.FAILURE,
    gameSlug() {
      return this.CURRENT_LOCATION.params.slug
    },
    isGamePlayRoute() {
      return this.CURRENT_LOCATION.name === RouteName.GAME_PLAY
    },
    t() {
      return this.$createComponentTranslator(GAME_PLAY)
    },
    gameContainerClassList() {
      const defaultContainerClass = this.isGamePlayRoute
        ? CSN_GAME_PLAY_CONTAINER_DEFAULT
        : EMPTY_STRING

      return [ROW, CONTAINER_GAME_PLAY, defaultContainerClass]
    },
  },
  methods: {
    toggleFullScreenMode,
    // handleGameListButtonClick() {
    //   stopGameSession()
    //   exitFullScreenMode()
    // },
  },
  beforeRouteLeave(to, from, next) {
    const isLeavingGamePlay = from.name === RouteName.GAME_PLAY

    setBodyOverflow()
    isLeavingGamePlay && stopGameSession()
    next()
  },
}
