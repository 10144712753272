import { GameApi } from '@/api'
import {
  GAME_VENDOR_DICTIONARY,
  SET_GAME_VENDOR_DICTIONARY,
  IS_GAME_VENDOR_PENDING,
  SET_IS_GAME_VENDOR_PENDING,
  ResponseState,
  SLUG,
  URL_SLUG,
  ITEMS,
} from '@/constants'
import { indexBy, map, pipe, when, path } from '@/helpers'

const gameVendorDictionaryInitialValue = {}
const initialIsPendingValue = false

export const gameVendor = {
  namespaced: true,
  state: {
    [GAME_VENDOR_DICTIONARY]: gameVendorDictionaryInitialValue,
    [IS_GAME_VENDOR_PENDING]: initialIsPendingValue,
  },
  mutations: {
    [SET_GAME_VENDOR_DICTIONARY](state, { dictionary }) {
      state[GAME_VENDOR_DICTIONARY] = dictionary
    },
    [SET_IS_GAME_VENDOR_PENDING](state, { value }) {
      state[IS_GAME_VENDOR_PENDING] = value
    },
  },
  actions: {
    async [SET_GAME_VENDOR_DICTIONARY](context) {
      try {
        context.commit(SET_IS_GAME_VENDOR_PENDING, { value: true })

        const { data, state } = await GameApi.getVendorListWithGames()

        if (state === ResponseState.OK) {
          pipe(
            path([ITEMS]),
            when(
              Array.isArray,
              pipe(
                indexBy(SLUG),
                map((vendor) => ({
                  ...vendor,
                  games: indexBy(URL_SLUG)(vendor.games),
                })),
                (dictionary) => {
                  context.commit(SET_GAME_VENDOR_DICTIONARY, { dictionary })
                  context.commit(SET_IS_GAME_VENDOR_PENDING, {
                    value: initialIsPendingValue,
                  })
                },
              ),
            ),
          )(data)
        }
      } catch (e) {
        console.dir(e)
      }
    },
  },
}
