import { AuthApi } from '@/api'
import {
  WITHDRAWAL_METHOD_DICTIONARY,
  SET_WITHDRAWAL_METHOD_DICTIONARY,
  IS_WITHDRAWAL_METHOD_PENDING,
  SET_IS_WITHDRAWAL_METHOD_PENDING,
  DROP_WITHDRAWAL_METHOD,
  ResponseState,
} from '@/constants'
import { indexBy, when, pipe } from '@/helpers'

const withdrawalMethodDictionaryInitialValue = {}
const isWithdrawalMethodPendingInitialValue = false

const PAYMENT_METHOD_ID = 'paymentMethodId'

export const withdrawalMethod = {
  namespaced: true,
  state: {
    [WITHDRAWAL_METHOD_DICTIONARY]: withdrawalMethodDictionaryInitialValue,
    [IS_WITHDRAWAL_METHOD_PENDING]: isWithdrawalMethodPendingInitialValue,
  },
  mutations: {
    [SET_WITHDRAWAL_METHOD_DICTIONARY](state, dictionary) {
      state[WITHDRAWAL_METHOD_DICTIONARY] = dictionary
    },
    [SET_IS_WITHDRAWAL_METHOD_PENDING](state, value) {
      state[IS_WITHDRAWAL_METHOD_PENDING] = value
    },
  },
  actions: {
    async [SET_WITHDRAWAL_METHOD_DICTIONARY](context) {
      try {
        context.commit(SET_IS_WITHDRAWAL_METHOD_PENDING, true)

        const { data, state } = await AuthApi.getWithdrawalMethods()

        if (state === ResponseState.OK) {
          when(
            Array.isArray,
            pipe(indexBy(PAYMENT_METHOD_ID), (dictionary) => {
              context.commit(SET_WITHDRAWAL_METHOD_DICTIONARY, dictionary)
              context.commit(
                SET_IS_WITHDRAWAL_METHOD_PENDING,
                isWithdrawalMethodPendingInitialValue,
              )
            }),
          )(data?.items)
        }
      } catch (e) {
        console.dir(e)
      }
    },
    [DROP_WITHDRAWAL_METHOD](context) {
      context.commit(
        SET_WITHDRAWAL_METHOD_DICTIONARY,
        withdrawalMethodDictionaryInitialValue,
      )
    },
  },
}
