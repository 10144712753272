import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  Module,
  IS_LOGGED,
  UPDATE_CASINO_INFO,
  LanguageKey,
  CURRENT_MODAL,
  RouteName,
  SHOWS_FOOTER,
  TOGGLE_FOOTER,
  HISTORY,
  Digit,
  DROP_FLASH,
  MUST_CHANGE_PASSWORD,
  IS_EMPTY_USER_ADDRESS,
  SHOWS_MAIN_MENU,
  SHOWS_SEARCH_MENU,
  TOGGLE_SEARCH_MENU,
  TOGGLE_MAIN_MENU,
} from '@/constants'
import {
  getGetter,
  dispatchAction,
  updateLocation,
  getState,
  prependLocaleToPath,
  setBodyOverflow,
  commitMutation,
} from '@/helpers'
import { routes } from '@/router/routes'
import { getTitle } from './getTitle'

Vue.use(VueRouter)

const router = new VueRouter({
  scrollBehavior() {
    return { x: Digit.NOUGHT, y: Digit.NOUGHT }
  },
  mode: HISTORY,
  base: process.env.BASE_URL,
  routes,
})
const userEditRoute = { name: RouteName.USER_EDIT }

router.beforeEach(async (to, from, next) => {
  const isLogged = getGetter([Module.USER, IS_LOGGED])
  const mustChangePassword = getState([Module.USER, MUST_CHANGE_PASSWORD])
  const hasEmptyAddress = getGetter([Module.USER, IS_EMPTY_USER_ADDRESS])
  const toChangePasswordRoute = to?.name === RouteName.USER_CHANGE_PASSWORD
  const toUserEditRoute = to?.name === RouteName.USER_EDIT
  const mustRedirectToPassword = mustChangePassword && toChangePasswordRoute
  const mustRedirectToUserEdit =
    isLogged && hasEmptyAddress && !toUserEditRoute && !mustRedirectToPassword
  const nextRoute = mustRedirectToUserEdit ? userEditRoute : to
  const lang = nextRoute?.params?.lang
  const showsMainMenu = getState([Module.MAIN, SHOWS_MAIN_MENU])
  const showsSearchMenu = getState([Module.MAIN, SHOWS_SEARCH_MENU])
  const toSearchResultRoute = to.name === RouteName.SEARCH_RESULT
  const mustDropSearchMenu = showsSearchMenu && !toSearchResultRoute

  showsMainMenu && commitMutation([Module.MAIN, TOGGLE_MAIN_MENU])
  mustDropSearchMenu && commitMutation([Module.MAIN, TOGGLE_SEARCH_MENU], false)
  commitMutation([Module.FLASH, DROP_FLASH])
  isLogged && dispatchAction([Module.CASINO_INFO, UPDATE_CASINO_INFO])

  if (!Object.values(LanguageKey).includes(lang)) {
    const nextRouteWithLocale = prependLocaleToPath(nextRoute)

    updateLocation({ to: nextRouteWithLocale, from })
    next(nextRouteWithLocale)
    return
  }

  getTitle(nextRoute)

  window.i18n.locale !== lang && (window.i18n.locale = lang)
  updateLocation({ to: nextRoute, from })
  next()
})

router.afterEach((to) => {
  const hasModal = Boolean(getGetter([Module.MODAL, CURRENT_MODAL]))
  const nextRouteName = to.name
  const hasFooter =
    nextRouteName !== RouteName.GAME_PLAY &&
    nextRouteName !== RouteName.GAME_DEMO
  const isFooterShown = getState([Module.MAIN, SHOWS_FOOTER])
  const needsFooter = hasFooter && !isFooterShown
  const needsFooterHide = !hasFooter && isFooterShown

  hasModal && setBodyOverflow({ hidden: true })
  needsFooter && commitMutation([Module.MAIN, TOGGLE_FOOTER], true)
  needsFooterHide && commitMutation([Module.MAIN, TOGGLE_FOOTER], false)
})

export default router
