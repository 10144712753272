import { mapState, mapMutations } from 'vuex'
import { AuthApi } from '@/api'
import {
  Module,
  IS_LOGGED,
  Digit,
  RouteName,
  SHOW_MODAL,
  REALITY_CHECK_MODAL,
  SET_IS_SESSION_CONTROL_PENDING,
  IS_SESSION_CONTROL_PENDING,
  MUST_CHANGE_PASSWORD,
  SET_MUST_CHANGE_PASSWORD,
  HAS_DEPOSIT,
  SET_HAS_DEPOSIT,
  PAY_AND_PLAY_SESSION_DETAILS,
  UPDATE_USER_NOTIFICATION_LIST,
} from '@/constants'
import {
  requestInterval,
  logout,
  commitMutation,
  getGetter,
  navigateTo,
  getState,
} from '@/helpers'

const requestInitialValue = null

export const sessionControlMixin = {
  data() {
    return {
      sessionControlRequest: requestInitialValue,
    }
  },
  computed: {
    ...mapState(Module.MAIN, { IS_SESSION_CONTROL_PENDING }),
    isLogged: () => getGetter([Module.USER, IS_LOGGED]),
  },
  methods: {
    ...mapMutations(Module.USER_NOTIFICATION, [UPDATE_USER_NOTIFICATION_LIST]),
    async controlSession() {
      await this.clearSessionControlRequest()

      this.handleSessionControl()
      this.sessionControlRequest = requestInterval(
        this.handleSessionControl,
        Digit.FIVE_THOUSAND,
      )
    },
    async handleSessionControl() {
      if (this.IS_SESSION_CONTROL_PENDING || !this.isLogged) {
        return
      }
      commitMutation([Module.MAIN, SET_IS_SESSION_CONTROL_PENDING], true)

      const routeName = this.$router.history.current.name
      const { data } = await AuthApi.getUserControl()
      const {
        sessionStatus,
        callRealityCheck,
        requiredPasswdChange,
        isDepositExist,
        callSessionDetails,
        notifications,
      } = data || {}
      const isGamePlayRoute = routeName === RouteName.GAME_PLAY
      const isPasswordChangeRoute = routeName === RouteName.USER_CHANGE_PASSWORD
      const mustChangePassword = getState([Module.USER, MUST_CHANGE_PASSWORD])
      const hasDepositCurrentValue = getState([Module.USER, HAS_DEPOSIT])

      this.UPDATE_USER_NOTIFICATION_LIST({ value: notifications })

      if (!sessionStatus && this.isLogged) {
        logout({ nextRoute: { name: RouteName.LOGIN } })
        return
      }

      if (requiredPasswdChange) {
        !isPasswordChangeRoute &&
          navigateTo({ name: RouteName.USER_CHANGE_PASSWORD })

        !mustChangePassword &&
          commitMutation([Module.USER, SET_MUST_CHANGE_PASSWORD], true)
      } else {
        mustChangePassword &&
          commitMutation([Module.USER, SET_MUST_CHANGE_PASSWORD], false)
      }

      if (isDepositExist !== hasDepositCurrentValue) {
        commitMutation([Module.USER, SET_HAS_DEPOSIT], isDepositExist)
      }

      if (callRealityCheck && isGamePlayRoute && this.isLogged) {
        commitMutation([Module.MODAL, SHOW_MODAL], {
          component: REALITY_CHECK_MODAL,
          isStrict: true,
        })

        return
      }

      if (callSessionDetails) {
        console.log({ callSessionDetails })
        commitMutation([Module.MODAL, SHOW_MODAL], {
          component: PAY_AND_PLAY_SESSION_DETAILS,
          isStrict: true,
        })

        return
      }

      commitMutation([Module.MAIN, SET_IS_SESSION_CONTROL_PENDING], false)
    },
    clearSessionControlRequest() {
      if (!this.sessionControlRequest) {
        return
      }

      Promise.all([
        this.IS_SESSION_CONTROL_PENDING &&
          commitMutation([Module.MAIN, SET_IS_SESSION_CONTROL_PENDING], false),
        this.sessionControlRequest.clear(),
      ]).then(() => {
        this.sessionControlRequest = requestInitialValue
      })
    },
  },
  watch: {
    isLogged: {
      immediate: true,
      handler(isLogged) {
        isLogged ? this.controlSession() : this.clearSessionControlRequest()
      },
    },
  },
  created() {
    commitMutation([Module.MAIN, SET_IS_SESSION_CONTROL_PENDING], false)
  },
  beforeDestroy() {
    this.sessionControlRequest && this.clearSessionControlRequest()
  },
}
