export const CasinoInfo = {
  accountBalance: 'accountBalance',
  accountBonusMoney: 'accountBonusMoney',
  accountRealMoney: 'accountRealMoney',
  accountWithdrawableMoney: 'accountWithdrawableMoney',
  accountNonWithdrawableMoney: 'accountNonWithdrawableMoney',
  bonusInfo: 'bonusInfo',
  cardId: 'cardId',
  cardExpiryDate: 'cardExpiryDate',
  cardName: 'cardName',
  cardLevel: 'cardLevel',
  firstName: 'firstName',
  lastName: 'lastName',
  platinCoins: 'platinCoins',
  session: 'session',
}

export const CasinoInfoModel = (data) => {
  return {
    [CasinoInfo.accountBalance]: data?.accountBalance,
    [CasinoInfo.accountBonusMoney]: data?.accountBonusMoney,
    [CasinoInfo.accountRealMoney]: data?.accountRealMoney,
    [CasinoInfo.accountWithdrawableMoney]: data?.accountWithdrawableMoney,
    [CasinoInfo.accountNonWithdrawableMoney]: data?.accountNonWithdrawableMoney,
    [CasinoInfo.bonusInfo]: data?.bonusInfo,
    [CasinoInfo.cardId]: data?.cardId,
    [CasinoInfo.cardExpiryDate]: data?.cardExpiryDate,
    [CasinoInfo.cardName]: data?.cardName,
    [CasinoInfo.cardLevel]: data?.cardLevel,
    [CasinoInfo.firstName]: data?.firstName,
    [CasinoInfo.lastName]: data?.lastName,
    [CasinoInfo.platinCoins]: data?.platinCoins,
    [CasinoInfo.session]: data?.session,
  }
}
