import { api } from './axios'
import { Endpoint, EMPTY_OBJECT, nodeEnv } from '../constants'
import { ApiService } from './apiService'

export class AuthApi extends ApiService {
  static changePassword(data) {
    return api.patch(Endpoint.API_AUTH_USER_CHANGE_PASSWORD, data)
  }
  static addGameToFavourite({ id }) {
    return api.post(
      super.formatUrl(Endpoint.API_AUTH_GAME_ADD_TO_FAVOURITE, { id }),
    )
  }
  static getGamePlay({ urlSlug }) {
    return api.get(super.formatUrl(Endpoint.API_AUTH_GAME_PLAY, { urlSlug }))
  }
  static removeGameFromFavourite({ id }) {
    return api.delete(
      super.formatUrl(Endpoint.API_AUTH_GAME_REMOVE_FROM_FAVOURITE, {
        id,
      }),
    )
  }
  static stopGame({ urlSlug }) {
    return api.get(super.formatUrl(Endpoint.API_AUTH_GAME_STOP, { urlSlug }))
  }
  static getUserCasinoInfo() {
    return api.get(Endpoint.API_AUTH_USER_CASINO_INFO)
  }
  static getUserInfo() {
    return api.get(Endpoint.API_AUTH_USER_INFO)
  }
  static getUserNewsletter() {
    return api.get(Endpoint.API_AUTH_USER_NEWSLETTER)
  }
  static postUserEdit(data) {
    return api.post(Endpoint.API_AUTH_USER_EDIT, data)
  }
  static postUserNewsletter(data) {
    return api.patch(Endpoint.API_AUTH_USER_NEWSLETTER, data)
  }
  static getDeposits({ dateFrom, dateTo, page = 1, limit = 5 } = EMPTY_OBJECT) {
    const params = {
      ...(dateFrom ? { dateFrom } : EMPTY_OBJECT),
      ...(dateTo ? { dateTo } : EMPTY_OBJECT),
      ...(page ? { page } : EMPTY_OBJECT),
      ...(limit ? { limit } : EMPTY_OBJECT),
    }

    return api.get(Endpoint.API_AUTH_USER_DEPOSIT, { params })
  }
  static getDepositMethods() {
    return api.get(Endpoint.API_AUTH_USER_DEPOSIT_METHODS)
  }
  static addDeposit(data) {
    return api.post(Endpoint.API_AUTH_DEPOSIT_ADD, data)
  }
  static getGameRounds({ dateFrom, dateTo, page, limit } = EMPTY_OBJECT) {
    const params = {
      ...(dateFrom ? { dateFrom } : EMPTY_OBJECT),
      ...(dateTo ? { dateTo } : EMPTY_OBJECT),
      ...(page ? { page } : EMPTY_OBJECT),
      ...(limit ? { limit } : EMPTY_OBJECT),
    }

    return api.get(Endpoint.API_AUTH_USER_GAME_ROUNDS, { params })
  }
  static getDepositDetails({ token }) {
    return api.get(
      super.formatUrl(Endpoint.API_AUTH_DEPOSIT_DETAILS, { token }),
    )
  }
  static getTransactions({ dateFrom, dateTo, page, limit } = EMPTY_OBJECT) {
    const params = {
      ...(dateFrom ? { dateFrom } : EMPTY_OBJECT),
      ...(dateTo ? { dateTo } : EMPTY_OBJECT),
      ...(page ? { page } : EMPTY_OBJECT),
      ...(limit ? { limit } : EMPTY_OBJECT),
    }

    return process.env.NODE_ENV === nodeEnv.DEVELOPMENT
      ? api.get(Endpoint.API_FIXTURE_USER_TRANSACTIONS, { params })
      : api.get(Endpoint.API_AUTH_USER_TRANSACTIONS, { params })
  }
  static getUserLimits() {
    return api.get(Endpoint.API_AUTH_USER_LIMITS)
  }
  static postUserLimits({ type, request }) {
    return api.post(
      super.formatUrl(Endpoint.API_AUTH_USER_LIMITS_TYPE, { type }),
      request,
    )
  }
  static getDocumentTypes() {
    return api.get(Endpoint.API_AUTH_USER_DOCUMENTS_TYPES)
  }
  static postDocument(request) {
    return api.post(Endpoint.API_AUTH_USER_DOCUMENTS, request)
  }
  static getDocuments() {
    return api.get(Endpoint.API_AUTH_USER_DOCUMENTS)
  }
  static getWithdrawalMethods() {
    return api.get(Endpoint.API_AUTH_WITHDRAWAL_METHODS)
  }
  static addWithdrawal(data) {
    return api.post(Endpoint.API_AUTH_WITHDRAWAL_ADD, data)
  }
  static getUserControl() {
    return api.get(Endpoint.API_AUTH_USER_CONTROL)
  }
  static getRealityCheck() {
    return api.get(Endpoint.API_AUTH_USER_REALITY_CHECK)
  }
  static confirmReality() {
    return api.patch(Endpoint.API_AUTH_USER_REALITY_CONFIRM)
  }
  static rejectReality() {
    return api.post(Endpoint.API_AUTH_USER_REALITY_REJECT)
  }
  static postSelfExclusion(data) {
    return api.post(Endpoint.API_AUTH_USER_SELF_EXCLUSION, data)
  }
  static postGameRating(data) {
    return api.post(Endpoint.API_AUTH_GAME_RATING, data)
  }
  static postBrowserHistory(data) {
    return api.post(Endpoint.API_AUTH_USER_BROWSER_HISTORY, data)
  }
  static getExampleAmount() {
    return api.get(Endpoint.API_AUTH_DEPOSIT_EXAMPLE_AMOUNT)
  }
  static getWithdrawalDetails({ id }) {
    return api.get(
      super.formatUrl(Endpoint.API_AUTH_WITHDRAWAL_DETAILS, { id }),
    )
  }
  static getSelfExclusionReasons() {
    return api.get(Endpoint.API_AUTH_USER_SELF_EXCLUSION_REASONS)
  }
}
