import { isArrayOfPrimitives } from '../array'
import { curry } from '../basic/curry'
import { prop } from './prop'
import { Type } from '../../../constants'
import { isPlainObject } from './isPlainObject'

/**
 * path(['a', 'b'], {a: {b: 2}}); //=> 2
 * path(['a', 'b'], {c: {b: 2}}); //=> undefined
 */

const _path = (pathArray, obj) => {
  if (!isArrayOfPrimitives(pathArray) || !isPlainObject(obj)) {
    return
  }
  let nestedValue = obj

  for (let path of pathArray) {
    const value = prop(path, nestedValue)
    nestedValue = value

    if (typeof value === Type.UNDEFINED) {
      break
    }
  }

  return nestedValue
}

export const path = curry(_path)
