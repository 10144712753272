import { api } from './axios'
import { Endpoint, EMPTY_OBJECT } from '../constants'
import { ApiService } from './apiService'

export class GameApi extends ApiService {
  static getGameDemo({ urlSlug }) {
    return api.get(super.formatUrl(Endpoint.API_OPEN_GAME_DEMO, { urlSlug }))
  }
  static getFavouriteGameList({ page, limit }) {
    return api.get(Endpoint.API_OPEN_GAME_FAVOURITES, {
      params: { page, limit },
    })
  }
  static getFreePlayGameList({ page }) {
    return api.get(Endpoint.API_OPEN_GAME_FREE_PLAY, { params: { page } })
  }
  static getNewGameList({ page, limit }) {
    return api.get(Endpoint.API_OPEN_GAME_NEW, { params: { page, limit } })
  }
  static getGameList({ page }) {
    return api.get(Endpoint.API_OPEN_GAME_LIST, { params: { page } })
  }
  static getGameNotifications() {
    return api.get(Endpoint.API_OPEN_GAME_NOTIFICATIONS)
  }
  static getLiveCasinoGameList({ page, limit }) {
    return api.get(Endpoint.API_OPEN_GAME_LIVE_CASINO, {
      params: { page, limit },
    })
  }
  static getRecentGameList({ page, limit }) {
    return api.get(Endpoint.API_OPEN_GAME_RECENTLY_PLAYED, {
      params: { page, limit },
    })
  }
  static getRecommendedGameList({ page, limit }) {
    return api.get(Endpoint.API_OPEN_GAME_RECOMMENDED, {
      params: { page, limit },
    })
  }
  static getSlotList({ page, limit }) {
    return api.get(Endpoint.API_OPEN_GAME_SLOTS, { params: { page, limit } })
  }
  static getTopGameList({ page, limit }) {
    return api.get(Endpoint.API_OPEN_GAME_TOP, { params: { page, limit } })
  }
  static getGameVendor({ id, page, limit }) {
    return api.get(super.formatUrl(Endpoint.API_OPEN_GAME_VENDOR, { id }), {
      params: { page, limit },
    })
  }
  static getGameVendors({ page, limit } = EMPTY_OBJECT) {
    return api.get(Endpoint.API_OPEN_GAME_VENDORS, {
      params: {
        ...(page ? { page } : EMPTY_OBJECT),
        ...(limit ? { limit } : EMPTY_OBJECT),
      },
    })
  }
  static getVendorListWithGames() {
    return api.get(Endpoint.API_OPEN_GAME_VENDORS_WITH_GAMES)
  }
  static getGameDetails({ slugName }) {
    return api.get(
      super.formatUrl(Endpoint.API_OPEN_GAME_DETAILS, { slugName }),
    )
  }
  static getGameInfo({ urlSlug }) {
    return api.get(super.formatUrl(Endpoint.API_OPEN_GAME_INFO, { urlSlug }))
  }
  static getGameSearch(params) {
    return api.get(Endpoint.API_OPEN_GAME_SEARCH, { params })
  }
  static getGameRating({ id }) {
    return api.get(super.formatUrl(Endpoint.API_OPEN_GAME_RATING_ID, { id }))
  }
  static getGameCategoryList() {
    return api.get(Endpoint.API_OPEN_GAME_CATEGORIES)
  }
  static getGameCategoryItemList({ param, page, limit }) {
    return api.get(
      super.formatUrl(Endpoint.API_OPEN_GAME_CATEGORY, { param }),
      { params: { page, limit } },
    )
  }
}
