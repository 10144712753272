import { AuthApi } from '@/api'
import {
  DOCUMENT_DICTIONARY,
  UPDATE_DOCUMENT_DICTIONARY,
  IS_DOCUMENT_PENDING,
  SET_IS_DOCUMENT_PENDING,
  DROP_DOCUMENT,
  ResponseState,
  EMPTY_OBJECT,
  ITEMS,
  ID,
  DOCUMENT_MESSAGE,
  SET_DOCUMENT_MESSAGE,
  EMPTY_STRING,
} from '@/constants'
import {
  pipe,
  map,
  when,
  assoc,
  path,
  indexBy,
  isArray,
  generateUUID,
} from '@/helpers'

const initialIsPendingValue = false

export const document = {
  namespaced: true,
  state: {
    [DOCUMENT_DICTIONARY]: EMPTY_OBJECT,
    [IS_DOCUMENT_PENDING]: initialIsPendingValue,
    [DOCUMENT_MESSAGE]: EMPTY_STRING,
  },
  mutations: {
    [UPDATE_DOCUMENT_DICTIONARY](state, value) {
      state[DOCUMENT_DICTIONARY] = value
    },
    [SET_IS_DOCUMENT_PENDING](state, value) {
      state[IS_DOCUMENT_PENDING] = value
    },
    [SET_DOCUMENT_MESSAGE](state, value) {
      state[DOCUMENT_MESSAGE] = value
    },
  },
  actions: {
    async [UPDATE_DOCUMENT_DICTIONARY](context) {
      try {
        context.commit(SET_IS_DOCUMENT_PENDING, true)

        const { data, state, msg } = await AuthApi.getDocuments()

        if (state === ResponseState.OK) {
          pipe(
            path([ITEMS]),
            when(
              isArray,
              pipe(
                map((doc) => assoc(ID, generateUUID(), doc)),
                indexBy(ID),
                (value) => {
                  context.commit(UPDATE_DOCUMENT_DICTIONARY, value)

                  context.state[DOCUMENT_MESSAGE] !== EMPTY_STRING &&
                    context.commit(SET_DOCUMENT_MESSAGE, EMPTY_STRING)
                },
              ),
            ),
          )(data)
        }

        if (state === ResponseState.ERROR) {
          context.commit(SET_DOCUMENT_MESSAGE, msg)
        }
      } catch (e) {
        console.dir(e)
      } finally {
        context.commit(SET_IS_DOCUMENT_PENDING, false)
      }
    },
    [DROP_DOCUMENT](context) {
      context.commit(UPDATE_DOCUMENT_DICTIONARY, EMPTY_OBJECT)
    },
  },
}
