import { EMPTY_ARRAY } from '@/constants'
import { isPlainObject } from './pipeline/object/isPlainObject'

export const createComponentTranslator = function (componentName) {
  return function (keyId, fallbackOrOptions, options) {
    const translateKey = `${componentName}.${keyId}`

    return this.$t(
      translateKey,
      fallbackOrOptions,
      ...(isPlainObject(options) ? [options] : EMPTY_ARRAY),
    )
  }
}
