import { curry } from '../basic/curry';

/**
 * It takes an indexField and object array and returns an object which becomes a dictionary
 * whose values are the same objects from the object array, indexed by the given key - indexField.
 *
 * The keys of items of returned object will be their common property which is specified in indexField.
 * Be sure they all have the necessary field, specified in indexField, and that the values of that field are unique for each object (in case the new object has to have all the objects from the array).
 *
 * In case, array item object does not have the needed field, a returned object will have a key "undefined" with that array item.
 * In case, some array items have the indexField with the same values, the latter item in the iteration will replace the previous item with that key in returned object.
 *
 * @param {string} indexField
 * @param {object[]} array
 *
 * @returns {object} object with values which are object from the array.
 *
 * Example:
 * const list = [{id: 'xyz', title: 'A'}, {id: 'abc', title: 'B'}];
 * indexBy('id', list); // => {abc: {id: 'abc', title: 'B'}, xyz: {id: 'xyz', title: 'A'}}
 **/

const _indexBy = (indexField, array) => {
  const dictionary = {};

  try {
    (array || []).map(item => {
      const index = item?.[indexField];

      return (dictionary[index] = item);
    });
  } catch (e) {
    console.error(e);
  }

  return dictionary;
};

export const indexBy = curry(_indexBy);
