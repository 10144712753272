import { RouteName } from '@/constants'
import { navigateTo } from '@/helpers'

export const modalMixin = {
  methods: {
    goToHomepage() {
      navigateTo({
        name: RouteName.HOME,
      })
    },
    goToNeedHelp() {
      navigateTo({ name: RouteName.NEED_HELP })
    },
  },
}
