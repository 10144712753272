import { Digit } from '@/constants'

export const stringComparator = (property) => {
  return (a, b) => {
    const stringA = property ? a[property].toUpperCase() : a.toUpperCase()
    const stringB = property ? b[property].toUpperCase() : b.toUpperCase()

    if (stringA < stringB) {
      return -Digit.ONE
    }
    if (stringA > stringB) {
      return Digit.ONE
    }
    return Digit.NOUGHT
  }
}
