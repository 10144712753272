export const ACTIVE = 'active'
export const AUTO = 'auto'
export const BODY = 'body'
export const COLON = ':'
export const DATA = 'data'
export const EMPTY_STRING = ''
export const EMPTY_STR = EMPTY_STRING
export const EMPTY_ARRAY = Object.freeze([])
export const EMPTY_OBJECT = Object.freeze({})
export const FORM_ERROR = 'formError'
export const HASH = '#'
export const HIDDEN = 'hidden'
export const HISTORY = 'history'
export const ID = 'id'
export const IN = 'in'
export const ITEMS = 'items'
export const NAME = 'name'
export const ON = 'on'
export const OFF = 'off'
export const READONLY = 'readonly'
export const SLASH = '/'
export const SLUG = 'slug'
// export const TITLE = 'title'
export const URL = 'URL'
export const URL_SLUG = 'urlSlug'
export const UUID = 'uuid'
export const VALUE = 'value'
