import { Type } from '../../../constants'

/**
 * @param {any} value
 * @returns {boolean} true in case the value is string
 **/

export const isString = (value) => {
  return typeof value === Type.STRING
}
