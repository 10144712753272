import { StatusCode } from '@/constants'
import { logout } from '@/helpers'

export const interceptUrl = (request) => {
  request.url = `${request.baseURL}${request.url}`
  return request
}

export const interceptResponse = (response) => {
  return response.data
}

export const interceptError = async (error) => {
  const isUnauthorized = error?.response?.status === StatusCode.UNAUTHORIZED

  isUnauthorized && (await logout())
  return error?.response?.data || error?.message
}
