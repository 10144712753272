import { curry } from '../basic/curry'
import { isPlainObject } from './isPlainObject'

const _pick = (propArrayList, obj) => {
  if (!Array.isArray(propArrayList) || !isPlainObject(obj)) {
    return
  }

  const newObj = {}

  propArrayList.forEach((prop) => {
    newObj[prop] = obj[prop]
  })

  return newObj
}

export const pick = curry(_pick)
