import router from '@/router'
import { EMPTY_OBJECT } from '@/constants'
import { isPlainObject, alignsWith, callFn } from '@/helpers'

export const navigateTo = async (nextRoute, options = EMPTY_OBJECT) => {
  const { isStrict, onNavigate } = options
  const currentRoute = isPlainObject(nextRoute)
    ? router.history.current
    : router.history.current.fullPath

  if (!isStrict && alignsWith(currentRoute, nextRoute)) {
    return
  }

  await router.push(nextRoute)

  callFn(onNavigate)
}
