import {
  SCREEN_WIDTH,
  SET_SCREEN_WIDTH,
  IS_MOBILE_SCREEN,
  Digit,
} from '@/constants'

export const device = {
  namespaced: true,
  state: {
    [SCREEN_WIDTH]: window.innerWidth,
  },
  mutations: {
    [SET_SCREEN_WIDTH](state, value) {
      state[SCREEN_WIDTH] = value
    },
  },
  getters: {
    [IS_MOBILE_SCREEN]: (state) => {
      return state[SCREEN_WIDTH] <= Digit.SEVEN_HUNDRED_SIXTY_EIGHT
    },
  },
}
