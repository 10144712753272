import { callFn } from '../../callFn';

/**
 * @param {function} fn the function that will be curried.
 * @param {any} args the arguments that will be passed to the curried function

 * @returns a curried equivalent of the provided function.
 * The idea is that its arguments need not be provided all at once.
 **/

export const curry = (fn, ...args) => {
  return fn.length <= args.length
    ? callFn(fn, ...args)
    : (...moreArgs) => {
        return curry(fn, ...args, ...moreArgs);
      };
};
