import { isArray } from '../array'
import { isPlainObject } from '../object'
import { isNil } from './isNil'
import { Type } from '../../../constants'

const ZERO = 0
/**
 * @param {any} value
 * @returns {boolean}
 * returns "true" in case of empty array, empty object, or empty string: [], {}, ''.
 * Otherwise, it returns "false".
 **/

export const isEmpty = (value) => {
  return (
    (isArray(value) && value.length === ZERO) ||
    (isPlainObject(value) && Object.keys(value).length === ZERO) ||
    (typeof value === Type.STRING && !value.length)
  )
}

/**
 *
 * @param {any} value
 *
 * @returns {boolean}
 * returns "true" in case of empty array, empty object, or empty string: [], {}, ''
 * also, returns "true" in case of undefined and null.
 * Otherwise, it returns "false".
 **/

export const isEmptyOrNil = (value) => isNil(value) || isEmpty(value)
