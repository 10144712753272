import * as data from '../../version.json'
import { Module, SHOW_MODAL, STORE_UPDATE_MODAL } from '@/constants'
import { commitMutation } from './store/commitMutation'
import { callFn } from './callFn'

const VUEX = 'vuex'
const STORE_VERSION = 'store-version'
const SAME_VERSION_MESSAGE =
  'The version is the same. No need to clear the store.'

export const checkStoreVersion = () => {
  const buildVersion = data.default.version
  const savedVersion = localStorage.getItem(STORE_VERSION)

  if (buildVersion === savedVersion) {
    console.log(SAME_VERSION_MESSAGE)
    return
  }

  commitMutation([Module.MODAL, SHOW_MODAL], {
    component: STORE_UPDATE_MODAL,
    isStrict: true,
  })
}

export const updateStoreVersion = (fn) => {
  const buildVersion = data.default.version

  localStorage.removeItem(VUEX)
  localStorage.setItem(STORE_VERSION, buildVersion)

  callFn(fn)
}
