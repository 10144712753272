export const DepositMethod = {
  currency: 'currency',
  isDeposit: 'isDeposit',
  logo: 'logo',
  minAmountLimit: 'minAmountLimit',
  maxAmountLimit: 'maxAmountLimit',
  paymentMethodId: 'paymentMethodId',
  paymentMethodName: 'paymentMethodName',
  providerName: 'providerName',
  transactionTypeId: 'transactionTypeId',
}

export const DepositMethodModel = (data) => {
  return {
    [DepositMethod.currency]: data?.currency,
    [DepositMethod.isDeposit]: data?.isDeposit,
    [DepositMethod.logo]: data?.logo,
    [DepositMethod.minAmountLimit]: data?.minAmountLimit,
    [DepositMethod.maxAmountLimit]: data?.maxAmountLimit,
    [DepositMethod.paymentMethodId]: data?.paymentMethodId,
    [DepositMethod.paymentMethodName]: data?.paymentMethodName,
    [DepositMethod.providerName]: data?.providerName,
    [DepositMethod.transactionTypeId]: data?.transactionTypeId,
  }
}
