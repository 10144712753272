const ZERO = 0
/**
 * @param {array} array
 * @returns {any} the first element of the array
 */

export const head = (array) => {
  return Array.isArray(array) && array.length ? array[ZERO] : undefined
}

export const first = head
