import {
  ADD_FLASH,
  REMOVE_FLASH,
  FLASH_DICTIONARY,
  DROP_FLASH,
  HAS_LOGOUT_FLASH,
  FlashId,
} from '@/constants'
import { isNotNil } from '@/helpers'

const flashDictionaryInitialValue = {}

export const flash = {
  namespaced: true,
  state: {
    [FLASH_DICTIONARY]: flashDictionaryInitialValue,
  },
  mutations: {
    [ADD_FLASH](state, { message, type, id }) {
      const value = {
        ...state[FLASH_DICTIONARY],
        [id || Date.now()]: { message, type },
      }

      state[FLASH_DICTIONARY] = value
    },
    [REMOVE_FLASH](state, { id }) {
      const value = { ...state[FLASH_DICTIONARY] }
      delete value[id]

      state[FLASH_DICTIONARY] = value
    },
    [DROP_FLASH](state) {
      state[FLASH_DICTIONARY] = flashDictionaryInitialValue
    },
  },
  getters: {
    [HAS_LOGOUT_FLASH]: (state) => {
      return isNotNil(state[FLASH_DICTIONARY]?.[FlashId.LOGOUT_SUCCESS])
    },
  },
}
