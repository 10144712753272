import { GameApi } from '@/api'
import {
  GAME_INFO_DICTIONARY,
  UPDATE_GAME_INFO_DICITONARY,
  EMPTY_OBJECT,
  GAME_INFO_BY_SLUG,
  ResponseState,
} from '@/constants'

export const gameInfo = {
  namespaced: true,
  state: {
    [GAME_INFO_DICTIONARY]: EMPTY_OBJECT,
  },
  mutations: {
    [UPDATE_GAME_INFO_DICITONARY](state, gameInfo) {
      state[GAME_INFO_DICTIONARY] = {
        ...state[GAME_INFO_DICTIONARY],
        ...gameInfo,
      }
    },
  },
  actions: {
    async [UPDATE_GAME_INFO_DICITONARY](context, { urlSlug } = {}) {
      try {
        const { data, state } = await GameApi.getGameInfo({ urlSlug })

        if (state === ResponseState.OK && data?.game) {
          context.commit(UPDATE_GAME_INFO_DICITONARY, {
            [data.game.urlSlug]: data.game,
          })
        }
      } catch (e) {
        console.dir(e)
      }
    },
  },
  getters: {
    [GAME_INFO_BY_SLUG]: (state) => (urlSlug) => {
      return state[GAME_INFO_DICTIONARY][urlSlug]
    },
  },
}
