import { AuthApi } from '@/api'
import {
  DEPOSIT_METHOD_DICTIONARY,
  SET_DEPOSIT_METHOD_DICTIONARY,
  IS_DEPOSIT_METHOD_DICTIONARY_PENDING,
  SET_IS_METHOD_DICTIONARY_PENDING,
  DROP_DEPOSIT_METHOD_DICTIONARY,
  ResponseState,
} from '@/constants'
import { indexBy, pipe, isArray, map } from '@/helpers'
import { DepositMethodModel } from '@/models/DepositMethod'

const depositMethodDictionaryInitialValue = {}
const PAYMENT_METHOD_ID = 'paymentMethodId'

export const depositMethod = {
  namespaced: true,
  state: {
    [DEPOSIT_METHOD_DICTIONARY]: depositMethodDictionaryInitialValue,
    [IS_DEPOSIT_METHOD_DICTIONARY_PENDING]: false,
  },
  mutations: {
    [SET_DEPOSIT_METHOD_DICTIONARY](state, { dictionary }) {
      state[DEPOSIT_METHOD_DICTIONARY] = dictionary
    },
    [SET_IS_METHOD_DICTIONARY_PENDING](state, { value }) {
      state[IS_DEPOSIT_METHOD_DICTIONARY_PENDING] = value
    },
  },
  actions: {
    async [SET_DEPOSIT_METHOD_DICTIONARY](context) {
      try {
        context.commit(SET_IS_METHOD_DICTIONARY_PENDING, { value: true })

        const { data, state } = await AuthApi.getDepositMethods()

        if (state === ResponseState.OK && isArray(data?.items)) {
          pipe(
            map(DepositMethodModel),
            indexBy(PAYMENT_METHOD_ID),
            (dictionary) => {
              context.commit(SET_DEPOSIT_METHOD_DICTIONARY, { dictionary })
              context.commit(SET_IS_METHOD_DICTIONARY_PENDING, { value: false })
            },
          )(data.items)
        }
      } catch (e) {
        console.dir(e)
      }
    },
    [DROP_DEPOSIT_METHOD_DICTIONARY](context) {
      context.commit(SET_DEPOSIT_METHOD_DICTIONARY, {
        value: depositMethodDictionaryInitialValue,
      })
    },
  },
}
