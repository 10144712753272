import { Type, Digit, EMPTY_STRING } from '../constants'

const NOUGHT_STRING = '0'

export const removeLeadingZero = (value) => {
  if (typeof value !== Type.STRING) {
    return EMPTY_STRING
  }

  if (value[Digit.NOUGHT] !== NOUGHT_STRING) {
    return value
  }

  let newValue = value.slice(Digit.ONE)
  removeLeadingZero(newValue)
}
