import { commitMutation } from '@/helpers'
import { Module, SET_IS_PENDING } from '@/constants'

export const load = async (list = []) => {
  commitMutation([Module.MAIN, SET_IS_PENDING], true)
  const responseArray = await Promise.all(list)
  commitMutation([Module.MAIN, SET_IS_PENDING], false)

  return responseArray
}
