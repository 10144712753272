import { isNotNil } from "../basic/isNil";

/**
 * @param {array} array
 * @returns array with no null or undefined elements
 */

export const filterOutNil = (array) => {
  return array.filter(isNotNil);
};
