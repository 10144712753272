export const UserInfo = {
    SEX: "sex",
    FIRST_NAME: "first_name",
    LAST_NAME: "last_name",
    ADDRESS: "address",
    CITY: "city",
    COUNTRY: "country",
    ZIP_CODE: "zip_code",
    CITIZENSHIP: "citizenship",
    BIRTH_DATE: "date_of_birth_day",
    BIRTH_MONTH: "date_of_birth_month",
    BIRTH_YEAR: "date_of_birth_year",
    STATE: "state",
    BIRTH_COUNTRY: "birth_country",
    BIRTH_PLACE: "birth_place",
  };
