import { GameApi } from '@/api'
import {
  GAME_DEMO_DICTIONARY,
  UPDATE_GAME_DEMO_DICTIONARY,
  IS_GAME_DEMO_PENDING,
  SET_IS_GAME_DEMO_PENDING,
  ResponseState,
  EMPTY_OBJECT
} from '@/constants'

const isPendingInitialValue = false

export const gameDemo = {
  namespaced: true,
  state: {
    [GAME_DEMO_DICTIONARY]: EMPTY_OBJECT,
    [IS_GAME_DEMO_PENDING]: isPendingInitialValue,
  },
  mutations: {
    [UPDATE_GAME_DEMO_DICTIONARY](state, dictionary) {
      state[GAME_DEMO_DICTIONARY] = dictionary
    },
    [SET_IS_GAME_DEMO_PENDING](state, value) {
      state[IS_GAME_DEMO_PENDING] = value
    },
  },
  actions: {
    async [UPDATE_GAME_DEMO_DICTIONARY](context, { urlSlug }) {
      try {
        context.commit(SET_IS_GAME_DEMO_PENDING, true)

        const { state, data } = await GameApi.getGameDemo({ urlSlug })

        if (state === ResponseState.OK) {
          const dictionary = {
            ...context.state[GAME_DEMO_DICTIONARY],
            [urlSlug]: { ...data },
          }

          context.commit(UPDATE_GAME_DEMO_DICTIONARY, dictionary)
        }

        context.commit(SET_IS_GAME_DEMO_PENDING, isPendingInitialValue)
      } catch (e) {
        console.dir(e)
      }
    },
  },
}
