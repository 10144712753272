import { curry } from '../basic/curry'
import { isPlainObject } from './isPlainObject'

/**
 *
 * @param {*} propArrayList
 * @param {*} obj
 *
 * returns a copy of the object where all the properies mentioned in propArrayList are removed
 */

const _remove = (propArrayList, obj) => {
  if (!Array.isArray(propArrayList) || !isPlainObject(obj)) {
    return
  }

  const newObj = { ...obj }

  propArrayList.forEach((prop) => {
    delete newObj[prop]
  })

  return newObj
}

export const remove = curry(_remove)
