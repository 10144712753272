import { SLASH, Module, PERSONAL_INFO, EMPTY_STR } from '../constants'
import { getState, capitalizeFirstLetter, pipe } from '../helpers'

const DASH = ' - '

export const getTitle = (route) => {
  const titleBase =
    route.path
      .split(SLASH)
      .filter((item) => item && item !== route.params.lang)
      .map(pipe(capitalizeFirstLetter, (item) => item.replaceAll(`-`, ` `)))
      .join(DASH) || route.name
  const user = getState([Module.USER, PERSONAL_INFO])

  const titleUser =
    user && user?.first_name && user?.last_name
      ? `${DASH}${user.first_name} ${user.last_name}`
      : EMPTY_STR

  route?.name && (document.title = titleBase + titleUser)
}
