export const Filter = {
  ALL_CATEGORIES: 'AllCategories',
  ALL_PROVIDERS: 'AllProviders',
  FILTER_BY: 'FilterBy',
  ORDER_BY: 'OrderBy',
}

export const FilterByFilter = {
  TOP_GAMES: 'TopGames',
  NEW_GAMES: 'NewGames',
}

export const OrderByFilter = {
  A_Z: 'A_Z',
  Z_A: 'Z_A',
}

export const FilterByFilderId = {
  [FilterByFilter.TOP_GAMES]: 'hotFlag',
  [FilterByFilter.NEW_GAMES]: 'newFlag',
}

export const OrderByFilterId = {
  [OrderByFilter.A_Z]: 'asc',
  [OrderByFilter.Z_A]: 'desc',
}
