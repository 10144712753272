import { curry } from '../basic/curry'
import { isPlainObject } from './isPlainObject'

const _assoc = (prop, value, obj) => {
  if (!isPlainObject(obj)) {
    return
  }

  const newObj = { ...obj }

  newObj[prop] = value
  return newObj
}
export const assoc = curry(_assoc)
