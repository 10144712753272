import { LanguageKey } from '@/constants'
import { default as en } from '@/messages/en'
import { de } from '@/messages/de'
import { fi } from '@/messages/fi'

export const messages = {
  [LanguageKey.EN]: en,
  [LanguageKey.DE]: de,
  [LanguageKey.FI]: fi,
}
