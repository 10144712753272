import { curry } from './curry'
import { callFn } from '../../callFn'

const _ifElse = (predicate, whenTrueFn, whenFalseFn, finalArgument) => {
  return callFn(predicate, finalArgument)
    ? callFn(whenTrueFn, finalArgument)
    : callFn(whenFalseFn, finalArgument)
}

export const ifElse = curry(_ifElse)
