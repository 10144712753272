export const EventType = {
  BEFOREUNLOAD: 'beforeunload',
  BLUR: 'blur',
  CHANGE: 'change',
  CLICK: 'click',
  CLICK_OUTSIDE: 'click-outside',
  CLOSE: 'close',
  FOCUS: 'focus',
  INPUT: 'input',
  KEYDOWN: 'keydown',
  KEYPRESS: 'keypress',
  MOUSEDOWN: 'mousedown',
  MOUSEENTER: 'mouseenter',
  MOUSELEAVE: 'mouseleave',
  MOUSEMOVE: 'mousemove',
  MOUSEOVER: 'mouseover',
  MOUSEUP: 'mouseup',
  RESIZE: 'resize',
  SCROLL: 'scroll',
  TOGGLE: 'toggle',
}
