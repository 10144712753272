import { curry } from './curry'
import { callFn } from '../../callFn'

/**
 * Tests the final argument by passing it to predicate function.
 * If the predicate is satisfied, the function will return the result of calling the whenTrueFn function with the same argument.
 * If the predicate is not satisfied, the argument is returned as is.
 *
 * @param {function} predicate receives finalArgument. If the predicate returns true, whenTrueFn will be called and receive finalArgument.
 * @param {function} whenTrueFn is called if the predicate returns true; receives finalArgument. Its returned value will be returned by "when" function.
 * In case, the predicate returns false, "when" will return finalArgument unchanged.
 * @returns {any}
 *
 * The function is curried.
 */

const _when = (predicate, whenTrueFn, finalArgument) => {
  return callFn(predicate, finalArgument)
    ? callFn(whenTrueFn, finalArgument)
    : finalArgument
}

export const when = curry(_when)

/**
 * Opposite to when, returns the value as is if it satisfies the whenFalseFn function
 */

const _unless = (predicate, whenFalseFn, finalArgument) => {
  return callFn(predicate, finalArgument)
    ? finalArgument
    : callFn(whenFalseFn, finalArgument)
}

export const unless = curry(_unless)
