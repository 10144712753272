export const Type = {
  OBJECT: 'object',
  BOOLEAN: 'boolean',
  NUMBER: 'number',
  STRING: 'string',
  BIGINT: 'bigint',
  SYMBOL: 'symbol',
  UNDEFINED: 'undefined',
  FUNCTION: 'function',
}
