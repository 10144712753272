import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { LanguageKey } from '../constants'
import { messages } from '../messages'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: LanguageKey.EN,
  fallbackLocale: LanguageKey.EN,
  messages,
})
window.i18n = i18n
