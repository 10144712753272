import { MainApi, RewardApi } from '@/api'
import {
  SLIDE_LIST,
  SET_SLIDE_LIST,
  REWARD_SLIDE_LIST,
  SET_REWARD_SLIDE_LIST,
  EMPTY_ARRAY,
  ResponseState,
} from '@/constants'

export const slide = {
  namespaced: true,
  state: {
    [SLIDE_LIST]: EMPTY_ARRAY,
    [REWARD_SLIDE_LIST]: EMPTY_ARRAY,
  },
  mutations: {
    [SET_SLIDE_LIST](state, { list }) {
      state[SLIDE_LIST] = list
    },
    [SET_REWARD_SLIDE_LIST](state, { list }) {
      state[REWARD_SLIDE_LIST] = list
    },
  },
  actions: {
    async [SET_SLIDE_LIST](context) {
      try {
        const { data } = await MainApi.getSlideShow()

        if (Array.isArray(data)) {
          context.commit(SET_SLIDE_LIST, { list: data })
        }
      } catch (e) {
        console.dir(e)
      }
    },
    async [SET_REWARD_SLIDE_LIST](context) {
      try {
        const { data, state } = await RewardApi.getRewardSliderShow()

        if (ResponseState.OK === state && Array.isArray(data)) {
          context.commit(SET_REWARD_SLIDE_LIST, { list: data })
        }
      } catch (e) {
        console.dir(e)
      }
    },
  },
}
