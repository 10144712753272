import { OfferApi } from '@/api'
import {
  OFFER_DICTIONARY,
  SET_OFFER_DICTIONARY,
  IS_OFFER_PENDING,
  SET_IS_OFFER_PENDING,
  ResponseState,
  LAST_LOADED_PAGE,
  PAGE_TOTAL,
  HAS_MORE_PAGES,
  RESET_OFFER,
  EMPTY_OBJECT,
  Digit,
  EMPTY_STRING,
  REGEX_PRE_FILE_URI,
  REGEX_PRE_LAST_SLASH,
  NAME,
  URL,
  ID,
} from '@/constants'
import {
  gameDictionaryInitialValue,
  isGamePendingInitialValue,
  lastLoadedPageInitialValue,
  pageTotalInitialValue,
} from '@/values'
import { indexBy, isNil, pipe, map } from '@/helpers'

const imageList = require.context(
  '../../assets/images/rewards/slideshow',
  true,
  /\.(png|jpg|jpeg|svg)$/,
)

const fileNameDictionary = pipe(
  (images) => images.keys(),
  map((image) => ({
    [URL]: imageList(image),
    [NAME]: image.replace(REGEX_PRE_FILE_URI, EMPTY_STRING),
  })),
  indexBy(NAME),
)(imageList)

export const offer = {
  namespaced: true,
  state: {
    [OFFER_DICTIONARY]: gameDictionaryInitialValue,
    [IS_OFFER_PENDING]: isGamePendingInitialValue,
    [LAST_LOADED_PAGE]: lastLoadedPageInitialValue,
    [PAGE_TOTAL]: pageTotalInitialValue,
  },
  mutations: {
    [SET_OFFER_DICTIONARY](state, payload) {
      state[OFFER_DICTIONARY] = {
        ...(isNil(state[OFFER_DICTIONARY])
          ? EMPTY_OBJECT
          : state[OFFER_DICTIONARY]),
        ...payload[OFFER_DICTIONARY],
      }
      state[PAGE_TOTAL] = payload[PAGE_TOTAL]
      state[LAST_LOADED_PAGE] = payload[LAST_LOADED_PAGE]
    },
    [SET_IS_OFFER_PENDING](state, value) {
      state[IS_OFFER_PENDING] = value
    },
    [RESET_OFFER](state) {
      state[OFFER_DICTIONARY] = gameDictionaryInitialValue
      state[LAST_LOADED_PAGE] = lastLoadedPageInitialValue
      state[PAGE_TOTAL] = pageTotalInitialValue
    },
  },
  actions: {
    async [SET_OFFER_DICTIONARY](
      { commit, state: store, getters },
      { resetsData } = {},
    ) {
      if (resetsData) {
        commit(RESET_OFFER)
      }
      if (!getters[HAS_MORE_PAGES]) {
        return
      }

      try {
        commit(SET_IS_OFFER_PENDING, true)

        const page = store[LAST_LOADED_PAGE] + Digit.ONE
        const { data, state } = await OfferApi.getOfferList({ page })

        if (state === ResponseState.OK && Array.isArray(data?.items)) {
          const { items, itemsPerPage, total } = data

          const payload = {
            [OFFER_DICTIONARY]: pipe(
              map((offer) => {
                const imageName = offer.headerImage.replace(
                  REGEX_PRE_LAST_SLASH,
                  EMPTY_STRING,
                )

                return {
                  ...offer,
                  headerImage: fileNameDictionary[imageName][URL],
                }
              }),
              indexBy(ID),
            )(items),

            [PAGE_TOTAL]: Math.ceil(total / itemsPerPage),
            [LAST_LOADED_PAGE]: page,
          }

          commit(SET_OFFER_DICTIONARY, payload)
          commit(SET_IS_OFFER_PENDING, isGamePendingInitialValue)
        }
      } catch (e) {
        console.dir(e)
      }
    },
  },
  getters: {
    [HAS_MORE_PAGES]: (state) => {
      return state[PAGE_TOTAL] > state[LAST_LOADED_PAGE]
    },
  },
}
