import { Type } from "../../../constants";

const _primitiveTypeArray = Object.values(Type).filter((type) => {
  return type !== Type.OBJECT && type !== Type.FUNCTION;
});

/**
 * @param {any} instance
 * @returns {boolean} true if instance is of primitive type
 */

export const isPrimitive = (instance) => {
  return _primitiveTypeArray.some((type) => {
    return typeof instance === type;
  });
};
