import { Module, SCREEN_WIDTH, Digit } from '@/constants'
import { getState } from '@/helpers/store/getState'
import { listItemSpaceX } from '@/values'

export const getVisibleGameCardNumber = (width) => {
  const containerWidth = width || getState([Module.DEVICE, SCREEN_WIDTH])

  if (
    containerWidth < Digit.ONE_THOUSAND_ONE_HUNDRED_SIXTY &&
    containerWidth > Digit.SIX_HUNDRED_EIGHTY_ONE
  ) {
    return Digit.FOUR
  }

  if (
    containerWidth < Digit.SIX_HUNDRED_EIGHTY &&
    containerWidth > Digit.FIVE_HUNDRED_THIRTY
  ) {
    return Digit.THREE
  }

  if (containerWidth <= Digit.FIVE_HUNDRED_TWENTY_NINE) {
    return Digit.TWO
  }

  return Math.floor(containerWidth / listItemSpaceX)
}
