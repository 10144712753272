<template>
  <div id="app" :class="appClass">
    <SideMenu />
    <Search />
    <Modal />
    <BlurCover :hasMenu="hasMenu" />
    <GameSideMenu />
    <UserNotificationSideMenu />
    <MyAccountSideMenu />
    <LoginSideMenu />
    <ForgotPasswordSideMenu />
    <RegisterSideMenu />
    <ResendConfirmationSideMenu />
    <PayAndPlaySideMenu />
    <FooterNavbar />
    <SupportSideMenu />
    <PnpAccountSideMenu />

    <CookieAccept />
    <Navbar :ref="navbarRef" />
    <Flash />
    <MainContent />
    <Footer />
    <GlobalLoader />
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import {
  Module,
  MAIN_MENU_COMPONENT,
  DROP_FLASH,
  IS_LOGGED,
  SET_ARE_COOKIES_ACCEPTED,
  ACCEPT_COOKIES,
  APP,
  EventType,
  EMPTY_STRING,
  SET_SIDE_MENU_GAME,
  Digit,
  HAS_MENU,
  TOGGLE_MOBILE_SEARCH_INPUT,
  IS_MOBILE_SEARCH_INPUT_OPEN,
  IS_NAVBAR_AT_TOP,
  SET_NAVBAR_AT_TOP,
  SET_SCREEN_WIDTH,
} from '@/constants'
import { dest, checkStoreVersion, onWindowUnload, isNil } from '@/helpers'
import { sessionControlMixin } from '@/mixins'

/*
REFACTOR TODO LIST
5. replace #user-account with csn-user-account-section class
7. create models and getters (optional)
*/

const CSN_APP = 'csn-app'
const CSN_APP_WITH_SIDE_MENU = `${CSN_APP}-with-side-menu`
const NAVBAR_REF = 'navbar-ref'

export default {
  name: APP,
  components: {
    Flash: () => import('./components/Flash'),
    Footer: () => import('./components/Footer'),
    Modal: () => import('./components/Modal'),
    MainContent: () => import('./components/MainContent'),
    Navbar: () => import('./components/Navbar'),
    GlobalLoader: () => import('./components/GlobalLoader'),
    CookieAccept: () => import('./components/CookieAccept'),
    Search: () => import('./components/Search'),
    SideMenu: () => import('./components/SideMenu'),
    BlurCover: () => import('./components/BlurCover'),
    GameSideMenu: () => import('./components/GameSideMenu'),
    UserNotificationSideMenu: () =>
      import('./components/UserNotificationSideMenu'),
    MyAccountSideMenu: () => import('./components/MyAccountSideMenu'),
    LoginSideMenu: () => import('./components/LoginSideMenu'),
    RegisterSideMenu: () => import('./components/RegisterSideMenu'),
    ForgotPasswordSideMenu: () => import('./components/ForgotPasswordSideMenu'),
    ResendConfirmationSideMenu: () =>
      import('./components/ResendConfirmationSideMenu'),
    PayAndPlaySideMenu: () => import('./components/PayAndPlaySideMenu'),
    FooterNavbar: () => import('./components/FooterNavbar'),
    SupportSideMenu: () => import('./components/SupportSideMenu'),
    PnpAccountSideMenu: () => import('./components/PnpAccountSideMenu'),
  },
  mixins: [sessionControlMixin],
  computed: {
    ...mapState(Module.MAIN, {
      MAIN_MENU_COMPONENT,
      IS_MOBILE_SEARCH_INPUT_OPEN,
      IS_NAVBAR_AT_TOP,
    }),
    hasMenu() {
      return this.$store.getters[dest([Module.MAIN, HAS_MENU])]
    },
    isLogged() {
      return this.$store.getters[dest([Module.USER, IS_LOGGED])]
    },
    appClass() {
      return [CSN_APP, this.hasMenu ? CSN_APP_WITH_SIDE_MENU : EMPTY_STRING]
    },
    navbarRef: () => NAVBAR_REF,
  },
  methods: {
    ...mapMutations(Module.FLASH, [DROP_FLASH]),
    ...mapMutations(Module.MAIN, [SET_ARE_COOKIES_ACCEPTED]),
    ...mapMutations(Module.MAIN, [SET_SIDE_MENU_GAME]),
    ...mapMutations(Module.MAIN, [TOGGLE_MOBILE_SEARCH_INPUT]),
    ...mapMutations(Module.MAIN, [SET_NAVBAR_AT_TOP]),
    ...mapMutations(Module.DEVICE, [SET_SCREEN_WIDTH]),
    getScreenWidth() {
      this.SET_SCREEN_WIDTH(window.innerWidth)
    },
    checkNavbarDistance() {
      const navbarTop = this.$refs[this.navbarRef].$el.getBoundingClientRect()
        .top

      navbarTop === Digit.NOUGHT
        ? !this.IS_NAVBAR_AT_TOP && this.SET_NAVBAR_AT_TOP(true)
        : this.IS_NAVBAR_AT_TOP && this.SET_NAVBAR_AT_TOP(false)
    },
  },
  created() {
    const areCookiesAccepted = localStorage.getItem(ACCEPT_COOKIES)
    this.SET_ARE_COOKIES_ACCEPTED(Boolean(areCookiesAccepted))
    !isNil(this.IS_MOBILE_SEARCH_INPUT_OPEN) &&
      this.TOGGLE_MOBILE_SEARCH_INPUT(null)
    window.addEventListener(EventType.BEFOREUNLOAD, onWindowUnload)
    window.addEventListener(EventType.RESIZE, this.getScreenWidth)
    window.addEventListener(EventType.SCROLL, this.checkNavbarDistance)
  },
  mounted() {
    this.DROP_FLASH()
    this.SET_SIDE_MENU_GAME(null)
    checkStoreVersion()
    this.getScreenWidth()
  },
  beforeDestroy() {
    window.removeEventListener(EventType.BEFOREUNLOAD, onWindowUnload)
    window.removeEventListener(EventType.RESIZE, this.getScreenWidth)
    window.addEventListener(EventType.SCROLL, this.checkNavbarDistance)
  },
}
</script>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
