import * as ComponentName from '@/constants/componentName'
import * as ModalName from '@/constants/modalName'
import {
  LimitType,
  FORM_ERROR,
  // TITLE,
  Filter,
  FilterByFilter,
  OrderByFilter,
  RouteName,
  GamePlayCategoryEnum,
} from '@/constants'

export const de = {
  [FORM_ERROR]: {
    field_is_required: 'Dieses Feld wird benötigt.',
    checkbox_must_be_checked: 'Dieses Kontrollkästchen muss aktiviert sein',
    enter_email: 'Bitte geben Sie eine gültige e-mail-Adresse ein.',
    password_confirmation_does_not_match_password:
      'Die Passwortbestätigung stimmt nicht mit dem Passwort überein.',
    password_wrong:
      'Das Passwort muss zwischen 6 und 30 Zeichen lang sein und mindestens eine Zahl und Buchstaben in Groß-/Kleinschreibung enthalten.',
    choose_file: 'Bitte wählen Sie eine Datei aus.',
    must_not_exceed_min:
      'Der Wert muss größer oder gleich dem zulässigen Minimum sein.',
    must_not_exceed_max:
      'Der Wert muss kleiner oder gleich dem zulässigen Maximum sein.',
    must_be_number: 'Der Wert muss Zahl sein.',
  },
  formMixin: {
    something_went_wrong: 'Etwas ist schief gelaufen',
  },
  [ComponentName.ABOUT_US]: {
    casino_five: 'CasinoFive',
    a_dream_come_true: 'Aus Traum wird Wirklichkeit',
    welcome_to_the_exciting_world:
      'Willkommen in der aufregenden Welt des CasinoFive. Geboren aus Leidenschaft und der Liebe zum Spiel, geleitet von 5 Musketieren mit jahrelanger iGaming Erfahrung und entsprechendem Enthusiasmus.',
    our_dream_was_born:
      'Unser Traum wurde im Jahr 2021 in unserer Casino-Ideenschmiede geboren. Im Sommer 2022 war es dann endlich soweit und wir öffneten unsere virtuellen CasinoFive-Tore, mit großen Sternchen in den Augen und vielleicht sogar der ein oder anderen Träne.',
    casinofive_stands_for: `CasinoFive steht für Zuverlässigkeit, Seriosität und Innovation und setzt auf erstklassige Spielautomaten und ein ultimatives Spielerlebnis. In unserer digitalen Spielhalle empfangen Dich Hunderte Lieblings-Titel von NetEnt, Play’n GO, Yggdrasil, Microgaming, Red Tiger, Big Time Gaming und etlichen weiteren Star-Anbietern. Diese versorgen Dich als Spieler kontinuierlich mit spannenden Angeboten, Aktionen, Turnieren und einem stetig wachsenden Repertoire an Automatenspielen. Die Zufriedenheit der Spieler ist eines unserer Hauptziele. Genau deshalb sind wir ununterbrochen auf der Suche nach den neuesten und aufregendsten Online-Slots. Ob Du ein Megaways-Slot-Enthusiast bist, ein Retro-Slot-Liebhaber oder eher scharf auf die beliebtesten modernen und/oder neuesten Titel bist, von Book of Dead über Gonzo’s Quest, Reactoonz, Monopoly bis natürlich jedermanns Allzeit-Favoriten Starburst, ist alles mit dabei! Dein virtuelles Spielerlebnis übertrifft einen echten Casino-Besuch bei weitem, denn nur hier hast Du die Wahl aus Tausenden Spielautomaten und das ganz bequem und sicher von zuhause.`,
    are_you_on_the_road: `Bist Du viel unterwegs und Dein Spielvergnügen soll trotzdem nicht zu kurz kommen? Kein Problem! Wir haben CasinoFive mit Blick auf mobile Geräte entwickelt, sodass Du auf Deinem Handy, Tablet oder Desktop-Browser spielen und ein nahtloses, sicheres Spielerlebnis genießen kannst. Zu jeder Zeit und von überall!`,
    the_security_of_our_players: `Die Sicherheit unserer Spieler steht bei uns an allererster Stelle und wird mit Hilfe spezifischer Kontrollmechanismen zu jeder Zeit gewährleistet und für jedermann offen und transparent ausgewiesen. Durch strikte Verträge mit unseren etlichen Zahlungsanbietern und komplexen Verschlüsselungsprozessen, sind die Daten unserer Spieler allzeit geschützt und von jeder Art von Missbrauch abgeschirmt. Und apropos Zahlungsanbieter, natürlich hast Du bei CasinoFive die Qual der Wahl, wenn es um Zahlungsmethoden geht. Wir arbeiten ausschließlich mit den vertrauensvollsten, verlässlichsten und sichersten Zahlungsdienstleistern zusammen. Von klassischer Banküberweisung, über VISA und Mastercard bis hin zu E-Wallets wie Skrill, Neteller und Co. findest Du bei uns alles.`,
    since_service_and_quality: `Da Service und Qualität, neben der Sicherheit unserer Spieler unser essenzielles Fundament bilden, legen wir viel Wert auf die individuelle Betreuung unserer Kunden. Unser stets hilfsbereiter Kundenservice ist in verschiedenen Sprachen, 15 Stunden pro Tag im Live-Chat erreichbar. Darüber hinaus besteht die Möglichkeit einer E-Mail-Korrespondenz sowie eines Kontakts über Facebook. Wenn Du also nach zielgerichteter und schneller Problemlösung, Verständnis, Kompetenz, Zuverlässigkeit und zügigen Bearbeitungszeiten suchst, dann bist Du bei unserem CasinoFive Kundenservice-Team genau an der richtigen Adresse.`,
    another_aspect: `Ein weiterer Punkt der uns als verantwortungsbewusstem Casino-Anbieter wichtig ist, ist das Thema verantwortungsvolles Glücksspiel. Insbesondere liegt uns jedoch der Jugendschutz im Allgemeinen am Herzen. Das Mindestalter für Glücksspiele jeglicher Art beträgt 18 Jahre. Aus diesem Grund behält sich CasinoFive vor, Angaben im gesetzlichen Rahmen zu prüfen, um sicherzustellen, dass Minderjährige vom Spielen ausgeschlossen sind. `,
    if_your_thirst: `Sollte Dein Wissenshunger noch immer nicht gestillt sein und Du würdest gern noch mehr über CasinoFive erfahren, kannst Du Dich per E-Mail oder Live-Chat direkt an unseren Kundenservice wenden. Wir freuen uns auf Deine Nachricht. `,
  },
  [ComponentName.ACCOUNT_VERIFICATION]: {
    title: 'Konto überprüfen',
    description_1:
      'Sie haben gewonnen und möchten Ihren Gewinn auszahlen? Das ist überhaupt kein Problem!',
    description_2:
      'Um Sie innerhalb der CasinoFive-Website identifizieren zu können, benötigen wir aufgrund behördlicher Sicherheitsbestimmungen eine lesbare Kopie Ihres amtlichen Lichtbildausweises sowie eine Kopie eines zweiten Dokuments mit Angabe Ihrer Adresse.',
    description_3:
      'Diese Maßnahmen dienen ausschließlich Ihrer Sicherheit, damit keine unbefugten Dritten Ihre Zugangsdaten verwenden und keine unbefugten Transaktionen stattfinden können. Wir bitten um Ihr Verständnis für diese Sicherheitsmaßnahmen.',
    description_4:
      'Wie funktioniert es? Wir brauchen nur drei Dateien. Klingt kompliziert, ist aber so einfach wie es nur geht.',
    subtitle: 'Dokumente hochladen',
    list_1_title: 'Lichtbildausweis',
    list_1_item_1: 'Reisepass',
    list_1_item_2: 'Personalausweis',

    list_2_title: 'Nachweis der Adresse',
    list_2_subtitle: 'Einer der folgenden',
    list_2_item_1:
      'Korrespondenz von Behörden (mit Angabe Ihres Vornamens, Nachnamens und Ihrer Adresse, ein Standortdokument, nicht älter als 3 Monate)',
    list_2_item_2:
      'Gut lesbarer Kontoauszug mit Angabe Ihrer Adresse (Deckungsbeträge, nicht älter als drei Monate)',
    list_2_item_3:
      'Nebenkostenabrechnung (Telefon/Strom/Gas, nicht älter als acht Wochen, nicht älter als drei Monate)',
    list_3_title: 'Verification of payment method',
    list_3_item_1:
      'Gut lesbarer Bank- oder Kreditkartenauszug mit Angabe Ihrer Adresse (gedeckte Beträge)',
    list_3_item_2:
      'Kopie Ihrer Kredit- oder Bankkarte (bitte decken Sie Ihren CVV und die ersten 12 Ziffern Ihrer Kreditkartennummer ab)',
    list_3_item_3:
      'Screenshot Ihres Skrill- oder Neteller-Kontos (mit Ihrem Namen und Ihrer Konto-ID)',
    upload_doctuments_bttn_label: 'Dokumente hochladen',
  },
  [ComponentName.BREADCRUMBS]: {
    category: 'Kategorie',
    play_now: 'Jetzt spielen',
    new_games: 'Neue Spiele',
    top_games: 'Spitzenspiele',
    search: 'Suche',
    home: 'Home',
    lobby: 'Empfangshalle',
    search_result: 'Spiel: Filter',
  },
  [ComponentName.CHANGE_PASSWORD]: {
    my_profile: 'Ändere das Passwort',
    description:
      'Zu Ihrer eigenen Sicherheit empfehlen wir Ihnen, Ihr Passwort regelmäßig zu ändern. Füllen Sie einfach die folgenden Felder aus. Aus Sicherheitsgründen senden wir Ihnen eine e-mail, um die Änderung Ihres Passworts zu bestätigen.',
    platin_account_password: 'Platin-Konto-Passwort',
    current_password: 'Jetziges Passwort',
    new_password: 'Neues Kennwort',
    repeat_new_password: 'Wiederhole das neue Passwort',
    update_password: 'Passwort erneuern',
  },
  [ComponentName.CLUB_CARD]: {
    id: 'Identifikation:',
    since: 'seitdem:',
  },
  [ComponentName.COOKIE_ACCEPT]: {
    text_1: 'Wir verwenden Cookies auf dieser Website.',
    text_2: 'Wenn Sie der Verwendung von Cookies zustimmen',
    text_3: 'und mit allen Funktionen der Website fortfahren möchten,',
    text_4: 'stimmen Sie unseren zu',
    privacy: 'Privatsphäre',
    cookie_policy: 'Cookie-Richtlinie',
    button_label: 'Ich stimme zu',
  },
  [ComponentName.DASHBOARD]: {
    balance: 'Balance',
    deposit: 'Anzahlung',
    platin_points: 'Platin-Punkte',
    redeem: 'einlösen',
    notifications: 'Benachrichtigungen',
    view: 'Sicht',
    platin_club: 'Platin-Club',
    loyalty_program: 'Treueprogramm',
    more: 'Mehr',
    rewards: 'Belohnung',
    carnival: 'Karneval',
    free_spins: 'Freispiele',
    special_offer: 'Sonderangebot',
    last_used_bonus_codes: 'Zuletzt verwendete Bonuscodes:',
    recently_played: 'Kürzlich gespielt',
    favourites: 'Favoritinnen',
  },
  [ComponentName.DEPOSIT]: {
    deposit: 'Anzahlung',
    select_deposit_method: 'Einzahlungsmethode auswählen',
    min: 'Min',
    max: 'Max',
    deposit_amount: 'Einzahlungsbetrag',
    deposit_details: 'Einzahlungsdetails',
    acknowledgement: 'Wissen',
    deposit_method: 'Einzahlungsmethode',
    total_available_balance: 'Verfügbares Gesamtguthaben',
    play_game: 'Jetzt Spielen',
    amount_to_deposit_euro: 'Einzahlungsbetrag (€)',
    bonus_code: 'Bonus-Code',
  },
  [ComponentName.DEPOSIT_HISTORY]: {
    title: 'Geschichte der Einzahlung',
    description: 'Detaillierte Liste der Einzahlungshistorie',
    filter_deposit: 'Filterablage',
    showing_results: 'Ergebnisse anzeigen',
    date_from: 'Datum von',
    date_to: 'Datum bis',
    submit: 'Zeige Ergebnisse',
    method_name: 'Methodenname',
    start_date: 'Startdatum',
    end_date: 'Enddatum',
    currency: 'Währung',
    amount: 'Menge',
    status: 'Status',
    no_deposits: 'Es sind keine Einzahlungen vorzuweisen.',
    bttn_label: 'Zeige Ergebnisse',
  },
  [ComponentName.FILE_FIELD]: {
    choose_file: 'Datei wählen',
  },
  [ComponentName.FOOTER]: {
    text_1: 'CASINO-ONLINE-SPIELE AUF CASINOFIVE.COM',
    description_1: `CASINOFIVE wurde 2012 gegründet und hat seine Spiele 2013 online gestellt. Rückblickend auf seine langjährige Erfahrung in der Spielebranche hat sich unser Team zum erklärten Ziel gesetzt, dem Spieler eine spannende und actionreiche Spielumgebung zu bieten. Wir arbeiten nur mit erstklassiger Gaming-Software und liefern alle unsere Casino-Online-Spiele in HD. In Zusammenarbeit mit namhaften Herstellern freuen wir uns, Ihnen exklusiv die renommierten Casinospiele von Merkur präsentieren zu können. Im CasinoFive genießen Sie die prickelnde Atmosphäre der Casino-Action rund um die Uhr, egal wo Sie sich befinden.`,
    text_2: 'SPIELAUTOMATEN UND VIELE ANDERE CASINO-SPIELE ONLINE',
    description_2: `Neben den traditionellen Standbys von Casinospielen sind wir bei CasinoFive in erster Linie auf Spielautomaten spezialisiert. Auf unserer Seite finden Sie die bekanntesten Spiele von herausragenden Herstellern, zum Beispiel Merkur-Spiele. Bekannt wurde das Unternehmen bereits durch seine Präsenz in stationären Spielhallen. Umgekehrt werden im Internet Casino-Online-Spiele unter diesem Namen nur von wenigen Anbietern angeboten. Der Grund ist, dass die Marke Merkur nur die renommiertesten Casinos online als Partner betrachtet. Daraus folgt, dass eine Partnerschaft mit CasinoFive ein logischer nächster Schritt war. Auf unserer Seite können Sie jetzt exklusiv die Casino-Online-Spiele von Merkur wie Double Triple Chance und viele mehr genießen.`,
    text_3: 'CASINO ONLINE SPIELE KOSTENLOS UND OHNE EINLOGGEN',
    description_3: `Wir sind bestrebt, Ihnen immer eine spannende und actionreiche Spielatmosphäre zu bieten, bei der der Spaß im Vordergrund steht. Aus diesen Gründen können Sie auf unserer Seite alle Casinospiele kostenlos und ohne Anmeldung online spielen. Testen Sie alle Spiele in aller Ruhe und ohne Risiko. Außerdem profitieren Sie bei CasinoFive vom erstklassigen Kundenservice, der Ihnen jederzeit zur Verfügung steht. Kompromisslose Transparenz, prompte Überweisungen und tolle Boni sind weitere Anreize, sich für Casino Online Spiele von CasinoFive zu entscheiden.`,
    text_4:
      'CASINOFIVE BEEINDRUCKT SICHER MIT SEINEM TOP-SERVICE UND EINEM BREITEN SPIELANGEBOT.',
    description_4_paragraph_1: `Eine weitere Besonderheit, die CasinoFive Neukunden bietet, ist ein großzügiges Bonusprogramm. Der Bonus ist ideal für Online-Casino-Neulinge, da er sehr transparent und leicht verständlich ist. CasinoFive verdoppelt Ihre Guthabenaufladungen zu 100 % für Zahlungen bis zu 100 Euro. Dieser 100% Bonus ist jedoch nicht nur für Neulinge; Es ist für alle neuen Spieler gedacht, die die breite Palette an Spielen von CasinoFive ausprobieren möchten. Entscheiden Sie sich bei der Anmeldung bei CasinoFive für Ihren Bonus und verwandeln Sie Ihr Wohnzimmer in Ihr ganz persönliches Casino!`,
    description_4_paragraph_2: `Sehen? Neben dem großzügigen Angebot an Online-Casino-Spielen gibt es noch viele weitere attraktive Gründe, ein besonderes Spielerlebnis im CasinoFive zu genießen.`,
    footer_left_text: `CasinoFive.com wird von Red Rhino Limited betrieben, einem in Malta mit der Registrierungsnummer C67666 und der Adresse 6 Investor House (Suite 3), Triq il-Fikus, San Gwann SGN 2461, Malta registrierten Unternehmen. Red Rhino Ltd. ist von der Malta Gaming Authority (MGA) lizenziert und reguliert und besitzt die Lizenznummer MGA/B2C/398/2017, ausgestellt am 01.08.2018.`,
    footer_left_ending: '© 2022 Red Rhino Ltd. Alle Rechte vorbehalten.',
    footer_right_text: `Die Verantwortung für Ihre sichere Spielumgebung bei CasinoFive.com ist unsere Verpflichtung und hat höchste Priorität. Wir möchten Sie unterhalten und bieten Spaß und Spannung. Aber Casinospiele bergen immer Risiken. Seien Sie sich bewusst, dass es großartig ist, zu gewinnen, aber Sie können auch verlieren. Spielen Sie nur mit Geld, das Sie noch ausgeben können. Spielen kann süchtig machen, bitte spielen Sie verantwortungsbewusst.`,
    footer_right_ending: `Bei Sorgen oder Fragen zum Spielverhalten steht Ihnen unser Kundensupport-Team zur Seite.`,
    follow_jack_wild: 'Folgen Jack Wild',
  },
  [ComponentName.FOOTER_NAVBAR]: {
    deposit: 'Einzahlen',
    account_balance: 'Kontostand:',
    real_money: 'Echtes Geld:',
    bonus_money: 'Bonusgeld:',
  },
  [ComponentName.GAME_BOX_WITH_HOVER]: {
    play_now: 'Jetzt spielen',
    try_me: 'Ausprobieren',
    hot: 'Heiß',
    new: 'Neu',
  },
  [ComponentName.GAME_INFO]: {
    start_playing: 'Jetzt Spielen',
    free_play: 'Gratis spielen',
    play_for_real: 'Spielen Sie um Real',
  },
  [ComponentName.GAME_LISTS]: {
    empty_list: 'Die Spielliste ist leer.',
  },
  [ComponentName.GAME_PLAY]: {
    no_iframe:
      'Der Iframe des Spiels konnte nicht geladen werden. Bitte versuchen Sie ein anderes Spiel.',
  },
  [ComponentName.GAME_PLAY_NAVIGATION]: {
    [GamePlayCategoryEnum.RECENT]: 'Kürzlich gespielt',
    [GamePlayCategoryEnum.TOP]: 'Top-Spiele',
    [GamePlayCategoryEnum.FAVOURITE]: 'Favoritinnen',
    [GamePlayCategoryEnum.RECOMMENDED]: 'Empfohlen',
  },
  [ComponentName.GAME_SIDE_MENU]: {
    play_now: 'Jetzt spielen',
    try_me: 'Ausprobieren',
    balance: 'Gleichgewicht',
    deposit: 'Anzahlung',
  },
  [ComponentName.HOME]: {
    home: 'Home',
    providers: 'Anbieter:',
    top_games: 'Spitzenspiele',
    recently_played: 'Kürzlich gespielt',
    favourites: 'Favoritinnen',
    new_games: 'Neue Spiele',
    live_casino: 'Live-Casino',
    slots: 'Schlüssel',
  },
  [ComponentName.HORIZONTAL_LIST]: {
    show_all: 'Zeige alle',
    empty_list: 'Diese Liste ist leer.',
  },
  [ComponentName.MAIN_MENU]: {
    lobby: 'Empfangshalle',
    games: 'Spiele',
    games_providers: 'Spieleanbieter',
    offers: 'Angebote',
    rewards_carnival: 'Belohnungen Karneval',
    platin_club: 'Platin-Club',
    casino_five_club: 'CasinoFive Club',
    dashboard: 'Armaturenbrett',
    deposit: 'Anzahlung',
    platin_coins: 'Platinmünzen',
    already_registered: 'Bereits registriert?',
    register_description:
      'Herzlich willkommen im CasinoFive. Beginnen Sie Ihre Reise zum Glück mit unserem Willkommensbonus!',
    register: 'Registrieren',
    click_here_to_login: 'Klicken Sie hier, um sich einzuloggen',
    login: 'Anmeldung',
    support: 'Die Unterstützung',
    information: 'Information',
    logout: 'Ausloggen',
    language: 'Sprache',
    deposit_and_play: 'Einzahlen und spielen',
  },
  [ComponentName.MONEY_COUNTER]: {
    min: 'Min',
    max: 'Max',
  },
  [ComponentName.MY_ACCOUNT_SIDE_MENU]: {
    my_account: 'Mein Konto',
  },
  [ComponentName.NAVBAR]: {
    [RouteName.OFFERS]: 'Werbeaktionen',
    [RouteName.OFFERS_VIEW]: 'Aktionsinfo',
    [RouteName.REWARD_CARNIVAL]: 'Belohnungen Karneval',
    [RouteName.USER_ACCOUNT]: 'Mein Profil',
    [RouteName.USER_ACCOUNT_BALANCE]: 'Kontostand',
    [RouteName.USER_CHANGE_PASSWORD]: 'Passwort',
    [RouteName.USER_GAME_ROUNDS]: 'Spielrunde',
    [RouteName.USER_TRANSACTIONS]: 'Transaktionen',
    [RouteName.USER_NEWSLETTER]: 'Newsletter',
    [RouteName.USER_LIMITS]: 'Grenzen',
    [RouteName.USER_ACCOUNT_VERIFICATION]: 'Konto überprüfen',
    [RouteName.USER_DOCUMENTS]: 'Meine Dokumente',
    [RouteName.USER_LIMIT_EDIT]: 'Limit einstellen',
    [RouteName.USER_SELF_EXCLUSION]: 'Selbstausschluss',
    [RouteName.DEPOSIT]: 'Anzahlung',
    [RouteName.WITHDRAWAL]: 'Rückzug',
    [RouteName.WITHDRAWAL_ADD]: 'Rückzug', // 'Auszahlung Add',
  },
  [ComponentName.NAVBAR__ACCOUNT_BALANCE]: {
    add_deposit: 'Einzahlung hinzufügen',
  },
  [ComponentName.NAVBAR__PAY_AND_PLAY_BUTTON]: {
    pay_and_play: 'Zahlen & spielen',
  },
  [ComponentName.NAVBAR__GAME_PANEL]: {
    add_deposit: 'Einzahlung hinzufügen',
    createAccount: 'Ein Konto erstellen',
    login: 'Anmeldung',
  },
  [ComponentName.NAVBER__LOGIN_BUTTON]: {
    login_btn_label: 'Anmeldung',
  },
  [ComponentName.NAVBAR__MAIN_MENU_BUTTON]: {
    menu: 'Menü',
  },
  [ComponentName.NAVBAR__REGISTER_BUTTON]: {
    createAccount: 'Ein Konto erstellen',
    register: 'Registrieren',
  },
  [ComponentName.NAVBAR__SEARCH_INPUT]: {
    play_now: 'Jetzt spielen',
    try_me: 'Ausprobieren',
    browse_games: 'Spiele durchsuchen',
    show_results_text_1: 'Alle',
    show_results_text_2: 'Ergebnisse anzeigen',
    no_results: 'Keine Ergebnisse',
  },
  [ComponentName.PAGE_404]: {
    header: 'Hoppla, es sieht so aus, als wären wir verloren!',
    help_btn_label: 'Brauchen Sie Hilfe?',
    home_btn_label: 'Wieder nach Hause gehen',
  },
  [ComponentName.PAGINATOR]: {
    rows_per_page: 'Zeilen pro Seite:',
    of: 'von',
  },
  [ComponentName.PLATINCLUB]: {
    platinclub_benefits: 'Platinclub-Vorteile',
    our_exclusive_loyalty:
      'unser exklusives Treueprogramm für die CasinoFive-Spieler',
    join_the_platinclub: `Treten Sie dem PlatinClub bei, unserem exklusiven Treueprogramm für das CasinoFive
    Spieler. Ab Ihrer ersten Einzahlung. Was ist für dich drin? Mehr und
    mehr mit jedem erreichten Status – von erweitertem Support und Special
    Aktionen, bis hin zu den tollsten Angeboten in unserem REWARDS CARNIVAL Shop.
    Überprüfen Sie Ihren aktuellen Status und schalten Sie gleich hier weitere Vorteile frei
    das Club-Portal. Willkommen im PlatinClub!`,
    vip_experiences: `VIP-Erlebnisse &\u00A0Sonderangebote`,
    our_platinclub_team: `Unser PlatinClub-Team erstellt ständig neue Aktionen und Specials
    Vorteile für Sie. Von automatisierten Credits zum Erreichen des nächsten
    Club-Status bis hin zu ganz persönlichen und exklusiven Geschenken. Stell sicher, dass du
    Verpassen Sie keine Belohnung: Melden Sie sich für unsere Marketingangebote per Newsletter an
    heute.`,
    platinclub_support: 'Platinclub-Unterstützung',
    our_support_team: `Unser Support-Team ist immer an Ihrer Seite, egal in welchem Status.
    Beim Erklimmen der Statusleiter bieten wir Ihnen zusätzlichen Kontakt
    Optionen, verlängerte Supportzeiten und sogar Ihr persönlicher PlatinClub
    Concierge für jede Anfrage.`,
    collect_and_redeem: 'Platinmünzen sammeln und einlösen',
    you_play_we_pay: `Sie spielen, wir zahlen! Jeder Echtgeldeinsatz, den Sie im CasinoFive tätigen
    Slots werden in PlatinCoins umgewandelt. Sie können die Münzen verwenden, um
    shoppe in unserem REWARDS CARNEVAL: Boni, Freegames und vieles mehr
    die Geschenke. Je mehr Sie die Status durchlaufen, desto mehr benötigen Sie
    weniger Einsätze, um weitere Münzen zu sammeln`,
    platinclub_status: 'Platinclub-Status',
    welcome_to_the_platin_club: 'Willkommen im Platin-Club!',
    from_your_very_first_deposit: `Schon bei Ihrer ersten Einzahlung sagen wir: Willkommen im PlatinClub! Du wirst
    starte deine Statusreise von BRONZE mit einem sehr netten Willkommen
    Geschenk, um Ihnen den Weg nach oben zu erleichtern. Unser Versprechen: Je mehr Sie spielen - desto mehr wir
    Zahlen! Erreiche die Statusleiter bis zu unserem Mystiker
    BLACK-Status, eine luxuriöse Mitglieder-Clique nur auf Einladung, mit legendären
    prestigeträchtige Vorteile `,
    reached: 'Erreicht',
    your_status: 'Dein Status',
    reach_status_now: 'Jetzt Status erreichen',
    the_platin_status_will_be_granted: `*Der PLATIN-Status wird am Tag nach Eingang der Einzahlung gewährt
    Menge von mind. 1.000 € im letzten Monat und wird bis zum Ende gewährt
    des nächsten Monats. PLATIN-Status kann im Monat des nicht erreicht werden
    erste Einzahlung überhaupt. Um den Status beizubehalten, muss der Betrag in . bestätigt werden
    jeden Monat.`,
    your_benefits_at_a_glance: 'Ihre Vorteile auf einen Blick',
    whats_in_it:
      'Was ist für dich drin? Mit jedem erreichten Status mehr und mehr',
    exclusive_and_personal_offers: 'exklusive & persönliche Angebote',
    enable_to_stay_updated:
      'Aktivieren, um über das CasinoFive Club-Angebot auf dem Laufenden zu bleiben',
    name: 'Platin-Club',
    yourStatusIcon: 'Ihr Statussymbol',
    platinClubWelcomeGift: 'CasinoFive Club Willkommensgeschenk',
    betAmountForOnePlatinCoin: 'Wettbetrag für 1 Platinmünze',
    AutomatedGifts: 'Automatisierte Geschenke',
    AnniversaryPromotions: 'Jubiläumsaktionen',
    noDepositBonusWager: 'Bonuswette ohne Einzahlung',
    PreferredWithdrwal: 'Bevorzugte Auszahlung***',
    expiryOfPlatinCoinsAfter: 'Verfall von Platinmünzen nach',
    conciergeService: 'Portier Service',
    black: 'Schwarz',
    vip_platin_club: 'VIP CasinoFive Club',
    invite_only: 'Nur geladene Gäste',
    for_a_minimum_deposit: `* Bei einer Mindesteinzahlung von 10 € pro Woche. Die Freispiele werden auf Book of Dead gewährt (Bonusgeldeinsatz 75, max. auszahlbarer Betrag 100 €)
    `,
    cashback_is_granted: `** Cashback wird auf den Betrag möglicher Echtgeldverluste (ohne Verbindung zu einem Bonus) innerhalb einer Woche (Mo - So) gewährt. Der Cashback-Betrag muss nur einmal umgesetzt werden. Die max. der auszahlbare Betrag entspricht dem Cashback-Betrag.
    `,
    preferred_wihdrawal_only: `*** Bevorzugte Auszahlung nur mit einem verifizierten Konto möglich`,
    make_sure:
      'Melden Sie sich bei PlatinNews an und verpassen Sie keine PlatinClub-Angebote oder Sonderaktionen per e-mail!',
    check_my_setting: 'Überprüfe meine Einstellung',
    conditions_platinclub: 'Conditons PlatinClub – General',
    loyalty_program:
      'PlatinClub ist das CasinoFive-Treueprogramm, das Ihnen verschiedene Spielervorteile bietet. Je mehr Sie bei CasinoFive spielen und einzahlen, desto höher ist Ihr VIP-Status und desto umfassender Ihre persönlichen Vorteile und Angebote.',
    general_condition_1: `CasinoFive zeigt die Voraussetzungen und Bedingungen für jeden VIP-Status auf der PlatinClub-Website an. Den Status Bronze, Silver und Gold (lebenslang) sowie Platin kannst du automatisch und unabhängig durch dein Spielerverhalten und deine Aktivität erreichen. Sie können Ihren aktuellen Status jederzeit in Ihrem Spielerkonto überprüfen. Die Vergabe eines neuen VIP-Status erfolgt automatisch innerhalb des technischen Systems am Tag nach Erreichen bzw. Überschreiten der Hürde für den nächsten Status.`,
    general_condition_2: `Das Willkommensgeschenk zum Erreichen eines neuen VIP-Status wird bei jedem erstmaligen Erreichen eines Status gewährt und ist einmal pro Spieler oder PlatinClub-Mitglied gültig.`,
    general_condition_3: `Die Berechnung Ihres VIP-Status und der Vorteile basiert auf den Einzahlungsbeträgen, dem Datumsbereich und den Echtgeldeinsätzen sowie Ihrem Konto- und Verifizierungsstatus (KYC).`,
    general_condition_4: `Sie können sich für den Status Platin qualifizieren, indem Sie den monatlichen Mindesteinzahlungsbetrag erreichen. Die Qualifikation ist bis Ende des nächsten Monats gültig. Falls Sie Ihren Platin-Status verlieren, werden Sie am 1. Tag des neuen Monats auf den Status Gold herabgestuft. Sie können täglich wieder auf Platin aktualisiert werden.`,
    general_condition_5: `Um in den Black-Status zu gelangen, benötigen Sie eine persönliche Einladung eines CasinoFive-Teammitglieds. Die Auswahl der Black-Mitglieder erfolgt ausschließlich durch CasinoFive. Nur wenn Sie eine Einladung erhalten, wird der Black-Status gewährt. Der Status wird in Ihrem Konto angezeigt.`,
    general_condition_6: `Es besteht kein Anspruch auf Mitgliedschaft im PlatinClub und/oder Black Status. Alle Angebote sind freibleibend und nicht übertragbar. Der Rechtsweg ist ausgeschlossen.`,
    general_condition_7: `Der PlatinClub inkl. der VIP-Status besteht seit seiner Gründung bis auf weiteres. CasinoFive behält sich das Recht vor, das PlatinClub-Programm mit allen dazugehörigen Angeboten und Inhalten jederzeit zu unterbrechen oder zu beenden, wobei die Spieler mindestens 7 Tage im Voraus informiert werden.`,
    general_condition_8: `CasinoFive behält sich das Recht vor, den Inhalt des PlatinClubs jederzeit zu erweitern oder anderweitig zu ändern, z.B. die Voraussetzungen zum Erreichen eines bestimmten VIP-Status und die damit verbundenen Vorteile. Informationen hierzu finden Sie auf der PlatinClub-Website.`,
    general_condition_9: `Wenn Sie Ihr CasinoFive-Konto schließen oder Ihr Konto vom CasinoFive-Team geschlossen wird, verlieren Sie Ihren VIP-Status und alle damit verbundenen Vorteile. Ihr Status ist nicht übertragbar, auch wenn Sie ein neues Konto bei CasinoFive eröffnen.
    CasinoFive behält sich das Recht vor, Ihre PlatinClub-Mitgliedschaft jederzeit zu kündigen und Ihren aktuellen VIP-Status zu entziehen.`,
    general_condition_10: `Unless otherwise stated, the PlatinClub and its content and benefits are governed by the terms and conditions or bonus conditions of CasinoFive or Red Rhino Ltd.
    CasinoFive will terminate your PlatinClub membership if we determine that you violate the PlatinClub conditions, other bonus terms and conditions, or the CasinoFive terms and conditions and/or otherwise act against the interests of CasinoFive. Such violation will result in the withdrawal of your VIP status and all associated benefits. CasinoFive also reserves the right to retroactively withdraw any bonuses already awarded and close your player account if necessary.Sofern nicht anders angegeben, unterliegen der PlatinClub und seine Inhalte und Vorteile den Allgemeinen Geschäftsbedingungen oder Bonusbedingungen von CasinoFive oder Red Rhino Ltd.
    CasinoFive beendet Ihre PlatinClub-Mitgliedschaft, wenn wir feststellen, dass Sie gegen die PlatinClub-Bedingungen, andere Bonusbedingungen oder die CasinoFive-Bedingungen verstoßen und/oder anderweitig gegen die Interessen von CasinoFive handeln. Ein solcher Verstoß führt zum Entzug Ihres VIP-Status und aller damit verbundenen Vorteile. CasinoFive behält sich außerdem das Recht vor, bereits gewährte Boni rückwirkend abzuheben und gegebenenfalls Ihr Spielerkonto zu schließen.`,
    general_condition_11: `Darüber hinaus kann CasinoFive Ihre PlatinClub-Mitgliedschaft jederzeit, d. h. für einen bestimmten Zeitraum oder bis auf Weiteres, aussetzen, wenn dies zur Wahrung unserer Interessen erforderlich ist. Während dieser Zeit wird Ihr VIP-Status widerrufen und alle Vorteile ausgesetzt.`,
    platincoins_conditions: 'PlatinCoins – Bedingungen, Sammeln und Umtauschen',
    every_real_money: `Jede Echtgeldwette, die Sie als Spieler bei CasinoFive an Spielautomaten tätigen, wird von unserem System erfasst und in PlatinCoins, unsere Treuewährung, umgewandelt. Die PlatinCoins sind Teil des PlatinClub-Angebots – je höher Ihr PlatinClub-Status, desto mehr PlatinCoins sammeln Sie für Ihre Wetten. Wie hoch Ihr Einsatz sein muss, um einen PlatinCoin in Ihrem persönlichen VIP-Status zu sammeln, erfahren Sie in der Vorteilstabelle auf unserer PlatinClub-Website. In unserem Rewards Carnival Shop kannst du deine gesammelten Münzen gegen Boni, Freegames und viele andere Belohnungen eintauschen.`,
    platincoins_condition_1:
      'PlatinCoins werden ab Ihrem ersten Echtgeldeinsatz bei uns berechnet – auch wenn Sie noch nicht den PlatinClub Status erreicht haben.',
    platincoins_condition_2:
      'Nur Ihre Echtgeldeinsätze in Spielautomaten werden zur Berechnung der Münzen verwendet. Wetten auf Tischspiele, Live-Casinospiele, andere Spiele oder Sportwetten werden nicht gezählt.',
    platincoins_condition_3:
      'Sie finden eine Übersicht Ihres PlatinCoins-Guthabens und der bisher gesammelten oder eingelösten Coins in Ihrem Konto.',
    platincoins_condition_4:
      'Sie können Ihre PlatinCoins je nach Clubstatus zwischen 30 und 90 Tagen verwenden. Die Laufzeit berechnet sich ab der Gutschrift Ihres letzten PlatinCoins. Danach verfallen Ihre Coins und werden storniert. Wenn Sie sich im Status Platin befinden, können Ihre Coins zeitlich unbegrenzt verwendet werden, jedoch nur bis Ihre PlatinClub-Mitgliedschaft gekündigt oder Ihr Konto geschlossen wird.',
    platincoins_condition_5: `Die PlatinCoins sind nicht auf andere Spieler oder Konten übertragbar. Wenn Sie sich entscheiden, Ihr CasinoFive-Konto zu schließen und zu einem späteren Zeitpunkt ein neues CasinoFive-Konto zu eröffnen, werden Ihre Coins nicht übertragen.
    CasinoFive übernimmt keine Verantwortung für den Verlust von PlatinCoins oder eine verspätete oder fehlende Gutschrift von Coins aufgrund von Datenübertragungsproblemen.`,
    platincoins_condition_6: `Die Gewährung von PlatinCoins, die geltenden Bedingungen (z.B. Wettbeträge) und deren Umtausch in Prämien im CasinoFive Rewards Carnival Shop liegen im alleinigen Ermessen von CasinoFive und können jederzeit ohne Angabe von Gründen geändert und beendet werden. Dies kann allgemein sein, aber auch auf Einzelspielerebene. Vorab werden keine Informationen zur Verfügung gestellt.`,
    platincoins_condition_7:
      'Im Falle einer Suspendierung, d.h. eines zeitlichen Ausschlusses aus dem PlatinClub, wird die Nutzung Ihrer gesammelten PlatinCoins und bereits erworbenen Prämien ausgesetzt, diese bleiben jedoch bis zur Aufhebung der Suspendierung oder Ihrer Mitgliedschaft im PlatinClub bestehen.',
  },
  [ComponentName.PROFILE_ACCOUNT_BALANCE]: {
    my_account: 'Mein Kontostand',
    withdrawal_balance: 'Auszahlbares Guthaben:',
    non_withdrawalable: 'An Bonus gebundenes nicht auszahlbares Guthaben:',
    bonus_balance: 'Bonus Guthaben:',
    attention:
      'Achtung: Du hast die Möglichkeit den aktuellen bonus zu stornieren.',
    click_here_to_cancel:
      'Hier klicken um Deinen aktuallen Bonus zu stornieren',
    required_turnover: 'Erforderlicher Umsatz:',
    maximum_conversion: 'Maximaler Umwandlungsbetrag für diesen Bonus',
    before_wager: `Bevor der Einsatz aus dem Bonus Guthaben erfolgt, wird der Einsatz aus dem Echtgeld Guthaben eingesetzt.`,
  },
  [ComponentName.PROMOTIONS]: {
    more_info: 'Mehr Info',
    fairness_transparency: 'Gerechtigkeit & Transparenz',
  },
  [ComponentName.PROMOTION_VIEW]: {
    text_1:
      'Einfach starten - einfach und 100% sicher einzahlen - einfach WILLKOMMEN fühlen!',
    text_2: `CasinoFive bietet jedem neu registrierten Spieler einen Bonus: 100% bis zu 100 EUR auf Ihre erste Einzahlung!`,
    text_3: `Geben Sie Ihren Einzahlungsbetrag ab 10 EUR in das Feld "Betrag eingeben" ein`,
    text_4: `Geben Sie WILLKOMMEN in das Feld "Bonuscode" ein`,
    text_5: `1100% Ihres Einzahlungsbetrags bis zu 100 EUR insgesamt werden
    automatisch Ihrem Konto gutgeschrieben`,
    text_6: `Nutzen Sie unser WILLKOMMEN-Geschenk - und verdoppeln Sie Ihre erste Einzahlung für doppelten Spielspaß im CasinoFive. Ihr Betrag, Ihre Risikowahl, Ihr Spaß beim Spielen Ihrer Lieblings-Merkur-Slots.`,
    text_7: 'Hier alle Angebotsdetails auf einen Blick:',
    text_8: `Der Willkommensbonus ist nur bei Ihrer ersten Einzahlung gültig und wird nur einmal pro Spieler, Haushalt und IP-Adresse vergeben. Sie können keine zusätzlichen Willkommensboni beanspruchen. Der Bonus- und Einzahlungsbetrag muss innerhalb von 14 Tagen mindestens 25 Mal umgesetzt werden. Alle Geldeinsätze sind enthalten, werden aber je nach Spielart unterschiedlich gewichtet (siehe unten).`,
    show_results: 'Ergebnisse anzeigen',
    weighted: '% Gewichtet',
    game: 'Spiel',
    slots: 'Schlüssel',
    other_games: 'Andere Spiele',
    rouletter: 'Roulette, Baccarat',
    sic_bo: 'Sic Bo',
    blackjack: 'Blackjack & Craps',
    stud_poker: 'Stud-Poker',
    rosk_ladder: 'Rosk-Leiter, Doppel-oder-Nichts, Kartenrisiko',
    text_9: 'Diese Einsätze werden zunächst von Ihrem Echtgeldkonto abgebucht.',
    text_10: `Wenn Ihr Echtgeldkonto leer ist, werden Ihre Bonusguthaben verwendet.
    Gewinne aus Einsätzen, die mit echtem Geld platziert wurden, werden Ihrem
    Guthaben zum Abheben zur Verfügung. Gewinne über Ihrem Einzahlungsbetrag
    werden Ihrem Bonuskonto gutgeschrieben. Gewinne aus Spielen mit
    Ihr Bonus wird Ihrem Bonuskonto gutgeschrieben und kann nur ausgezahlt werden
    aus, wenn die Bonusbedingungen erfüllt sind. Ihr Bonus kann
    nur ausgezahlt werden, wenn Sie die beschriebenen Bonusbedingungen erfüllt haben
    oben: Ihre Ersteinzahlung und Ihr Bonusbetrag müssen umgesetzt worden sein
    mindestens 25-mal innerhalb von 14 Tagen. Sie können nur Gewinne abheben
    von Ihrer Echtgeldeinlage, bevor Sie diese Bedingung erfüllen. Bitte
    Beachten Sie, dass eine vorzeitige Auszahlung bedeutet, dass der Bonus verfällt. Wir
    behält sich das Recht vor, Auszahlungen abzulehnen oder zurückzuhalten im Falle von
    Missbrauch des Bonus, Rückbuchung oder andere außergewöhnliche Umstände
    nachteilig für das Casino, wie sie entstehen können.`,
    text_11: 'Allgemeine Bonusbedingungen:',
    text_12: `Wenn Sie mit Bonusgeld spielen, werden Ihnen alle Gewinne gutgeschrieben
    Bonuskonto. Bonusgeld kann danach in echtes Geld umgewandelt werden
    die Bonusbedingungen erfüllen. Bonusgeld ist ein Bonus, bis es hat
    wurde aktiv in das Benutzerkonto umgewandelt und kann daher nur
    nach Umwandlung und Erfüllung des Bonus ausgezahlt werden
    Bedingungen. Dafür muss der gesamte Bonusbetrag eine bestimmte Zahl betragen
    gewettet. Der Bonusbetrag ist der Betrag der Einzahlung zuzüglich des
    gewährter Bonusbetrag. Beispiel: Sie leisten eine Einzahlung von 50,00 € und
    zusätzlich 50,00 € Bonusguthaben erhalten - somit der Gesamtbonus
    Betrag von 100,00 € werden entsprechend oft umgerechnet. `,
    text_13: 'Bedingungen für Freispiele:',
    text_14: `20 Freespins am Slot Book of Aztec mit einem maximalen auszahlbaren Betrag von €
    100 und eine Wette von 75.`,
  },
  [ComponentName.REGISTER_SIDE_MENU]: {
    register_account: 'Konto registrieren',
  },
  [ComponentName.REGISTRATION_BOX]: {
    left_title: 'Das Sicherheitsversprechen von CasinoFive',
    left_text_1: '100 % sichere Zahlungen mit führenden Zahlungsanbietern',
    left_text_2: 'Höchste Datensicherheit Ihrer persönlichen Daten',
    left_text_3: 'Schnelle Auszahlung, sichere Einzahlung',
    middle_title: 'Die CasinoFive-Spielvielfalt',
    middle_text_1:
      'Grenzenloser Casino-Spaß, egal ob auf Mobilgeräten, Mac oder PC!',
    middle_text_2: 'Regelmäßige tolle Aktionen',
    middle_text_3:
      'Riesige Auswahl an Spielen der bekanntesten Premiumanbieter',
    right_title: 'Nur für Mitglieder Der CasinoFive Club',
    right_text_1: 'Casino-Nachrichten & Informationen zuerst',
    right_text_2: 'Bevorzugte Auszahlung Ihrer Gewinne',
    right_text_3: 'Sonderaktionen & Wettbewerbe',
  },
  [ComponentName.REGISTRATION_FORM]: {
    resend_confirmation_mail: 'Bestätigungsmail erneut senden',
    click_here: 'Klicken Sie hier',
    correct_following_errors: 'Bitte korrigieren sie die folgenden Fehler:',
    register_btn_label: 'Registrieren',
    email_label: 'E-mail',
    password_label: 'Passwort',
    password_confirmation_label: 'Passwort wiederholen',
    newsletter_subscription_label:
      'Jawohl! Ich möchte die neuesten Nachrichten, Gewinnspiele und Bonusangebote per e-mail erhalten.',
    terms_label:
      'Hiermit bestätige ich, dass ich älter als 18 Jahre bin und die Allgemeinen Geschäftsbedingungen und die Datenschutzrichtlinie von Red Rhino Ltd. für die Nutzung von CasinoFive.com gelesen und verstanden und akzeptiert habe.',
    login: 'Anmeldung',
  },
  [ComponentName.REGISTRATION_SUCCESS]: {
    check_your_email: 'Check deine e-mails',
    description: `Eine e-mail mit weiteren Anweisungen wurde an Ihr e-mail-Konto gesendet. Klicken Sie auf den Aktivierungslink, um Ihr Konto zu aktivieren.`,
    finish: 'Fertig',
    need_help: 'Brauchen Sie Hilfe?',
    click_here: 'Klicken Sie hier',
  },
  [ComponentName.RESEND_CONFIRMATION]: {
    back_to_registration: 'zurück zur Registrierungsseite',
    send_confirmation_again: 'Senden Sie die Bestätigungs-e-mail erneut',
    description: `Wenn Sie die Bestätigungs-e-mail nicht erhalten haben, können Sie die e-mail hier erneut senden.`,
    resend_link: 'Bestätigungslink erneut senden',
    email: 'E-mail',
  },
  [ComponentName.RESPONSIBLE_GAMING]: {
    responsible_gaming: 'Verantwortungsbewusstes Spielen',
    for_us_1: `Bei `,
    for_us_2: ` möchten wir unsere Spieler bei einem sicheren und verantwortungsvollen Spielverhalten unterstützen, so dass zeitnah richtige Entscheidungen getroffen werden können.`,
    we_recommend: `Damit Spielen auch weiterhin einer spannenden Unterhaltung dient und nicht zu Problemen führt, raten wir Dir, die folgenden Grundsätze zu beherzigen:`,
    always_remember: `Sei Dir stets bewusst, dass das Spiel ausschließlich Deiner Unterhaltung dient und keine Einkommensquelle darstellt.`,
    set_yourself_a_limit: `Setze Dir ein Limit für die Höhe Deiner Einsätze in dem Umfang, den Du Dir auch leisten kannst.`,
    never_play_with_money: `Setze nie Geld ein, das Du für den Lebensunterhalt oder Nahrungsmittel benötigst.`,
    do_not_take_out_loans: `Leihe Dir niemals anderweitig Geld nur um zu spielen.`,
    plan_ahead_how_much: `Plane voraus, wie viel Zeit Du für Wetten und zum Spielen aufwenden möchtest.`,
    stick_to_these_plans: `Halte Dich an diese Pläne, unabhängig davon, ob Du gewinnst oder verlierst.`,
    do_not_gamble_if_you_are_irritated: `Spiele nicht, wenn Du unkonzentriert bist oder unter Alkohol- oder Medikamenteneinfluss stehst und lege regelmäßig Pausen ein.`,
    do_not_gamble_to_help_distract: `Spiele nicht, um Dich von Streitigkeiten, Enttäuschungen oder Problemen abzulenken.`,
    do_not_neglect_your_social_environment: `Vergiss Dein soziales Umfeld nicht, und nimm Reaktionen von Partner/in, Familienmitgliedern und Freunden ernst.`,
    do_not_play_in_secret: '', //NOTE: this property is needed for the English translation
    self_assessmet: `Selbsteinschätzung`,
    are_you_at_rist: `Bist Du gefährdet?`,
    think_about_the_past: `Denke über die letzten 12 Monate nach und beantworte die folgenden Fragen ehrlich.`,
    have_you_ever_bet: `1. Hast Du schon einmal mehr eingesetzt, als Du Dir eigentlich hättest leisten können zu verlieren? `,
    have_you_borrowed_money: `2. Hast Du Dir Geld geliehen, einen Kredit aufgenommen oder irgendetwas verkauft, um an Geld zum Spielen zu kommen?`,
    has_gambling_ever_caused: `3. Hat das Spielen schon einmal in Deinem Hause oder bei der Arbeit für Probleme gesorgt?`,
    have_you_ever_gambled: `4. Hast Du schon mal gespielt, nur um mit dem Gewinn Schulden zu begleichen oder finanzielle Schwierigkeiten zu lösen?`,
    has_your_performance_decreased: `5. Wurde Deine Leistungsfähigkeit durch das Spielen verringert?`,
    after_playing: `6. Hast Du nach dem Spielen das Gefühl, so bald wie möglich zum Spiel zurückkehren zu müssen, um Deine Verluste zurückzugewinnen?`,
    do_you_regularly_play: `7. Spielst Du regelmäßig länger als geplant?`,
    have_you_ever_had_trouble: `8. Hattest Du schon mal Schlafprobleme durch das Spielen?`,
    have_you_had_any_other_health_problems: `9. Hattest Du andere Gesundheitsprobleme aufgrund des Spielens, leidest Du z. B. unter Stress oder Angstgefühlen?`,
    do_you_regularly_gamble: `10. Spielst Du regelmäßig so lange, bis Du kein Geld mehr hast?`,
    have_you_ever_found: `11. Hast Du Dein eigenes Spielverhalten bereits als selbstzerstörerisch empfunden?`,
    have_you_ever_felt_guilty: `12. Hast Du Dich schon einmal schuldig für Dein Spielverhalten gefühlt oder schuldig für das was passiert, wenn Du spielst?`,
    in_case_you_answer: `Solltest Du tatsächlich 7 oder mehr Fragen mit ja beantwortet haben, ist es an der Zeit über Dein Spielverhalten nachzudenken und es zu kontrollieren.`,
    responsible_gambling_tools: `Hilfsmittel für ein verantwortungsbewusstes Spielverhalten`,
    with_us_you_always_have_1: `Bei uns hast Du jederzeit die Möglichkeit, Dir über`,
    my_profile_limits: `“Mein Profil" > "Limits“`,
    with_us_you_always_have_2: `tägliche, wöchentliche oder monatliche Limits zu setzen.`,
    if_you_lower_your_limits: `Wenn Du Deine Limits verringerst, tritt die Änderung sofort in Kraft. Wenn Du Dein Limit erhöhst, startet eine siebentägige Übergangszeit. Nach Ablauf der sieben Tage wird Dein neues Limit aktiviert.`,
    you_can_also_set: `Damit kannst Du Deinem Spielverhalten Grenzen setzen, deren Einhaltung wir zuverlässig sicherstellen. Ebenfalls kannst Du Dir eigene Spielpausen einstellen.`,
    cool_offs_and_self_exclusion: `Spielpause und Selbstauschluss`,
    cool_off: `Zeitlich begrenzte Spielpause`,
    you_can_set_up_a_time_limited_cool_off_period_1: `Eine zeitlich begrenzte Spielpause kannst Du über die Funktion in Deinem Kundenkonto`,
    my_profile_self_exclusion: `"Mein Profil" > "Selbstausschluss" `,
    you_can_set_up_a_time_limited_cool_off_period_2: `selbständig einrichten.`,
    with_the_cool_off: `Bei der zeitlich begrenzten Spielpause kannst Du einen Ausschlusszeitraum bis zu 30 Tage wählen. In diesem Zeitraum kannst Du weder spielen noch Geld auf Dein Kundenkonto einzahlen. Am Ende dieses ausgewählten Zeitraums wird das Konto automatisch wieder freigegeben.`,
    unrestricted_block: `Zeitlich unbegrenzte Spielsperre`,
    if_you_want_to_exclude_yourself_1: `Sollten Du Dich zeitlich unbegrenzt vom Spiel ausschließen wollen, also eine Sperre die so lange aktiv bleibt, bis Du sie selber widerrufst, kannst Du hierfür unseren Kundenservice via Chat oder E-Mail`,
    if_you_want_to_exclude_yourself_2: `kontaktieren. In diesem Zeitraum kannst Du weder spielen noch Geld auf Dein Kundenkonto einzahlen.`,
    of_course_we_also_offer_1: `Weiterhin bieten wir Dir die Möglichkeit, Dich permanent zu sperren. Dies ist auch über eine Anfrage bei unserem Kundenservice via Chat oder per E-Mail`,
    of_course_we_also_offer_2: `möglich. Wir sind dann bemüht, alle Deine neuregistrierten Konten aufzuspüren und diese zu schließen. Du wirst auch keine E-Mails zu Werbezwecken mehr erhalten.`,
    limits: 'Limits',
    deposit_limits: 'Einzahlungslimits',
    deposit_limits_help:
      'Einzahlungslimits helfen Dir zu bestimmen, wie viel Geld Du auf Dein Kundenkonto pro Monat, Woche oder Tag einzahlen kannst. Durch die Begrenzung der Einzahlung kannst Du Deine Glücksspielausgaben auf sehr einfache Art und Weise einschränken.',
    loss_limits: 'Verlustlimits',
    we_offer_loss_limits: `Wir bieten Dir Verlustlimits an, die es Dir ermöglichen, ein persönliches Limit für Echtgeld-Verluste pro Tag, Woche oder Monat einzustellen.`,
    stake_limits: `Einsatzlimits`,
    as_part_of_a_stake_limit: `Im Rahmen eines Einsatzlimits werden alle Deine Einsätze pro Tag, Woche oder Monat summiert. Sobald diese das gesetzte Limit erreicht haben, werden weitere Einsätze im Spiel verhindert.`,
    time_limits: `Zeitliche Limits`,
    time_limits_allow_you: `Zeitlimits ermöglichen es Dir, die Zeit, die Du in einer Spielsitzung verbringst, besser vorauszuplanen. Wenn Du Dir ein Zeitlimit setzt, dann wirst Du automatisch nach Verstreichen dieses Zeitraums an das gesetzte Limit erinnert und automatisch augeloggt.`,
    addiction_advice_centres: `Beratung`,
    if_you_feel_that_you_are_gambling: `Spielst Du mehr als Du es eigentlich möchtest? Wenn Du das Gefühl hast, Dein Spielverhalten nicht mehr unter Kontrolle zu haben oder Deine Freunde und Familie Anzeichen von Glücksspielsucht an Dir feststellen,`,
    the_following_organisations_provide: `dann gibt es verschiedene Stellen, die Anreize, Lösungen, Hilfe und weitere Informationen für Dich bereithalten:`,
    link_1_label: `http://www.gluecksspielsucht.de`,
    link_1_url: `http://www.gluecksspielsucht.de`,
    link_2_label: `http://www.spielen-mit-verantwortung.de`,
    link_2_url: `http://www.spielen-mit-verantwortung.de`,
    last_text:
      'Kunden aus Deutschland haben darüber hinaus die Möglichkeit, sich an das kostenlose Beratungstelefon der Bundeszentrale für gesundheitliche Aufklärung (BZgA) zu wenden: 0800 1372000 (kostenlos)', //NOTE: this property is needed for the German translation
  },
  [ComponentName.REWARD_BREADCRUMBS]: {
    rewards: 'Belohnung',
    platin_coins: 'Platinmünzen',
  },
  [ComponentName.REWARD_CARD]: {
    buy: 'Kaufen Sie',
    coins: 'Münzen',
  },
  [ComponentName.REWARD_CARNIVAL]: {
    text_1: `Ab sofort werden Sie bei unserem Platin REWARDS CARNIVAL für Ihr Spiel belohnt. Jede Wette auf Ihre Lieblings-Slots zählt und füllt Ihr PlatinCoins-Konto.`,
    text_2: `Mit deinen gesammelten PlatinCoins kannst du im REWARDS CARNIVAL Shop nach zusätzlichen Freispielen, Boni, Echtgeldguthaben oder exklusiven Artikeln wie Gutscheinen für deinen nächsten Supermarktbesuch shoppen.`,
    text_3: `Sie können herausfinden, wie viele PlatinCoins Sie erhalten, indem Sie Ihren PlatinClub-Status überprüfen. Je nach Status kann das Sammeln von Münzen bis zu 10-mal schneller sein.`,
    text_4: `Deinen persönlichen PlatinCoins-Saldo kannst du in deiner Kontoübersicht und oberhalb des REWARDS CARNIVAL Shops einsehen. So haben Sie immer den Bonus im Blick, mit dem Sie sich den Tag versüßen können.`,
    text_5: `Kurz erklärt, wie der Kauf von Shop-Angeboten funktioniert:`,
    text_6: `Sie sehen auf einen Blick, wie viele PlatinCoins Sie für einen Artikel benötigen. Die Nummer wird auf der Schaltfläche "Kaufen" angezeigt. Wenn Sie auf diese Schaltfläche klicken, werden Sie aufgefordert, Ihren Kauf erneut zu bestätigen. Nun wird Ihr Angebot für Sie freigeschaltet und als neue "Benachrichtigungen" angezeigt. Von dort aus können Sie es nun einfach aktivieren. Einkäufe von Sonderangeboten, wie zum Beispiel Gutscheine, können bei unserem Support-Team abgeholt werden.`,
  },
  [ComponentName.SEARCH]: {
    search: 'Suche',
    search_input_options: 'Suchfilteroptionen',
    reset_filter: 'Filter zurücksetzen',
    [Filter.ALL_CATEGORIES]: 'Alle Kategorien',
    [Filter.ALL_PROVIDERS]: 'Alle Anbieter',
    [Filter.FILTER_BY]: 'Filtern nach',
    [Filter.ORDER_BY]: 'Sortieren nach',
    [FilterByFilter.TOP_GAMES]: 'Top-Spiel',
    [FilterByFilter.NEW_GAMES]: 'Neue Spiele',
    [OrderByFilter.A_Z]: 'A-Z',
    [OrderByFilter.Z_A]: 'Z-A',
  },
  [ComponentName.SUPPORT_COMPONENT]: {
    go_to_section: 'Zum Abschnitt gehen',
  },
  TabPanels: {
    back: 'Zurück',
  },
  [ComponentName.UPDATE_PROFILE_BUTTON]: {
    bttn_label: 'Profile aktualisieren',
  },
  [ComponentName.USER]: {
    go_to_section: 'Zum Abschnitt gehen',
  },
  [ComponentName.USER_ACCOUNT_MENU_LIST]: {
    my_profile: 'Mein Profil',
    change_password: 'Ändere das Passwort',
    game_rounds: 'Spielrunden',
    transactions: 'Transaktionen',
    newsletter: 'Newsletter',
    limits: 'Grenzen',
    verify_account: 'Konto überprüfen',
    documents: 'Unterlagen',
    self_exclusion: 'Selbstausschluss',
    my_account_balance: 'Mein Kontostand',
    deposit: 'Anzahlung',
    withdrawal: 'Rückzug',
  },
  [ComponentName.USER_ACCOUNT]: {
    title: 'Mein Profil',
    description:
      'Bei Änderungen Ihrer Adresse und/oder personenbezogenen Daten wenden Sie sich bitte an unseren Kundenservice.',
    personal_data: 'Persönliche Daten',
    address: 'Adresse',
    address_secondary: 'Adresse Sekundär',
    dashboard_settings: 'Dashboard-Einstellungen',
    additional_data: 'Zusätzliche Daten',
    notice: 'Konto erfolgreich aktualisiert.',
    error: 'Beim Speichern Ihres Kontos ist ein Fehler aufgetreten',
    edit_account_global: 'Kontobearbeitung',
    back_to_dashboard: 'Zurück zum Dashboard',
    save_change: 'Profile aktualisieren',
    email: 'E-mail',
    sex: 'Anrede',
    first_name: 'Vorname',
    last_name: 'Familienname',
    date_of_birth: 'Geburtsdatum',
    phone: 'Telefon',
    business_phone: 'Geschäftstelefon',
    mobile_phone_number: 'Mobiltelefon',
    citizenship: 'Staatsbürgerschaft',
    dashboard_culture: 'Dashboard-Kultur',
    birth_country: 'Geburtsland',
    birth_place: 'Geburtsort',
    street_and_house_number: 'StreeStraße, Hausnummer',
    zip_code: 'Postleitzahl',
    city: 'Stadt',
    country: 'Land',
    birth_date: 'Geburtsdatum',
    mr: 'Herr',
    mrs: 'Frau',
  },
  [ComponentName.USER_DOCUMENTS]: {
    title: 'Unterlagen',
    description: '',
    type: 'Typ',
    select_file: 'Datei aussuchen',
    file_name: 'Dateinamen',
    upload_date: 'Hochladedatum',
    expiry_date: 'Verfallsdatum',
    issue_date: 'Ausgabetag',
    status: 'Status',
    my_documents: 'Meine Dokumente',
    no_documents: 'Es sind noch keine Dokumente zum Anzeigen vorhanden.',
  },
  [ComponentName.USER_DOCUMENT_FORM]: {
    upload_file: 'Datei hochladen',
    choose_file: 'Datei wählen',
    upload_restrictions:
      'Max. Dateigröße: 8,00 MB, Erlaubte Dateitypen: .jpg, .jpeg, .png, .pdf, .gif',
    type: 'Typ',
    upload_date: 'Hochladedatum',
    expiry_date: 'Verfallsdatum',
    issue_date: 'Ausgabetag',
    please_select: 'Bitte auswählen',
  },
  [ComponentName.USER_EDIT]: {
    my_profile: 'Mein Profil',
    description:
      'Bei Änderungen Ihrer Adresse und/oder personenbezogenen Daten wenden Sie sich bitte an unseren Kundenservice.',
    personal_data: 'Personal Data',
    address: 'Adresse',
    street_and_house_number_label: 'Straße, Hausnummer',
    salutation_label: 'Anrede',
    salutation_placeholder: 'Bitte auswählen',
    zip_code_label: 'Postleitzahl',
    first_name_label: 'Vorname',
    last_name_label: 'Familienname',
    date_of_birth_label: 'Geburtsdatum',
    citizenship_label: 'Staatsbürgerschaft',
    city_label: 'Stadt',
    country_label: 'Land',
    mr: 'Herr',
    mrs: 'Frau',
    to_be_able: `Um alle Funktionen des Casinos nutzen zu können, geben Sie bitte Ihre Daten ein, dies ist erforderlich.`,
  },
  [ComponentName.USER_GAME_ROUNDS]: {
    title: 'Spielrunden',
    description: '',
    filter_game_rounds: 'Spielrunden filtern',
    showing_results: 'Ergebnisse anzeigen',
    game_token: 'Spiel#',
    time: 'Zeit',
    game: 'Spiel',
    balance: 'Saldo (Beginn/Ende)',
    bet: 'Wette',
    win: 'Gewinnen',
    no_game_rounds: 'Es sind keine Spielrunden zu zeigen.',
    date_from: 'Datum von',
    date_to: 'Datum bis',
    bttn_label: 'Zeige Ergebnisse',
  },
  [ComponentName.USER_LIMIT_EDIT]: {
    title: 'Grenzen',
    description: '',
    define_limits: 'Definieren Sie Ihre Selbstschutzgrenzen.',
    deposit_limit: 'Erhöhte Limits werden nach einer Woche aktiviert!',
    cancel_bttn_label: 'Stornieren',
    submit_bttn_label: 'Limit setzen',
    form_amount_label: 'Einzahlungsmaximum €',
    form_amount_placeholder: 'Limit eingeben',
    form_period_label: 'per',
    month: 'Monat',
    week: 'Woche',
    day: 'Tag',
    hour: 'Stunde',
    minute: 'Minute',
    [LimitType.DEPOSIT]: 'Hinterlegung',
    [LimitType.WAGER]: 'Wette',
    [LimitType.LOSS]: 'Verlust',
    [LimitType.TIME]: 'Zeit',
    session_time_maximum: 'Sitzungszeit Maximum',
  },
  [ComponentName.USER_LIMITS]: {
    title: 'Grenzen',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Donec ullamcorper nulla non metus auctor fringilla.',
    success: 'Limit erfolgreich aktualisiert',
    error: 'Beim Speichern des Limits ist ein Fehler aufgetreten',
    [LimitType.DEPOSIT]: 'Anzahlung',
    [LimitType.WAGER]: 'Wette',
    [LimitType.LOSS]: 'Verlust',
    [LimitType.TIME]: 'Zeit',
    no_limit: 'kein Limit gesetzt',
    account_limits: 'Kontolimits',
    type: 'Typ',
    period: 'Zeitraum',
    limit: 'LimGrenzeit',
    remaining: 'Verbleibend',
    valid_from: 'Gültig ab',
    valid_to: 'Gültig bis',
    options: 'Optionen',
  },
  [ComponentName.USER_MENU]: {
    withdraw: 'Abheben',
    deposit: 'Anzahlung',
    my_account: 'Mein Konto',
    transactions: 'Transaktionen',
    game_rounds: 'Spielrunden',
    support: 'Die Unterstützung',
    information: 'Information',
    logout: 'Ausloggen',
  },
  [ComponentName.USER_NEWSLETTER]: {
    newsletter: 'Newsletter',
    description: '',
    stay_updated: 'Bleiben Sie mit unseren Sonderangeboten auf dem Laufenden',
    email_label: 'e-mail-Newsletter',
    sms_label: 'Handy-SMS',
    update_bttn_label: 'Aktualisieren',
  },
  [ComponentName.USER_NOTIFICATION]: {
    bonus: 'Bonus:',
    amount: 'Menge:',
    code: 'Code:',
    use: 'verwenden',
    Prozent: 'Prozent:',
    free_spin: 'Freispiel:',
  },
  [ComponentName.USER_NOTIFICATIONS]: {
    notifications: 'Benachrichtigungen',
    empty_list: 'Ihr Konto hat noch keine Benachrichtigungen.',
  },
  [ComponentName.USER_SELF_EXCLUSION]: {
    title: 'Selbstausschluss',
    subtitle: 'Benutzerkonto zeitlich begrenzt oder dauerhaft deaktivieren',
    description: `Du kannst Dein Benutzerkonto für einen bestimmten Zeitraum sperren. Dein Benutzerkonto wird nach Ablauf der Zeit automatisch wieder freigeschaltet.
    Wenn Du eine zeitlich unbegrenzte Sperrung bestätigst, wird Dein Benutzerkonto für weitere Aktivitäten gesperrt. Ein Einloggen ist dann nicht mehr möglich.`,
    form_title: 'Select self exclusion settings',
    success: 'Selbstausschluss erfolgreich gespeichert',
    error: 'Beim Selbstausschluss ist ein Fehler aufgetreten',
    form_period_label: 'Dauer der Deaktivierung',
    form_reason_for_deactivation: 'Grund der Deaktivierung',
    submit_bttn_label: 'Setup-Ausschluss',
    day: 'Tag',
    three_days: '3 Tage',
    week: 'Woche',
    month: 'Monat',
    three_months: '3 Monate',
    six_months: '6 Monate',
    year: 'Jahr',
    notice:
      'Für eine dauerhafte Sperrung Deines Kontos wende Dich bitte an unser Support-Team.',
  },
  [ComponentName.USER_TRANSACTIONS]: {
    title: 'Transaktionen',
    description: '',
    filter_transactions: 'Transaktionen filtern',
    showing_results: 'Ergebnisse anzeigen',
    date: 'Datum',
    method: 'Methode',
    status: 'Status',
    bonus_amount: 'Bonusbetrag',
    bonus_balance: 'Bonusguthaben',
    amount: 'Menge',
    balance: 'Balance',
    currency: 'Währung',
    no_transactions: 'Es sind noch keine Transaktionen zum Anzeigen vorhanden.',
    date_from: 'Datum von',
    date_to: 'Datum bis',
    bttn_label: 'Zeige Ergebnisse',
  },
  [ComponentName.WITHDRAWAL_PANEL]: {
    title: 'Rückzug',
    description: '',
    select_withdrawal_method: 'Auszahlungsmethode auswählen',
    no_deposit:
      'Es wurde keine Anzahlung geleistet. Um Geld abheben zu können, müssen Sie Ihr Konto aufladen.',
    withdrawal_confirmation: 'Auszahlungsbestätigung',
    min: 'Min',
    max: 'Max',
    amount: 'Menge',
    submit: 'einreichen',
    withdrawal_method_not_found:
      'Die Auszahlungsmethode ist falsch oder nicht ausgewählt. Bitte wählen Sie zuerst die Auszahlungsmethode in Schritt 1 aus.',
    currency: 'Währung',
    payment_method_name: 'Name der Zahlungsmethode',
    owner_name: 'Besitzername',
    payment_card_number: 'Zahlungskartennummer',
    payment_card_expire_date: 'Ablaufdatum der Zahlungskarte',
    your_account_balance: 'Ihr Kontostand',
    account_status: 'Kontostand',
    payment_bank_account_number: 'Kontonummer der Zahlungsbank',
  },
  [ComponentName.WITHDRAWAL_HISTORY]: {
    title: 'Entzugshistorie',
    description: 'Detaillierte Liste der Auszahlungshistorie',
    cancel: 'Abhebung stornieren',
    currency: 'Währung',
    amount: 'Menge',
    created: 'Startdatum',
    state: 'Zustand',
    action: 'Aktion',
  },
  [ModalName.PAY_AND_PLAY_MODAL]: {
    deposit_and_play: 'einzahlen und spielen',
    deposit_amount: 'Einzahlungsbetrag',
    bonus_code: 'Bonuscode',
  },
  [ModalName.REALITY_CHECK_MODAL]: {
    reality_check: 'Realitätscheck',
    confirm: 'Bestätigen Sie',
    leave: 'Verlassen',
    question: 'Spielst du immernoch?',
    bet: 'WETTE:',
    win: 'GEWINNEN:',
  },
  [ModalName.LOGIN_MODAL]: {
    form_h1: 'Bitte loggen Sie sich ein',
    form_label_email: 'E-mail-Addresse',
    form_label_passwd: 'Passwort',
    forgot_your_password: 'Passwort vergessen?',
    click_here: 'Klicken Sie hier',
    submit_login_btn_label: 'Anmeldung',
    no_account: 'Kein Account?',
    register_now: 'Jetzt registrieren',
    german_notice:
      'Glücksspiel kann süchtig machen. Spiele verantwortungsbewusst. Mehr Informationen unter',
  },
  [ModalName.PAY_AND_PLAY_SESSION_DETAILS]: {
    pay_and_play_session_details: 'Details der Pay-and-Play-Sitzung',
    due_to_regulations:
      'Aufgrund von Vorschriften müssen wir Sie nach Ihrem Geschlecht fragen',
    salutation_label: 'Anrede',
    salutation_placeholder: 'Bitte auswählen',
    mr: 'Herr',
    mrs: 'Frau',
    email: 'Email',
    to_stay_up_to_date:
      'Um über Neuigkeiten und Angebote auf dem Laufenden zu bleiben, fügen Sie hier Ihre E-Mail-Adresse hinzu',
    i_agree: `Ich stimme zu, dass CasinoFive mich über Neuigkeiten und Angebote auf dem Laufenden halten wird. Ich kann meine Zustimmung jederzeit von meinem eigenen Konto widerrufen.`,
    save_information: 'Informationen speichern',
  },
  [ModalName.RESEND_CONFIRMATION_EMAIL_MODAL]: {
    title: 'Aktivierungslink erneut senden',
    email: 'E-mail',
    resend: 'Erneut senden',
    need_help: 'Brauchen Sie Hilfe?',
    click_here: 'Klicken Sie hier',
  },
  [ModalName.FORGOT_PASSWORD_MODAL]: {
    title: 'Passwort vergessen?',
    email: 'E-mail your account email',
    resend: 'Erneut senden',
    need_help: 'Brauchen Sie Hilfe?',
    click_here: 'Klicken Sie hier',
  },
  [ModalName.PNP_ACCOUNT_MODAL]: {
    pay_and_play: 'Bezahlen und spielen',
    main: 'Haupt',
    login: 'Einloggen',
    registration: 'Registrierung',
  },
  [ModalName.STORE_UPDATE_MODAL]: {
    title: 'Versions-Update',
    line_1: 'Versionsaktualisierung.',
    line_2:
      'Damit die Website richtig funktioniert, muss sie aktualisiert werden.',
    update: 'Aktualisieren',
  },
  [ModalName.REGISTER_MODAL]: {
    register_account: 'Account registrieren',
    german_notice:
      'Glücksspiel kann süchtig machen. Spiele verantwortungsbewusst. Mehr Informationen unter',
  },
}
