import { AuthApi } from '@/api'
import {
  LIMIT_DICTIONARY,
  SET_LIMIT_DICTIONARY,
  IS_LIMIT_PENDING,
  SET_IS_LIMIT_PENDING,
  DROP_LIMIT,
  ResponseState,
  EMPTY_OBJECT,
  LIMIT_ERROR,
  SET_LIMIT_ERROR,
} from '@/constants'
import { isNotNil } from '@/helpers'

export const limit = {
  namespaced: true,
  state: {
    [LIMIT_DICTIONARY]: EMPTY_OBJECT,
    [IS_LIMIT_PENDING]: false,
    [LIMIT_ERROR]: null,
  },
  mutations: {
    [SET_LIMIT_DICTIONARY](state, { dictionary }) {
      state[LIMIT_DICTIONARY] = dictionary
    },
    [SET_IS_LIMIT_PENDING](state, { value }) {
      state[IS_LIMIT_PENDING] = value
    },
    [SET_LIMIT_ERROR](state, { value }) {
      state[LIMIT_ERROR] = value
    },
  },
  actions: {
    async [SET_LIMIT_DICTIONARY](context) {
      try {
        context.commit(SET_IS_LIMIT_PENDING, { value: true })

        const { data, state, msg } = await AuthApi.getUserLimits()

        if (state === ResponseState.OK) {
          const dictionary = {}

          Object.keys(data.items).forEach((limitType) => {
            dictionary[limitType] = {}
            const limit = data.items[limitType]

            dictionary[limitType] = isNotNil(Number(limit.amount)) ? limit : {}
          })

          context.commit(SET_LIMIT_DICTIONARY, { dictionary })
          context.commit(SET_LIMIT_ERROR, { value: null })
        }

        if (state === ResponseState.ERROR) {
          context.commit(SET_LIMIT_ERROR, { value: msg })
        }

        context.commit(SET_IS_LIMIT_PENDING, { value: false })
      } catch (e) {
        console.dir(e)
      }
    },
    [DROP_LIMIT](context) {
      context.commit(SET_LIMIT_DICTIONARY, {
        dictionary: EMPTY_OBJECT,
      })
    },
  },
}
