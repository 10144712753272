import { SLASH } from '@/constants'

export const prependLocaleToPath = ({ params, ...rest }) => {
  if (rest.fullPath === SLASH) {
    return `/${window.i18n.locale}${rest.path}`
  }

  return {
    params: { ...params, lang: window.i18n.locale },
    ...rest,
  }
}
