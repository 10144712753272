import { MainApi } from '@/api'
import { CMS_GROUP_DICTIONARY, SET_CMS_GROUP_DICTIONARY } from '@/constants'

export const cms = {
  namespaced: true,
  state: {
    [CMS_GROUP_DICTIONARY]: {},
  },
  mutations: {
    [SET_CMS_GROUP_DICTIONARY](state, { data }) {
      state[CMS_GROUP_DICTIONARY] = data
    },
  },
  actions: {
    async [SET_CMS_GROUP_DICTIONARY](context) {
      try {
        context.commit(
          SET_CMS_GROUP_DICTIONARY,
          await MainApi.getCmsTree({ type: 1 }),
        )
      } catch (e) {
        console.dir(e)
      }
    },
  },
}
