import { AuthApi } from '@/api'
import {
  DOC_TYPE_DICTIONARY,
  SET_DOC_TYPE_DICTIONARY,
  IS_DOC_TYPE_PENDING,
  SET_IS_DOC_TYPE_PENDING,
  ResponseState,
  DROP_DOC_TYPE,
  EMPTY_OBJECT,
  ID,
  ITEMS,
} from '@/constants'
import { indexBy, path, pipe, when } from '@/helpers'

const initialIsPendingValue = false

export const docType = {
  namespaced: true,
  state: {
    [DOC_TYPE_DICTIONARY]: EMPTY_OBJECT,
    [IS_DOC_TYPE_PENDING]: initialIsPendingValue,
  },
  mutations: {
    [SET_DOC_TYPE_DICTIONARY](state, { dictionary }) {
      state[DOC_TYPE_DICTIONARY] = dictionary
    },
    [SET_IS_DOC_TYPE_PENDING](state, value) {
      state[IS_DOC_TYPE_PENDING] = value
    },
  },
  actions: {
    async [SET_DOC_TYPE_DICTIONARY](context) {
      try {
        context.commit(SET_IS_DOC_TYPE_PENDING, true)

        const { data, state } = await AuthApi.getDocumentTypes()

        when(
          () => state === ResponseState.OK,
          pipe(
            path([ITEMS]),
            when(
              Array.isArray,
              pipe(indexBy(ID), (dictionary) => {
                context.commit(SET_DOC_TYPE_DICTIONARY, { dictionary })
                context.commit(SET_IS_DOC_TYPE_PENDING, initialIsPendingValue)
              }),
            ),
          ),
        )(data)
      } catch (e) {
        console.dir(e)
      }
    },
    [DROP_DOC_TYPE](context) {
      context.commit(SET_DOC_TYPE_DICTIONARY, {
        dictionary: EMPTY_OBJECT,
      })
    },
  },
}
