export const Module = {
  CASINO_INFO: 'CASINO_INFO',
  CMS: 'CMS',
  DEPOSIT_METHOD: 'DEPOSIT_METHOD',
  DEVICE: 'DEVICE',
  DOC_TYPE: 'DOC_TYPE',
  DOCUMENT: 'DOCUMENT',
  FAVOURITE_GAME: 'FAVOURITE_GAME',
  FLASH: 'FLASH',
  GAME_DEMO: 'GAME_DEMO',
  GAME_INFO: 'GAME_INFO',
  GAME_NOTIFICATION: 'GAME_NOTIFICATION',
  GAME_PLAY: 'GAME_PLAY',
  GAME_VENDOR: 'GAME_VENDOR',
  LIMIT: 'LIMIT',
  LOCATION: 'LOCATION',
  MAIN: 'MAIN',
  MODAL: 'MODAL',
  NEWSLETTER: 'NEWSLETTER',
  OFFER: 'OFFER',
  RECENT_GAME: 'RECENT_GAME',
  SEARCH_RESULT: 'SEARCH_RESULT',
  SLIDE: 'SLIDE',
  USER: 'USER',
  USER_NOTIFICATION: 'USER_NOTIFICATION',
  VENDOR: 'VENDOR',
  WITHDRAWAL_METHOD: 'WITHDRAWAL_METHOD',
}

//Casino Info module
export const CASINO_INFO = 'CASINO_INFO'
export const UPDATE_CASINO_INFO = 'UPDATE_CASINO_INFO'
export const IS_CASINO_INFO_PENDING = 'IS_CASINO_INFO_PENDING'
export const SET_IS_CASINO_INFO_PENDING = 'SET_IS_CASINO_INFO_PENDING'
export const DROP_CASINO_INFO = 'DROP_CASINO_INFO'

//CMS module
export const CMS_GROUP_DICTIONARY = 'CMS_GROUP_DICTIONARY'
export const SET_CMS_GROUP_DICTIONARY = 'SET_CMS_GROUP_DICTIONARY'

//Deposit Method module
export const DEPOSIT_METHOD_DICTIONARY = 'DEPOSIT_METHOD_DICTIONARY'
export const SET_DEPOSIT_METHOD_DICTIONARY = 'SET_DEPOSIT_METHOD_DICTIONARY'
export const IS_DEPOSIT_METHOD_DICTIONARY_PENDING =
  'IS_DEPOSIT_METHOD_DICTIONARY_PENDING'
export const SET_IS_METHOD_DICTIONARY_PENDING =
  'SET_IS_METHOD_DICTIONARY_PENDING'
export const DROP_DEPOSIT_METHOD_DICTIONARY = 'DROP_DEPOSIT_METHOD_DICTIONARY'

//Device module
export const SCREEN_WIDTH = 'SCREEN_WIDTH'
export const SET_SCREEN_WIDTH = 'SET_SCREEN_WIDTH'
export const IS_MOBILE_SCREEN = 'IS_MOBILE_SCREEN' // getter

//Doc Type module
export const DOC_TYPE_DICTIONARY = 'DOC_TYPE_DICTIONARY'
export const SET_DOC_TYPE_DICTIONARY = 'SET_DOC_TYPE_DICTIONARY'
export const IS_DOC_TYPE_PENDING = 'IS_DOC_TYPE_PENDING'
export const SET_IS_DOC_TYPE_PENDING = 'SET_IS_DOC_TYPE_PENDING'
export const DROP_DOC_TYPE = 'DROP_DOC_TYPE'

//Document module
export const DOCUMENT_DICTIONARY = 'DOCUMENT_DICTIONARY'
export const UPDATE_DOCUMENT_DICTIONARY = 'UPDATE_DOCUMENT_DICTIONARY'
export const IS_DOCUMENT_PENDING = 'IS_DOCUMENT_PENDING'
export const SET_IS_DOCUMENT_PENDING = 'SET_IS_DOCUMENT_PENDING'
export const DROP_DOCUMENT = 'DROP_DOCUMENT'
export const DOCUMENT_MESSAGE = 'DOCUMENT_MESSAGE'
export const SET_DOCUMENT_MESSAGE = 'SET_DOCUMENT_MESSAGE'

//Favourite Game module
export const FAVOURITE_GAME_DICTIONARY = 'FAVOURITE_GAME_DICTIONARY'
export const UPDATE_FAVOURITE_GAME_DICTIONARY =
  'UPDATE_FAVOURITE_GAME_DICTIONARY'
export const IS_FAVOURITE_PLAY_GAME_PENDING = 'IS_FAVOURITE_PLAY_GAME_PENDING'
export const SET_IS_FAVOURITE_PLAY_GAME_PENDING =
  'SET_IS_FAVOURITE_PLAY_GAME_PENDING'
export const FAVOURITE_GAME_TOTAL = 'FAVOURITE_GAME_TOTAL'
export const FAVOURITE_GAME_DICTIONARY_BY_ID = 'FAVOURITE_GAME_DICTIONARY_BY_ID'
export const FAVOURITE_GAME_STATIC_PENDING_LIST =
  'FAVOURITE_GAME_STATIC_PENDING_LIST'
export const RESET_FAVOURITE_GAME_DICTIONARY = 'RESET_FAVOURITE_GAME_DICTIONARY'

//Flash module
export const FLASH_DICTIONARY = 'FLASH_DICTIONARY'
export const ADD_FLASH = 'ADD_FLASH'
export const REMOVE_FLASH = 'REMOVE_FLASH'
export const DROP_FLASH = 'DROP_FLASH'
export const HAS_LOGOUT_FLASH = 'HAS_LOGOUT_FLASH'
export const HAS_DEACTIVATION_FLASH = 'HAS_DEACTIVATION_FLASH'

//Game Demo
export const GAME_DEMO_DICTIONARY = 'GAME_DEMO_DICTIONARY'
export const UPDATE_GAME_DEMO_DICTIONARY = 'UPDATE_GAME_DEMO_DICTIONARY'
export const IS_GAME_DEMO_PENDING = 'IS_GAME_DEMO_PENDING'
export const SET_IS_GAME_DEMO_PENDING = 'SET_IS_GAME_DEMO_PENDING'

//Game Info module
export const GAME_INFO_DICTIONARY = 'GAME_INFO_DICTIONARY'
export const UPDATE_GAME_INFO_DICITONARY = 'UPDATE_GAME_INFO_DICITONARY'
export const GAME_INFO_BY_SLUG = 'GAME_INFO_BY_SLUG'

//Game Notification module
export const GAME_NOTIFICATION_LIST = 'GAME_NOTIFICATION_LIST'
export const SET_GAME_NOTIFICATION_LIST = 'SET_GAME_NOTIFICATION_LIST'
export const IS_GAME_NOTIFICATION_PENDING = 'IS_GAME_NOTIFICATION_PENDING'
export const SET_IS_GAME_NOTIFICATION_PENDING =
  'SET_IS_GAME_NOTIFICATION_PENDING'

//Game Play module
export const GAME_PLAY_DICTIONARY = 'GAME_PLAY_DICTIONARY'
export const SET_GAME_PLAY_DICTIONARY = 'SET_GAME_PLAY_DICTIONARY'
export const IS_GAME_PLAY_PENDING = 'IS_GAME_PLAY_PENDING'
export const SET_IS_GAME_PLAY_PENDING = 'SET_IS_GAME_PLAY_PENDING'
export const UPDATE_GAME_FAVOURITE_STATUS = 'UPDATE_GAME_FAVOURITE_STATUS'
export const DROP_GAME_PLAY_DICTIONARY = 'DROP_GAME_PLAY_DICTIONARY'
export const IS_GAME_FAV_STATUS_PENDING = 'IS_GAME_FAV_STATUS_PENDING'
export const SET_IS_GAME_FAV_STATUS_PENDING = 'SET_IS_GAME_FAV_STATUS_PENDING'
export const IS_GAME_FAVORITE = 'IS_GAME_FAVORITE'

//Game Vendor module
export const GAME_VENDOR_DICTIONARY = 'GAME_VENDOR_DICTIONARY'
export const SET_GAME_VENDOR_DICTIONARY = 'SET_GAME_VENDOR_DICTIONARY'
export const IS_GAME_VENDOR_PENDING = 'IS_GAME_VENDOR_PENDING'
export const SET_IS_GAME_VENDOR_PENDING = 'SET_IS_GAME_VENDOR_PENDING'

//Limit module
export const LIMIT_DICTIONARY = 'LIMIT_DICTIONARY'
export const SET_LIMIT_DICTIONARY = 'SET_LIMIT_DICTIONARY'
export const IS_LIMIT_PENDING = 'IS_LIMIT_PENDING'
export const SET_IS_LIMIT_PENDING = 'SET_IS_LIMIT_PENDING'
export const DROP_LIMIT = 'DROP_LIMIT_ROUND'
export const LIMIT_ERROR = 'LIMIT_ERROR'
export const SET_LIMIT_ERROR = 'SET_LIMIT_ERROR'

//Location module
export const CURRENT_LOCATION = 'CURRENT_LOCATION'
export const PREVIOUS_LOCATION = 'PREVIOUS_LOCATION'
export const UPDATE_LOCATION = 'UPDATE_LOCATION'
export const IS_LOGIN_PAGE = 'IS_LOGIN_PAGE'
export const SEARCH_QUERY = 'SEARCH_QUERY'

//Main module
export const SHOWS_MAIN_MENU = 'SHOWS_MAIN_MENU'
export const MAIN_MENU_COMPONENT = 'MAIN_MENU_COMPONENTs'
export const TOGGLE_MAIN_MENU = 'TOGGLE_MAIN_MENU'
export const TOGGLE_MAIN_MENU_COMPONENT = 'TOGGLE_MAIN_MENU_COMPONENT'
export const IS_SESSION_CONTROL_PENDING = 'IS_SESSION_CONTROL_PENDING'
export const SET_IS_SESSION_CONTROL_PENDING = 'SET_IS_SESSION_CONTROL_PENDING'
export const IS_PENDING = 'IS_PENDING'
export const SET_IS_PENDING = 'SET_IS_PENDING'
export const SHOWS_FOOTER = 'SHOWS_FOOTER'
export const TOGGLE_FOOTER = 'TOGGLE_FOOTER'
export const NEEDS_TIMER_RESET = 'NEEDS_TIMER_RESET'
export const SET_NEEDS_TIMER_RESET = 'SET_NEEDS_TIMER_RESET'
export const ARE_COOKIES_ACCEPTED = 'ARE_COOKIES_ACCEPTED'
export const SET_ARE_COOKIES_ACCEPTED = 'SET_ARE_COOKIES_ACCEPTED'
export const SHOWS_SEARCH_MENU = 'SHOWS_SEARCH_MENU'
export const TOGGLE_SEARCH_MENU = 'TOGGLE_SEARCH_MENU'
export const SET_SIDE_MENU_GAME = 'SET_SIDE_MENU_GAME'
export const SIDE_MENU_GAME = 'SIDE_MENU_GAME'
export const SHOWS_USER_NOTIFICATION_SIDE_MENU =
  'SHOWS_USER_NOTIFICATION_SIDE_MENU'
export const TOGGLE_USER_NOTIFICATION_SIDE_MENU =
  'TOGGLE_USER_NOTIFICATION_SIDE_MENU'
export const DROP_MENU = 'DROP_MENU'
export const HAS_MENU = 'HAS_MENU'
export const SHOWS_MY_ACCOUNT_SIDE_MENU = 'SHOWS_MY_ACCOUNT_SIDE_MENU'
export const TOGGLE_MY_ACCOUNT_SIDE_MENU = 'TOGGLE_MY_ACCOUNT_SIDE_MENU'
export const TOGGLE_MOBILE_SEARCH_INPUT = 'TOGGLE_MOBILE_SEARCH_INPUT'
export const IS_MOBILE_SEARCH_INPUT_OPEN = 'IS_MOBILE_SEARCH_INPUT_OPEN'
export const TOGGLE_LOGIN_SIDE_MENU = 'TOGGLE_LOGIN_SIDE_MENU'
export const SHOWS_LOGIN_SIDE_MENU = 'SHOWS_LOGIN_SIDE_MENU'
export const TOGGLE_FORGOT_PASSWORD_SIDE_MENU =
  'TOGGLE_FORGOT_PASSWORD_SIDE_MENU'
export const SHOWS_FORGOT_PASSWORD_SIDE_MENU = 'SHOWS_FORGOT_PASSWORD_SIDE_MENU'
export const TOGGLE_REGISTER_SIDE_MENU = 'TOGGLE_REGISTER_SIDE_MENU'
export const SHOWS_REGISTER_SIDE_MENU = 'SHOWS_REGISTER_SIDE_MENU'
export const SHOWS_RESEND_CONFIRM_SIDE_MENU = 'SHOWS_RESEND_CONFIRM_SIDE_MENU'
export const TOGGLE_RESEND_CONFIRM_SIDE_MENU = 'TOGGLE_RESEND_CONFIRM_SIDE_MENU'
export const SHOWS_DESKTOP_NOTIFICATION_MENU = 'SHOWS_DESKTOP_NOTIFICATION_MENU'
export const TOGGLE_DESKTOP_NOTIFICATION_MENU =
  'TOGGLE_DESKTOP_NOTIFICATION_MENU'
export const IS_NAVBAR_AT_TOP = 'IS_NAVBAR_AT_TOP'
export const SET_NAVBAR_AT_TOP = 'SET_NAVBAR_AT_TOP'
export const TOGGLE_PAY_AND_PLAY_SIDE_MENU = 'TOGGLE_PAY_AND_PLAY_SIDE_MENU'
export const SHOWS_PAY_AND_PLAY_SIDE_MENU = 'SHOWS_PAY_AND_PLAY_SIDE_MENU'
export const TOGGLE_SUPPORT_SIDE_MENU = 'TOGGLE_SUPPORT_SIDE_MENU'
export const SHOWS_SUPPORT_SIDE_MENU = 'SHOWS_SUPPORT_SIDE_MENU'
export const TOGGLE_PNP_ACCOUNT_SIDE_MENU = 'TOGGLE_PNP_ACCOUNT_SIDE_MENU'
export const SHOWS_PNP_ACCOUNT_SIDE_MENU = 'SHOWS_PNP_ACCOUNT_SIDE_MENU'

//Modal module
export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const DROP_MODAL = 'DROP_MODAL'
export const TRIGGER_MODAL_REMOVAL = 'TRIGGER_MODAL_REMOVAL'
export const MODAL_LIST = 'MODAL_LIST'
export const IS_MODAL_REMOVAL_PENDING = 'IS_MODAL_REMOVAL_PENDING'
export const CURRENT_MODAL = 'CURRENT_MODAL'

//Newsletter module
export const NEWSLETTER_DICTIONARY = 'NEWSLETTER_DICTIONARY'
export const SET_NEWSLETTER_DICTIONARY = 'SET_NEWSLETTER_DICTIONARY'
export const IS_NEWSLETTER_DICTIONARY_PENDING =
  'IS_NEWSLETTER_DICTIONARY_PENDING'
export const SET_IS_NEWSLETTER_DICTIONARY_PENDING =
  'SET_IS_NEWSLETTER_DICTIONARY_PENDING'
export const DROP_NEWSLETTER = 'DROP_NEWSLETTER'

//Offer module
export const OFFER_DICTIONARY = 'OFFER_DICTIONARY'
export const SET_OFFER_DICTIONARY = 'SET_OFFER_DICTIONARY'
export const IS_OFFER_PENDING = 'IS_OFFER_PENDING'
export const SET_IS_OFFER_PENDING = 'SET_IS_OFFER_PENDING'
export const RESET_OFFER = 'RESET_OFFER'

//Recent Game module
export const RECENT_GAME_DICTIONARY = 'RECENT_GAME_DICTIONARY'
export const UPDATE_RECENT_GAME_DICTIONARY = 'UPDATE_RECENT_GAME_DICTIONARY'
export const IS_RECENT_GAME_LIST_PENDING = 'IS_RECENT_GAME_LIST_PENDING'
export const SET_IS_RECENT_GAME_LIST_PENDING = 'SET_IS_RECENT_GAME_LIST_PENDING'
export const RECENT_GAME_TOTAL = 'RECENT_GAME_TOTAL'
export const RECENT_GAME_DICTIONARY_BY_ID = 'RECENT_GAME_DICTIONARY_BY_ID'
export const RECENT_GAME_STATIC_PENDING_LIST = 'RECENT_GAME_STATIC_PENDING_LIST'
export const RESET_RECENT_GAME_DICTIONARY = 'RESET_RECENT_GAME_DICTIONARY'

//Search Result module
export const SEARCH_RESULT_DICTIONARY = 'SEARCH_RESULT_DICTIONARY'
export const UPDATE_SEARCH_RESULT_DICTIONARY = 'UPDATE_SEARCH_RESULT_DICTIONARY'
export const IS_SEARCH_RESULT_PENDING = 'IS_SEARCH_RESULT_PENDING'
export const SET_IS_SEARCH_RESULT_PENDING = 'SET_IS_SEARCH_RESULT_PENDING'
export const RESET_SEARCH_RESULT = 'RESET_SEARCH_RESULT'
export const SEARCH_RESULT_TOTAL = 'SEARCH_RESULT_TOTAL'
export const SEARCH_RESULT_SHORT_LIST = 'SEARCH_RESULT_SHORT_LIST'

//Slide module
export const SLIDE_LIST = 'SLIDE_LIST'
export const SET_SLIDE_LIST = 'SET_SLIDE_LIST'
export const REWARD_SLIDE_LIST = 'REWARD_SLIDE_LIST'
export const SET_REWARD_SLIDE_LIST = 'SET_REWARD_SLIDE_LIST'

//User
export const PERSONAL_INFO = 'PERSONAL_INFO'
export const UPDATE_PERSONAL_INFO = 'UPDATE_PERSONAL_INFO'
export const IS_PERSONAL_INFO_PENDING = 'IS_PERSONAL_INFO_PENDING'
export const SET_IS_PERSONAL_INFO_PENDING = 'SET_IS_PERSONAL_INFO_PENDING'
export const DROP_PERSONAL_INFO = 'DROP_PERSONAL_INFO'
export const IS_LOGGED = 'IS_LOGGED'
export const LACKS_INFO = 'LACKS_INFO'
export const USER_EMAIL = 'USER_EMAIL'
export const SET_USER_EMAIL = 'SET_USER_EMAIL'
export const IS_EMPTY_USER_ADDRESS = 'IS_EMPTY_USER_ADDRESS'
export const MUST_CHANGE_PASSWORD = 'MUST_CHANGE_PASSWORD'
export const SET_MUST_CHANGE_PASSWORD = 'SET_MUST_CHANGE_PASSWORD'
export const HAS_DEPOSIT = 'HAS_DEPOSIT'
export const SET_HAS_DEPOSIT = 'SET_HAS_DEPOSIT'

//User Notification module
export const USER_NOTIFICATION_LIST = 'USER_NOTIFICATION_LIST'
export const UPDATE_USER_NOTIFICATION_LIST = 'UPDATE_USER_NOTIFICATION_LIST'
export const IS_USER_NOTIFICATION_LIST_PENDING =
  'IS_USER_NOTIFICATION_LIST_PENDING'
export const SET_IS_USER_NOTIFICATION_LIST_PENDING =
  'SET_IS_USER_NOTIFICATION_LIST_PENDING'
export const DROP_USER_NOTIFICATION = 'DROP_USER_NOTIFICATION'

//Vendor module
export const VENDOR_DICTIONARY = 'VENDOR_DICTIONARY'
export const UPDATE_VENDOR_DICTIONARY = 'UPDATE_VENDOR_DICTIONARY'
export const IS_VENDOR_LIST_PENDING = 'IS_VENDOR_LIST_PENDING'
export const SET_IS_VENDOR_LIST_PENDING = 'SET_IS_VENDOR_LIST_PENDING'
export const VENDOR_TOTAL = 'VENDOR_TOTAL'
export const VENDOR_DICTIONARY_BY_ID = 'VENDOR_DICTIONARY_BY_ID'
export const VENDOR_STATIC_PENDING_LIST = 'VENDOR_STATIC_PENDING_LIST'
export const RESET_VENDOR_DICTIONARY = 'RESET_VENDOR_DICTIONARY'

//Withdrawal Method module
export const WITHDRAWAL_METHOD_DICTIONARY = 'WITHDRAWAL_METHOD_DICTIONARY'
export const SET_WITHDRAWAL_METHOD_DICTIONARY =
  'SET_WITHDRAWAL_METHOD_DICTIONARY'
export const IS_WITHDRAWAL_METHOD_PENDING = 'IS_WITHDRAWAL_METHOD_PENDING'
export const SET_IS_WITHDRAWAL_METHOD_PENDING =
  'SET_IS_WITHDRAWAL_METHOD_PENDING'
export const DROP_WITHDRAWAL_METHOD = 'DROP_WITHDRAWAL_METHOD'

//Pagination (cannot be used by mapState since the names are not unique for modules)
export const LAST_LOADED_PAGE = 'lastLoadedPage'
export const PAGE_TOTAL = 'pageTotal'
export const HAS_MORE_PAGES = 'hasMorePages'
export const ITEMS_PER_PAGE = 'itemsPerPage'

//Non-unique game category property
export const IS_VERTICAL = 'isVertical'
