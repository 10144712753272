import { api } from './axios'
import { Endpoint, Digit } from '../constants'
import { ApiService } from './apiService'

export class MainApi extends ApiService {
  static getCountryByIP({ ip }) {
    return api.get(Endpoint.API_OPEN_MAIN_DEFAULT_COUNTRY_FROM_IP_LOCATION, {
      params: { ip },
    })
  }
  static register(data) {
    return api.post(Endpoint.API_OPEN_MAIN_REGISTRATION, data)
  }
  static getCountryList() {
    const locale = window.navigator.language.slice(Digit.NOUGHT, Digit.TWO)

    return api.get(Endpoint.API_OPEN_MAIN_LIST_OF_COUNTRIES, {
      params: { locale },
    })
  }
  static getGameFilters() {
    return api.get(Endpoint.API_OPEN_MAIN_GAME_FILTERS)
  }
  static getSlideShow() {
    return api.get(Endpoint.API_OPEN_MAIN_SLIDE_SHOW)
  }
  static getSearchSidebar() {
    return api.get(Endpoint.API_OPEN_MAIN_SEARCH_SIDEBAR)
  }
  static getMgaVerification() {
    return api.get(Endpoint.API_OPEN_MAIN_MGA_VERIFICATION)
  }
  static getPaymentProviders() {
    return api.get(Endpoint.API_OPEN_MAIN_PAYMENT_PROVIDERS)
  }
  static getCmsTree({ type }) {
    return api.get(super.formatUrl(Endpoint.API_OPEN_CMS_TREE, { type }))
  }
  static getCmsPageDetails({ urlSlug }) {
    return api.get(
      super.formatUrl(Endpoint.API_OPEN_CMS_PAGE_DETAILS, { urlSlug }),
    )
  }
}
