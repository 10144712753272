import { isPlainObject } from "../object/isPlainObject";
import { isArray } from "./isArray";

/**
 * @param {any} value
 * @returns {boolean} returns true is the value is an empty array or array with real objects only.
 */

export const isObjectArray = (value) => {
  return isArray(value) && value.every(isPlainObject);
};

export const isNotObjectArray = (value) => !isObjectArray(value);
