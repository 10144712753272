import { RouteName, RoutePath } from '@/constants'
import { Resolver } from '@/router/resolver'

const ROUTER_REVIEW = 'router-view'

export const routes = [
  {
    path: RoutePath.LOCALE,
    component: {
      render: (h) => h(ROUTER_REVIEW),
    },
    children: [
      {
        path: RoutePath.HOMEPAGE,
        name: RouteName.HOME,
        component: () => import('../views/Home'),
        beforeEnter: Resolver.Home,
      },
      {
        path: RoutePath.BACKOFFICE_SUPPORT_ACCESS,
        name: RouteName.BACKOFFICE_SUPPORT_ACCESS,
        component: () => import('../views/BackofficeSupportAccess'),
      },
      {
        path: RoutePath.DASHBOARD,
        name: RouteName.DASHBOARD,
        component: () => import('../views/Dashboard'),
        beforeEnter: Resolver.Dashboard,
      },
      {
        path: RoutePath.DEPOSIT,
        name: RouteName.DEPOSIT,
        component: () => import('../views/Deposit'),
        beforeEnter: Resolver.Deposit,
      },
      {
        path: RoutePath.FORGOT_YOUR_PASSWORD,
        name: RouteName.FORGOT_PASSWORD,
        component: () => import('../views/ForgotPassword'),
        beforeEnter: Resolver.ForgotPassword,
      },
      {
        path: RoutePath.Game.ROOT, //it's game list
        name: RouteName.GAME,
        component: () => import('../views/Game'),
        beforeEnter: Resolver.Game,
      },
      {
        path: RoutePath.Game.CATEGORY, //has slug
        name: RouteName.GAME_CATEGORY,
        component: () => import('../views/GameCategory'),
      },
      {
        path: RoutePath.Game.DEMO, //has slug
        name: RouteName.GAME_DEMO,
        component: () => import('../views/GameDemo'),
        beforeEnter: Resolver.GameDemo,
      },
      {
        path: RoutePath.Game.FREE_PLAY,
        name: RouteName.GAME_FREE_PLAY,
        component: () => import('../views/FreePlay'),
      },
      {
        path: RoutePath.Game.INFO,
        name: RouteName.GAME_INFO,
        component: () => import('../views/GameInfo'),
        beforeEnter: Resolver.GameInfo,
      },
      {
        path: RoutePath.Game.LISTS, //it's game list item and has :slug
        name: RouteName.GAME_LISTS,
        component: () => import('../views/GameLists'),
        beforeEnter: Resolver.GameLists,
      },
      {
        path: RoutePath.Game.NEW_GAMES,
        name: RouteName.GAME_NEW_GAMES,
        component: () => import('../views/NewGames'),
      },
      {
        path: RoutePath.Game.PLAY, //has slug
        name: RouteName.GAME_PLAY,
        component: () => import('../views/GamePlay'),
        beforeEnter: Resolver.GamePlay,
      },
      // {
      //   path: RoutePath.JACKPOTS,
      //   name: RouteName.JACKPOTS,
      //   component: () => import('../views/Jackpots'),
      // },
      {
        path: RoutePath.SEARCH_RESULT,
        name: RouteName.SEARCH_RESULT,
        component: () => import('../views/SearchResult'),
      },
      {
        path: RoutePath.Game.TOP_GAMES,
        name: RouteName.GAME_TOP_GAMES,
        component: () => import('../views/TopGames'),
      },
      {
        path: RoutePath.Game.VENDOR, //has slug
        name: RouteName.GAME_VENDOR,
        component: () => import('../views/GameVendor'),
        beforeEnter: Resolver.GameVendor,
      },
      // {
      //   path: RoutePath.LIVE_CASINO,
      //   name: RouteName.LIVE_CASINO,
      //   component: () => import('../views/LiveCasino'),
      // },
      {
        path: RoutePath.LOGIN,
        name: RouteName.LOGIN,
        component: () => import('../views/Login'),
        beforeEnter: Resolver.Login,
      },
      {
        path: RoutePath.OFFERS,
        name: RouteName.OFFERS,
        component: () => import('../views/Promotions'),
        beforeEnter: Resolver.Offers,
      },
      {
        path: RoutePath.OFFERS_VIEW,
        name: RouteName.OFFERS_VIEW,
        component: () => import('../views/PromotionView'),
      },
      {
        path: RoutePath.PLATINCLUB,
        name: RouteName.PLATINCLUB,
        component: () => import('../views/Platinclub'),
        beforeEnter: Resolver.Platinclub,
      },
      {
        path: RoutePath.PNP_ACCOUNT,
        name: RouteName.PNP_ACCOUNT,
        component: () => import('../views/PnpAccount'),
        beforeEnter: Resolver.PnpAccount,
      },
      {
        path: RoutePath.Registration.ROOT,
        name: RouteName.REGISTRATION,
        component: () => import('../views/Registration'),
        beforeEnter: Resolver.Registration,
      },
      {
        path: RoutePath.Registration.CONFIRM,
        name: RouteName.REGISTRAION_CONFIRM,
        component: () => import('../views/RegistrationConfirm'),
        beforeEnter: Resolver.RegistrationConfirm,
      },
      {
        path: RoutePath.Registration.SUCCESS,
        name: RouteName.REGISTRATION_SUCCESS,
        component: () => import('../views/RegistrationSuccess'),
      },
      {
        path: RoutePath.RESEND_YOUR_CONFIRMATION_EMAIL,
        name: RouteName.RESEND_CONFIRM_MAIL,
        component: () => import('../views/ResendConfirmMail'),
        beforeEnter: Resolver.ResendConfirmationEmail,
      },
      {
        path: RoutePath.NEED_HELP,
        name: RouteName.NEED_HELP,
        component: () => import('../views/NeedHelp'),
      },
      {
        path: RoutePath.REWARD_CARNIVAL,
        name: RouteName.REWARD_CARNIVAL,
        component: () => import('../views/RewardCarnival'),
        beforeEnter: Resolver.RewardsCarnival,
      },
      // {
      //   path: RoutePath.SLOTS,
      //   name: RouteName.SLOTS,
      //   component: () => import('../views/Slots'),
      // },
      {
        path: RoutePath.SUPPORT,
        name: RouteName.SUPPORT,
        component: () => import('../views/Support'),
      },
      {
        path: RoutePath.User.ROOT,
        name: RouteName.USER,
        component: () => import('../views/User'),
        beforeEnter: Resolver.User,
        children: [
          {
            path: RoutePath.User.ACCOUNT,
            name: RouteName.USER_ACCOUNT,
            component: () => import('../views/UserAccount'),
            beforeEnter: Resolver.UserAccount,
          },
          {
            path: RoutePath.User.ACCOUNT_BALANCE,
            name: RouteName.USER_ACCOUNT_BALANCE,
            component: () => import('../views/ProfileAccountBalance'),
            beforeEnter: Resolver.UserAccountBalance,
          },
          {
            path: RoutePath.User.ACCOUNT_VERIFY,
            name: RouteName.USER_ACCOUNT_VERIFICATION,
            component: () => import('../views/AccountVerification'),
            beforeEnter: Resolver.UserAccountVerify,
          },
          {
            path: RoutePath.User.BONUS_CANCEL,
            name: RouteName.BONUS_CANCEL,
            component: () => import('../views/BonusCancel'),
          },
          {
            path: RoutePath.User.CHANGE_PASSWORD,
            name: RouteName.USER_CHANGE_PASSWORD,
            component: () => import('../views/ChangePassword'),
            beforeEnter: Resolver.UserChangePassword,
          },
          {
            path: RoutePath.User.DEPOSIT_HISTORY,
            name: RouteName.USER_DEPOSIT_HISTORY,
            component: () => import('../views/DepositHistory'),
            beforeEnter: Resolver.DepositHistory,
          },
          {
            path: RoutePath.User.DOCUMENTS,
            name: RouteName.USER_DOCUMENTS,
            component: () => import('../views/UserDocuments'),
            beforeEnter: Resolver.UserDocuments,
          },
          {
            path: RoutePath.User.EDIT,
            name: RouteName.USER_EDIT,
            component: () => import('../views/UserEdit'),
            beforeEnter: Resolver.UserEdit,
          },
          {
            path: RoutePath.User.GAME_ROUNDS,
            name: RouteName.USER_GAME_ROUNDS,
            component: () => import('../views/UserGameRounds'),
            beforeEnter: Resolver.UserGameRounds,
          },
          {
            path: RoutePath.User.LIMITS,
            name: RouteName.USER_LIMITS,
            component: () => import('../views/UserLimits'),
            beforeEnter: Resolver.UserLimits,
          },
          {
            path: RoutePath.User.LIMITS_EDIT,
            name: RouteName.USER_LIMIT_EDIT,
            component: () => import('../views/UserLimitEdit'),
          },
          {
            path: RoutePath.User.NEWSLETTER,
            name: RouteName.USER_NEWSLETTER,
            component: () => import('../views/UserNewsletter'),
            beforeEnter: Resolver.Newsletter,
          },
          {
            path: RoutePath.User.SELF_EXCLUSION,
            name: RouteName.USER_SELF_EXCLUSION,
            component: () => import('../views/UserSelfExclusion'),
          },
          {
            path: RoutePath.User.TRANSACTIONS,
            name: RouteName.USER_TRANSACTIONS,
            component: () => import('../views/UserTransactions'),
            beforeEnter: Resolver.UserTransactions,
          },
          {
            path: RoutePath.User.USER_NOTIFICATIONS,
            name: RouteName.USER_NOTIFICATIONS,
            component: () => import('../views/UserNotifications'),
          },
        ],
      },
      {
        path: RoutePath.USER_REGISTER_PROGRESS,
        name: RouteName.USER_REGISTER_PROGRESS,
        component: () => import('../views/UserRegisterProgress'),
      },
      {
        path: RoutePath.Withdrawal.ROOT,
        name: RouteName.WITHDRAWAL,
        component: () => import('../views/Withdrawal'),
        beforeEnter: Resolver.Withdrawal,
      },
      {
        path: RoutePath.Withdrawal.ADD,
        name: RouteName.WITHDRAWAL_ADD,
        component: () => import('../views/WithdrawalAdd'),
        beforeEnter: Resolver.WithdrawalAdd,
      },
      {
        path: RoutePath.Withdrawal.HISTORY,
        name: RouteName.WITHDRAWAL_HISTORY,
        component: () => import('../views/WithdrawalHistory'),
      },
      {
        path: RoutePath.Withdrawal.STATUS,
        name: RouteName.WITHDRAWAL_STATUS,
        component: () => import('../views/WithdrawalStatus'),
      },
    ],
  },
  {
    path: RoutePath.PAGE_404,
    name: RouteName.PAGE_404,
    component: () => import('../views/Page404'),
  },
]
