export const CSN_INPUT_CLASS = 'csn-input'
export const CSN_INPUT_CLASS_SELECTOR = `.${CSN_INPUT_CLASS}`
export const INPUT_CONTAINER_STYLE_CLASS = 'form-group csn-form-input-container'
export const INPUT_CONTAINER_CLASS = `${INPUT_CONTAINER_STYLE_CLASS} ${CSN_INPUT_CLASS}`

export const MODAL_DIALOG = 'modal-dialog csn-modal-dialog'
export const MODAL_CONTENT = 'modal-content'
export const MODAL_HEADER = 'modal-header'
export const MODAL_TITLE = 'modal-title'
export const MODAL_BODY = 'modal-body'
export const CSN_MODAL_CLOSE_BUTTON = 'csn-modal-close-button'

export const LINE_CLASS = 'line'
export const FOOTER_CLASS = 'footer'
export const BTN_CLASS = 'btn'

export const CARUSEL_WRAPPER = 'carousel-wrapper'
export const CSN_HORIZONTAL_GAME_LIST = 'csn-horizontal-game-list'
export const CSN_HORIZONTAL_GAME_LIST_HIGH = `${CSN_HORIZONTAL_GAME_LIST}-high`
export const CSN_HORIZONTAL_GAME_LIST_BUTTON_LEFT = `${CSN_HORIZONTAL_GAME_LIST}-button-left`
export const CSN_HORIZONTAL_GAME_LIST_BUTTON_RIGHT = `${CSN_HORIZONTAL_GAME_LIST}-button-right`
export const CSN_HORIZONTAL_GAME_LIST_BUTTON_HIDDEN = `${CSN_HORIZONTAL_GAME_LIST}-button-hidden`

export const SLIDER = 'slider'
export const SLIDE_PRE = 'slidePre'
export const SLIDE_NEXT = 'slideNext'
