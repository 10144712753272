/**
 * Checks if the array has only unique primitives values.
 *
 * @param {array} array
 * @returns {boolean} true if array includes only unique values
 */

export const hasUniqueValues = (array) => {
  return array.length === new Set(array).size;
};

export const hasDuplicateValues = (array) => !hasUniqueValues(array);
