import { Digit } from '../constants'

export const setFavicon = () => {
  let link
  link = document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  link.href = 'https://cdn-casino.blackpage.pl/images/favicon/casino-five.svg'
  document.getElementsByTagName('head')[Digit.NOUGHT].appendChild(link)
}
