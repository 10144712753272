import { GameApi } from '@/api'
import {
  SEARCH_RESULT_DICTIONARY,
  UPDATE_SEARCH_RESULT_DICTIONARY,
  IS_SEARCH_RESULT_PENDING,
  SET_IS_SEARCH_RESULT_PENDING,
  ResponseState,
  LAST_LOADED_PAGE,
  PAGE_TOTAL,
  HAS_MORE_PAGES,
  RESET_SEARCH_RESULT,
  Digit,
  EMPTY_OBJECT,
  URL_SLUG,
  SEARCH_RESULT_TOTAL,
  SEARCH_RESULT_SHORT_LIST,
} from '@/constants'
import {
  gameDictionaryInitialValue,
  isGamePendingInitialValue,
  lastLoadedPageInitialValue,
  pageTotalInitialValue,
} from '@/values'
import { indexBy, isNil, isArray, values } from '@/helpers'

export const searchResult = {
  namespaced: true,
  state: {
    [SEARCH_RESULT_DICTIONARY]: gameDictionaryInitialValue,
    [IS_SEARCH_RESULT_PENDING]: isGamePendingInitialValue,
    [LAST_LOADED_PAGE]: lastLoadedPageInitialValue,
    [PAGE_TOTAL]: pageTotalInitialValue,
    [SEARCH_RESULT_TOTAL]: Digit.NOUGHT,
  },
  mutations: {
    [UPDATE_SEARCH_RESULT_DICTIONARY](state, payload) {
      state[SEARCH_RESULT_DICTIONARY] = {
        ...(isNil(state[SEARCH_RESULT_DICTIONARY])
          ? EMPTY_OBJECT
          : state[SEARCH_RESULT_DICTIONARY]),
        ...payload[SEARCH_RESULT_DICTIONARY],
      }
      state[PAGE_TOTAL] = payload[PAGE_TOTAL]
      state[LAST_LOADED_PAGE] = payload[LAST_LOADED_PAGE]
      state[SEARCH_RESULT_TOTAL] = payload[SEARCH_RESULT_TOTAL]
    },
    [SET_IS_SEARCH_RESULT_PENDING](state, value) {
      state[IS_SEARCH_RESULT_PENDING] = value
    },
    [RESET_SEARCH_RESULT](state) {
      state[SEARCH_RESULT_DICTIONARY] = gameDictionaryInitialValue
      state[LAST_LOADED_PAGE] = lastLoadedPageInitialValue
      state[PAGE_TOTAL] = pageTotalInitialValue
      state[SEARCH_RESULT_TOTAL] = Digit.NOUGHT
    },
  },
  actions: {
    async [UPDATE_SEARCH_RESULT_DICTIONARY](
      { commit, state: store, getters },
      { resetsData, ...params } = {},
    ) {
      if (resetsData) {
        commit(RESET_SEARCH_RESULT)
      }
      if (!getters[HAS_MORE_PAGES]) {
        return
      }

      try {
        commit(SET_IS_SEARCH_RESULT_PENDING, true)

        const page = store[LAST_LOADED_PAGE] + Digit.ONE
        const { data, state } = await GameApi.getGameSearch({ ...params, page })

        if (state === ResponseState.OK && isArray(data?.items)) {
          const { items, itemsPerPage, total } = data

          const payload = {
            [SEARCH_RESULT_DICTIONARY]: indexBy(URL_SLUG, items),
            [PAGE_TOTAL]: Math.ceil(total / itemsPerPage),
            [LAST_LOADED_PAGE]: page,
            [SEARCH_RESULT_TOTAL]: total,
          }

          commit(UPDATE_SEARCH_RESULT_DICTIONARY, payload)
          commit(SET_IS_SEARCH_RESULT_PENDING, isGamePendingInitialValue)
        }
      } catch (e) {
        console.dir(e)
      }
    },
  },
  getters: {
    [HAS_MORE_PAGES]: (state) => {
      return state[PAGE_TOTAL] > state[LAST_LOADED_PAGE]
    },
    [SEARCH_RESULT_SHORT_LIST]: (state) => {
      return values(state[SEARCH_RESULT_DICTIONARY]).slice(
        Digit.NOUGHT,
        Digit.FOUR,
      )
    },
  },
}
