import { AuthApi } from '@/api'
import {
  NEWSLETTER_DICTIONARY,
  SET_NEWSLETTER_DICTIONARY,
  IS_NEWSLETTER_DICTIONARY_PENDING,
  SET_IS_NEWSLETTER_DICTIONARY_PENDING,
  DROP_NEWSLETTER,
} from '@/constants'
import { isPlainObject } from '@/helpers'

export const newsletter = {
  namespaced: true,
  state: {
    [NEWSLETTER_DICTIONARY]: {},
    [IS_NEWSLETTER_DICTIONARY_PENDING]: false,
  },
  mutations: {
    [SET_NEWSLETTER_DICTIONARY](state, { dictionary }) {
      state[NEWSLETTER_DICTIONARY] = dictionary
    },
    [SET_IS_NEWSLETTER_DICTIONARY_PENDING](state, { value }) {
      state[IS_NEWSLETTER_DICTIONARY_PENDING] = value
    },
  },
  actions: {
    async [SET_NEWSLETTER_DICTIONARY](context) {
      try {
        context.commit(SET_IS_NEWSLETTER_DICTIONARY_PENDING, { value: true })
        const response = await AuthApi.getUserNewsletter()
        const dictionary = response?.data?.user_newsletter

        if (isPlainObject(dictionary)) {
          context.commit(SET_NEWSLETTER_DICTIONARY, { dictionary })
          context.commit(SET_IS_NEWSLETTER_DICTIONARY_PENDING, { value: false })
        }
      } catch (e) {
        console.dir(e)
      }
    },
    [DROP_NEWSLETTER](context) {
      context.commit(SET_NEWSLETTER_DICTIONARY, { dictionary: {} })
    },
  },
}
