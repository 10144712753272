import { Type } from '../../../constants'

/**
 * @param {any} value
 * @returns {boolean} true in case the value is number
 **/

export const isNumber = (value) => {
  return typeof value === Type.NUMBER
}

export const isNotNumber = (value) => !isNumber(value)
