import { Endpoint } from '../constants'
import axios from 'axios'

const JSON_CONST = 'json'
export class ExternalApi {
  static async getIP() {
    const { data } = await axios.get(Endpoint.IP, {
      params: { format: JSON_CONST },
    })

    return data
  }
}
