import { AuthApi } from '@/api'
import { getGetter } from './store/getGetter'
import { Module, IS_LOGGED } from '@/constants'
import router from '@/router'

export const stopGameSession = () => {
  const isLogged = getGetter([Module.USER, IS_LOGGED])
  const urlSlug = router.history.current.params.slug

  if (isLogged) {
    AuthApi.stopGame({ urlSlug })
  }
}
