import {
  Type,
  InputType,
  EventType,
  EMPTY_STRING,
  EMPTY_ARRAY,
} from '@/constants'
import { generateUUID, isNotNil, head } from '@/helpers'
import { Validator } from '@/form/validator'

const INPUT_ERROR_CLASS = 'csn-input-error'
const REQUIRED = 'required'

export const inputMixin = {
  data: function () {
    return {
      innerValue: null,
      innerValidation: [],
      isReady: false,
    }
  },
  props: {
    placeholder: String,
    disabled: Boolean,
    validators: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: EMPTY_STRING,
    },
    required: {
      type: String,
      default: EMPTY_STRING,
    },
    name: {
      type: String,
      default: EMPTY_STRING,
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: EMPTY_STRING,
    },
    serverError: String,
    containerClass: {
      type: String,
      default: EMPTY_STRING,
    },
    inputClass: {
      type: String,
      default: EMPTY_STRING,
    },
    hidden: Boolean,
    labelClass: {
      type: String,
      default: EMPTY_STRING,
    },
  },
  computed: {
    id: () => `${generateUUID()}_input`,
    inputType: () => InputType,
    isValid() {
      return (
        this.innerValidation.every((item) => item.check) && !this.serverError
      )
    },
    messages() {
      const array = []
      this.innerValidation.forEach(
        (item) => item.message && array.push(item.message),
      )

      return array
    },
    message() {
      return this.serverError || head(this.messages)
    },
    inputClassOnError() {
      return this.message ? INPUT_ERROR_CLASS : EMPTY_STRING
    },
    //Required class is not used 
    derivedLabelClass() {
      return this.validators.some(
        (element) =>
          element === Validator.required || element === Validator.checked,
      )
        ? [REQUIRED, this.labelClass]
        : this.labelClass
    },
  },
  watch: {
    value: {
      handler() {
        this.handleInput(this.value)
        if (isNotNil(this.value) && this.isReady) {
          this.handleValidation()
        }
      },
      immediate: true,
    },
    validators() {
      if (this.isReady) {
        this.handleValidation()
      }
    },
  },
  methods: {
    makeReady() {
      if (!this.isReady) {
        this.isReady = true
      }
    },
    handleInput(value) {
      this.innerValue = value
      this.$emit(EventType.INPUT, value)

      if (this.isReady) {
        this.handleValidation()
      }
    },
    handleFocus() {
      this.makeReady()
      this.$emit(EventType.FOCUS)
    },
    handleBlur(value) {
      this.makeReady()
      this.handleValidation()
      this.$emit(EventType.BLUR, value)
    },
    handleValidation() {
      this.innerValidation = []

      this.validators.forEach((invokeValidator) => {
        const result = invokeValidator(this.innerValue)
        const message = typeof result === Type.STRING ? result : null
        this.innerValidation.push({
          check: typeof result === Type.BOOLEAN && result,
          message,
        })
      })
    },
    validateFormField() {
      this.handleValidation()
      this.makeReady()

      return {
        isValid: this.isValid,
        messages: [
          ...this.messages,
          ...(this.serverError ? [this.serverError] : EMPTY_ARRAY),
        ],
      }
    },
  },
}
