import { EMPTY_OBJECT, EMPTY_ARRAY } from '@/constants/basic'
import { Digit } from '@/constants/digit'

export const gameDictionaryInitialValue = EMPTY_OBJECT
export const gameCompoundDictionaryInitialValue = EMPTY_OBJECT
export const isGamePendingInitialValue = false
export const gameTotalInitialValue = Digit.NOUGHT
export const lastLoadedPageInitialValue = Digit.NOUGHT
export const pageTotalInitialValue = Digit.ONE
export const itemsPerPageInitialValue = Digit.ONE
export const staticPendingListInitialValue = EMPTY_ARRAY
