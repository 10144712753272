import { api } from './axios'
import { Endpoint } from '../constants'

export class LoginApi {
  static loginSession(data) {
    return api.post(Endpoint.API_LOGIN, data)
  }
  static logoutSession() {
    return api.get(Endpoint.API_LOGOUT)
  }
}
