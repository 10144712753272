import { api } from './axios'
import { Endpoint } from '../constants'
import { ApiService } from './apiService'

export class PayAndPlayApi extends ApiService {
  static deposit(data) {
    return api.post(Endpoint.API_OPEN_PAY_N_PLAY_DEPOSIT, data)
  }
  static patchSessionDetails(data) {
    return api.patch(Endpoint.API_AUTH_PAY_AND_PLAY_SESSION_DETAILS, data)
  }
}
