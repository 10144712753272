import {
  Module,
  LOGIN_MODAL,
  SHOW_MODAL,
  Digit,
  IS_PENDING,
  SET_IS_PENDING,
  REGISTER_MODAL,
} from '@/constants'
import { commitMutation, getState } from '@/helpers'

export const openLoginModal = () => {
  window.scrollTo(Digit.NOUGHT, Digit.NOUGHT)

  commitMutation([Module.MODAL, SHOW_MODAL], {
    component: LOGIN_MODAL,
    isStrict: true,
  })

  const isPending = getState([Module.MAIN, IS_PENDING])
  isPending && commitMutation([Module.MAIN, SET_IS_PENDING], false)
}

export const openRegisterModal = () => {
  window.scrollTo(Digit.NOUGHT, Digit.NOUGHT)

  commitMutation([Module.MODAL, SHOW_MODAL], {
    component: REGISTER_MODAL,
    isStrict: true,
  })

  const isPending = getState([Module.MAIN, IS_PENDING])
  isPending && commitMutation([Module.MAIN, SET_IS_PENDING], false)
}
