import { AuthApi } from '@/api'
import {
  CASINO_INFO,
  UPDATE_CASINO_INFO,
  IS_CASINO_INFO_PENDING,
  SET_IS_CASINO_INFO_PENDING,
  DROP_CASINO_INFO,
} from '@/constants'
import { pipe, when, isPlainObject } from '@/helpers'
import { CasinoInfoModel } from '@/models/CasinoInfo'

export const casinoInfo = {
  namespaced: true,
  state: {
    [CASINO_INFO]: {},
    [IS_CASINO_INFO_PENDING]: false,
  },
  mutations: {
    [UPDATE_CASINO_INFO](state, value) {
      state[CASINO_INFO] = value
    },
    [SET_IS_CASINO_INFO_PENDING](state, value) {
      state[IS_CASINO_INFO_PENDING] = value
    },
  },
  actions: {
    async [UPDATE_CASINO_INFO](context) {
      try {
        context.commit(SET_IS_CASINO_INFO_PENDING, { value: true })

        const { data } = await AuthApi.getUserCasinoInfo({
          withCredentials: true,
        })

        if (data) {
          pipe(
            CasinoInfoModel,
            when(isPlainObject, (value) => {
              context.commit(UPDATE_CASINO_INFO, value)
              context.commit(SET_IS_CASINO_INFO_PENDING, { value: false })
            }),
          )(data)
        }
      } catch (e) {
        console.dir(e)
      }
    },
    [DROP_CASINO_INFO](context) {
      console.log('drop casino info');
      context.commit(UPDATE_CASINO_INFO, {})
    },
  },
}
