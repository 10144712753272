import { api } from './axios'
import { Endpoint } from '../constants'

export class UserApi {
  static checkEmailStatus(data) {
    return api.post(Endpoint.API_OPEN_USER_VALID_EMAIL, data)
  }
  static resendConfirmationEmail(data) {
    return api.post(Endpoint.API_OPEN_USER_RESEND_CONFIRMATION_EMAIL, data)
  }
  static login(data) {
    return api.post(Endpoint.API_OPEN_USER_LOGIN, data)
  }
  static postTokenVerification(data) {
    return api.patch(
      Endpoint.API_OPEN_USER_REGISTRATION_TOKEN_VERIFICATION,
      null,
      { params: data },
    )
  }
  static postForgetPassword(data) {
    return api.post(Endpoint.API_OPEN_USER_FORGER_PASSWORD, data)
  }
}
