import { Digit } from '@/constants'

const ErrorMessage = {
  ALREADY_RUNNING: 'Timer is already running',
  ALREADY_STOPPED: 'Timer is already stopped',
}

export class Timer {
  constructor() {
    this.isRunning = false
    this.startTime = Digit.NOUGHT
    this.overallTime = Digit.NOUGHT
  }

  _getTimeElapsedSinceLastStart() {
    if (!this.startTime) {
      return Digit.NOUGHT
    }

    return Date.now() - this.startTime
  }

  start() {
    if (this.isRunning) {
      return console.error(ErrorMessage.ALREADY_RUNNING)
    }

    this.isRunning = true
    this.startTime = Date.now()
  }

  stop() {
    if (!this.isRunning) {
      return console.error(ErrorMessage.ALREADY_STOPPED)
    }

    this.isRunning = false
    this.overallTime = this.overallTime + this._getTimeElapsedSinceLastStart()
  }

  reset() {
    this.overallTime = Digit.NOUGHT

    if (this.isRunning) {
      this.startTime = Date.now()
      return
    }

    this.startTime = Digit.NOUGHT
  }

  getTime() {
    if (!this.startTime) {
      return Digit.NOUGHT
    }

    if (this.isRunning) {
      return this.overallTime + this._getTimeElapsedSinceLastStart()
    }

    return this.overallTime
  }
}
