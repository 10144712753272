import { isPlainObject } from "../object/isPlainObject";
import { callFn } from "../../callFn";
import { isArray } from "./isArray";
import { curry } from "../basic/curry";

/**
 * @param {array | object} functor
 * Takes a function and a functor, applies the function to each of the functor's values,
 * @returns a functor of the same shape.
 *
 * const double = x => x * 2;
 * map(double, [1, 2, 3]); //=> [2, 4, 6]
 * map(double, {x: 1, y: 2, z: 3}); //=> {x: 2, y: 4, z: 6}
 *
 * The function is curried.
 */

const _map = (fn, functor) => {
  if (isArray(functor)) {
    return functor.map((item) => {
      return callFn(fn, item);
    });
  }

  if (isPlainObject(functor)) {
    const returnedObject = {};

    for (const property in functor) {
      returnedObject[property] = callFn(fn, functor[property]);
    }

    return returnedObject;
  }

  throw new Error('wrong map functor');
};

export const map = curry(_map);
