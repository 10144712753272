import { getGetter, navigateTo } from '@/helpers'
import { Module, RouteName, IS_LOGGED } from '@/constants'

export class Protector {
  static protectByLogin({ to }) {
    const isLogged = getGetter([Module.USER, IS_LOGGED])

    if (!isLogged) {
      navigateTo({
        name: RouteName.LOGIN,
        query: { redirect_uri: to.fullPath, redirect_name: to.name },
      })
    }
  }
}
