import {
  SHOWS_MAIN_MENU,
  TOGGLE_MAIN_MENU,
  MAIN_MENU_COMPONENT,
  TOGGLE_MAIN_MENU_COMPONENT,
  MAIN_MENU,
  IS_SESSION_CONTROL_PENDING,
  SET_IS_SESSION_CONTROL_PENDING,
  IS_PENDING,
  SET_IS_PENDING,
  SHOWS_FOOTER,
  TOGGLE_FOOTER,
  NEEDS_TIMER_RESET,
  SET_NEEDS_TIMER_RESET,
  ARE_COOKIES_ACCEPTED,
  SET_ARE_COOKIES_ACCEPTED,
  SHOWS_SEARCH_MENU,
  TOGGLE_SEARCH_MENU,
  SIDE_MENU_GAME,
  SET_SIDE_MENU_GAME,
  SHOWS_USER_NOTIFICATION_SIDE_MENU,
  TOGGLE_USER_NOTIFICATION_SIDE_MENU,
  DROP_MENU,
  HAS_MENU,
  SHOWS_MY_ACCOUNT_SIDE_MENU,
  TOGGLE_MY_ACCOUNT_SIDE_MENU,
  TOGGLE_MOBILE_SEARCH_INPUT,
  IS_MOBILE_SEARCH_INPUT_OPEN,
  TOGGLE_LOGIN_SIDE_MENU,
  SHOWS_LOGIN_SIDE_MENU,
  SHOWS_FORGOT_PASSWORD_SIDE_MENU,
  TOGGLE_FORGOT_PASSWORD_SIDE_MENU,
  TOGGLE_REGISTER_SIDE_MENU,
  SHOWS_REGISTER_SIDE_MENU,
  TOGGLE_RESEND_CONFIRM_SIDE_MENU,
  SHOWS_RESEND_CONFIRM_SIDE_MENU,
  TOGGLE_DESKTOP_NOTIFICATION_MENU,
  SHOWS_DESKTOP_NOTIFICATION_MENU,
  IS_NAVBAR_AT_TOP,
  SET_NAVBAR_AT_TOP,
  SHOWS_PAY_AND_PLAY_SIDE_MENU,
  TOGGLE_PAY_AND_PLAY_SIDE_MENU,
  SHOWS_SUPPORT_SIDE_MENU,
  TOGGLE_SUPPORT_SIDE_MENU,
  TOGGLE_PNP_ACCOUNT_SIDE_MENU,
  SHOWS_PNP_ACCOUNT_SIDE_MENU,
} from '@/constants'

// TODO: separate module for togglers?

export const main = {
  namespaced: true,
  state: {
    [SHOWS_MAIN_MENU]: false,
    [MAIN_MENU_COMPONENT]: MAIN_MENU,
    [IS_SESSION_CONTROL_PENDING]: false,
    [IS_PENDING]: false,
    [SHOWS_FOOTER]: true,
    [NEEDS_TIMER_RESET]: false,
    [ARE_COOKIES_ACCEPTED]: false,
    [SHOWS_SEARCH_MENU]: false,
    [SHOWS_MY_ACCOUNT_SIDE_MENU]: false,
    [SIDE_MENU_GAME]: null,
    [SHOWS_USER_NOTIFICATION_SIDE_MENU]: false,
    [IS_MOBILE_SEARCH_INPUT_OPEN]: null,
    [SHOWS_LOGIN_SIDE_MENU]: false,
    [SHOWS_FORGOT_PASSWORD_SIDE_MENU]: false,
    [SHOWS_REGISTER_SIDE_MENU]: false,
    [SHOWS_RESEND_CONFIRM_SIDE_MENU]: false,
    [SHOWS_DESKTOP_NOTIFICATION_MENU]: false,
    [IS_NAVBAR_AT_TOP]: false,
    [SHOWS_PAY_AND_PLAY_SIDE_MENU]: false,
    [SHOWS_SUPPORT_SIDE_MENU]: false,
    [SHOWS_PNP_ACCOUNT_SIDE_MENU]: false,
  },
  mutations: {
    [TOGGLE_MAIN_MENU](state) {
      state[SHOWS_MAIN_MENU] = !state[SHOWS_MAIN_MENU]
    },
    [TOGGLE_MAIN_MENU_COMPONENT](state, { value }) {
      state[MAIN_MENU_COMPONENT] = value
    },
    [SET_IS_SESSION_CONTROL_PENDING](state, value) {
      state[IS_SESSION_CONTROL_PENDING] = value
    },
    [SET_IS_PENDING](state, value) {
      state[IS_PENDING] = value
    },
    [TOGGLE_FOOTER](state, value) {
      state[SHOWS_FOOTER] = value
    },
    [SET_NEEDS_TIMER_RESET](state, value) {
      state[NEEDS_TIMER_RESET] = value
    },
    [SET_ARE_COOKIES_ACCEPTED](state, value) {
      state[ARE_COOKIES_ACCEPTED] = value
    },
    [TOGGLE_SEARCH_MENU](state, value) {
      state[SHOWS_SEARCH_MENU] = value
    },
    [SET_SIDE_MENU_GAME](state, value) {
      state[SIDE_MENU_GAME] = value
    },
    [TOGGLE_USER_NOTIFICATION_SIDE_MENU](state, value) {
      state[SHOWS_USER_NOTIFICATION_SIDE_MENU] = value
    },
    [TOGGLE_MY_ACCOUNT_SIDE_MENU](state, value) {
      state[SHOWS_MY_ACCOUNT_SIDE_MENU] = value
    },
    [DROP_MENU](state) {
      state[SHOWS_MAIN_MENU] && (state[SHOWS_MAIN_MENU] = false)
      state[SIDE_MENU_GAME] && (state[SIDE_MENU_GAME] = false)
      state[SHOWS_SEARCH_MENU] && (state[SHOWS_SEARCH_MENU] = false)
      state[SHOWS_USER_NOTIFICATION_SIDE_MENU] &&
        (state[SHOWS_USER_NOTIFICATION_SIDE_MENU] = false)
      state[SHOWS_MY_ACCOUNT_SIDE_MENU] &&
        (state[SHOWS_MY_ACCOUNT_SIDE_MENU] = false)
      state[SHOWS_SUPPORT_SIDE_MENU] && (state[SHOWS_SUPPORT_SIDE_MENU] = false)
    },
    [TOGGLE_MOBILE_SEARCH_INPUT](state, value) {
      state[IS_MOBILE_SEARCH_INPUT_OPEN] = value
    },
    [TOGGLE_LOGIN_SIDE_MENU](state, value) {
      state[SHOWS_LOGIN_SIDE_MENU] = value
    },
    [TOGGLE_FORGOT_PASSWORD_SIDE_MENU](state, value) {
      state[SHOWS_FORGOT_PASSWORD_SIDE_MENU] = value
    },
    [TOGGLE_REGISTER_SIDE_MENU](state, value) {
      state[SHOWS_REGISTER_SIDE_MENU] = value
    },
    [TOGGLE_RESEND_CONFIRM_SIDE_MENU](state, value) {
      state[SHOWS_RESEND_CONFIRM_SIDE_MENU] = value
    },
    [TOGGLE_DESKTOP_NOTIFICATION_MENU](state, value) {
      state[SHOWS_DESKTOP_NOTIFICATION_MENU] = value
    },
    [SET_NAVBAR_AT_TOP](state, value) {
      state[IS_NAVBAR_AT_TOP] = value
    },
    [TOGGLE_PAY_AND_PLAY_SIDE_MENU](state, value) {
      state[SHOWS_PAY_AND_PLAY_SIDE_MENU] = value
    },
    [TOGGLE_SUPPORT_SIDE_MENU](state, value) {
      state[SHOWS_SUPPORT_SIDE_MENU] = value
    },
    [TOGGLE_PNP_ACCOUNT_SIDE_MENU](state, value) {
      state[SHOWS_PNP_ACCOUNT_SIDE_MENU] = value
    },
  },
  getters: {
    [HAS_MENU](state) {
      return (
        state[SHOWS_MAIN_MENU] ||
        state[SIDE_MENU_GAME] ||
        state[SHOWS_SEARCH_MENU] ||
        state[SHOWS_USER_NOTIFICATION_SIDE_MENU] ||
        state[SHOWS_MY_ACCOUNT_SIDE_MENU] ||
        state[SHOWS_LOGIN_SIDE_MENU] ||
        state[SHOWS_FORGOT_PASSWORD_SIDE_MENU] ||
        state[SHOWS_REGISTER_SIDE_MENU] ||
        state[SHOWS_RESEND_CONFIRM_SIDE_MENU] ||
        state[SHOWS_PAY_AND_PLAY_SIDE_MENU] ||
        state[SHOWS_SUPPORT_SIDE_MENU] ||
        state[SHOWS_PNP_ACCOUNT_SIDE_MENU]
      )
    },
  },
}
