import { InputType, MessageTypeEnum, ResponseState } from '@/constants'
import { generateUUID, isPlainObject } from '@/helpers'
import { Validator } from '@/form/validator'

export const formMixin = {
  data: () => ({
    serverError: {},
    formDataSubmitCopy: {},
    isSubmitting: false,
    message: null,
    messageType: null, // NOTE: current Message type to render
  }),
  computed: {
    formRef: () => `form_${generateUUID()}_ref`,
    inputType: () => InputType,
    messageTypeEnum: () => MessageTypeEnum,
    responseState: () => ResponseState,
    validator: () => Validator,
    form_t() {
      return this.$createComponentTranslator('formMixin')
    },
  },
  methods: {
    validateResponse(response) {
      if (isPlainObject(response)) {
        return true
      }

      this.message = this.form_t('something_went_wrong')
      this.messageType !== this.messageTypeEnum.FAILURE &&
        (this.messageType = this.messageTypeEnum.FAILURE)
      this.isSubmitting = false
      return false
    },
  },
}
