import {
  SHOW_MODAL,
  HIDE_MODAL,
  MODAL_LIST,
  IS_MODAL_REMOVAL_PENDING,
  TRIGGER_MODAL_REMOVAL,
  DROP_MODAL,
  EMPTY_ARRAY,
  CURRENT_MODAL,
 Digit,
} from '@/constants'
import { setBodyOverflow, isEmpty, head } from '@/helpers'

const modalListInitialValue = EMPTY_ARRAY
const isModalRemovalPendingInitialValue = false

export const modal = {
  namespaced: true,
  state: {
    [MODAL_LIST]: modalListInitialValue,
    [IS_MODAL_REMOVAL_PENDING]: isModalRemovalPendingInitialValue,
  },
  mutations: {
    [SHOW_MODAL](state, { component, isStrict = false }) {
      const lastModal = state[MODAL_LIST].slice(-Digit.ONE).pop()

      if (lastModal && lastModal?.component === component) {
        return
      }

      state[MODAL_LIST] = [...state[MODAL_LIST], { component, isStrict }]
      setBodyOverflow({ hidden: true })
    },
    [HIDE_MODAL](state) {
      const newModalList = [...state[MODAL_LIST]]
      newModalList.shift()

      state[IS_MODAL_REMOVAL_PENDING] = isModalRemovalPendingInitialValue
      state[MODAL_LIST] = newModalList
      isEmpty(newModalList) && setBodyOverflow()
    },
    [TRIGGER_MODAL_REMOVAL](state) {
      state[IS_MODAL_REMOVAL_PENDING] = true
    },
    [DROP_MODAL](state) {
      state[MODAL_LIST] = modalListInitialValue
      state[IS_MODAL_REMOVAL_PENDING] = isModalRemovalPendingInitialValue
    },
  },
  getters: {
    [CURRENT_MODAL]: (state) => {
      return head(state[MODAL_LIST])
    },
  },
}
