// TODO: investigate to fix "no-useless-escape" error
//eslint-disable-next-line
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,30}$/

//eslint-disable-next-line
export const REGEX_PRE_FILE_URI = /^.*[\\\/]/
//eslint-disable-next-line
export const REGEX_PRE_LAST_SLASH = /^.*[\\\/]/
export const REGE_FILE_EXTENSION = /\.[0-9a-z]+$/i
export const REGEX_WITHIN_BRACKETS = /\((.*?)\)/
