export const RoutePath = {
  PAGE_404: '*',
  LOCALE: '/:lang',
  BACKOFFICE_SUPPORT_ACCESS: 'backoffice/support/access',
  DASHBOARD: 'dashboard',
  DEPOSIT: 'deposit',
  FORGOT_YOUR_PASSWORD: 'forgot-your-password',
  Game: {
    ROOT: 'game',
    CATEGORY: 'game/category/:slug',
    DEMO: 'game/demo/:slug',
    FREE_PLAY: 'game/free-play',
    LISTS: 'game/lists/:slug', //500
    NEW_GAMES: 'game/new-games',
    PLAY: 'game/play/:slug',
    TOP_GAMES: 'game/top-games',
    VENDOR: 'game/vendor/:slug',
    INFO: 'game/info/:slug',
  },
  HOMEPAGE: '/',
  LOGIN: 'login',
  NEED_HELP: 'need-help',
  OFFERS: 'offers',
  OFFERS_VIEW: 'offers/view/:slug',
  PLATINCLUB: 'platinclub',
  PNP_ACCOUNT: 'pnp/account',
  Registration: {
    ROOT: 'registration',
    CONFIRM: 'registration/confirm',
    SUCCESS: 'registration/success',
  },
  RESEND_YOUR_CONFIRMATION_EMAIL: 'resend-your-confirmation-mail',
  REWARD_CARNIVAL: 'rewards-carnival',
  SEARCH_RESULT: 'search-result',
  SUPPORT: 'support/:slug',
  User: {
    ROOT: 'profile',
    ACCOUNT: 'account',
    ACCOUNT_BALANCE: 'account-balance',
    ACCOUNT_VERIFY: 'account/verify',
    BONUS_CANCEL: 'bonus/cancel',
    CHANGE_PASSWORD: 'change-password',
    DEPOSIT_HISTORY: 'deposit/history',
    DOCUMENTS: 'documents',
    EDIT: 'edit',
    GAME_ROUNDS: 'game-rounds',
    LIMITS: 'limits',
    LIMITS_EDIT: 'limits/:type',
    NEWSLETTER: 'newsletter',
    SELF_EXCLUSION: 'self-exclusion',
    TRANSACTIONS: 'transactions',
    USER_NOTIFICATIONS: 'userNotifications',
  },
  USER_REGISTER_PROGRESS: 'user-register-progress',
  Withdrawal: {
    ROOT: 'withdrawal',
    ADD: 'withdrawal/add',
    HISTORY: 'withdrawal/history',
    STATUS: 'withdrawal/status',
  },
}
