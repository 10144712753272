import { Type } from '../../../constants'

/**
 * @param {any} value
 * @returns {boolean} true in case the value is function
 **/

export const isFunction = (value) => {
  return typeof value === Type.FUNCTION
}

export const isNotFunction = (value) => !isFunction(value);
