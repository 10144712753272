import { Digit, EventType } from '@/constants'
import { getVisibleGameCardNumber, isNotNil, generateUUID } from '@/helpers'

const cardsPerScreenContainer = 'CARDS_PER_SCREEN_CONTAINER'

export const cardsPerScreenCalculationMixin = {
  data: () => ({
    containerWidth: Digit.NOUGHT,
  }),
  computed: {
    itemsPerScreen() {
      return getVisibleGameCardNumber(this.containerWidth)
    },
    ref: () => `${cardsPerScreenContainer}${generateUUID()}`,
  },
  methods: {
    calculateContainerWidth() {
      const containerWidth = this.$refs?.[this.ref]?.offsetWidth

      if (isNotNil(containerWidth)) {
        this.containerWidth = containerWidth
      }
    },
  },
  mounted() {
    this.calculateContainerWidth()
    window.addEventListener(EventType.RESIZE, this.calculateContainerWidth)
  },
  beforeDestroy() {
    window.removeEventListener(EventType.RESIZE, this.calculateContainerWidth)
  },
}
