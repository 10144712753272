import { Type } from '../../../constants'

/**
 * @param {any} value
 * @returns {boolean} true in case the value is boolean
 **/

export const isBoolean = (value) => {
  return typeof value === Type.BOOLEAN
}
