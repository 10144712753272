const API = '/api'

const API_OPEN = `${API}/open`
const API_OPEN_GAME = `${API_OPEN}/game`
const API_OPEN_MAIN = `${API_OPEN}/main`
const API_OPEN_OFFERS = `${API_OPEN}/offers`
const API_OPEN_REWARDS = `${API_OPEN}/rewards`
const API_OPEN_USER = `${API_OPEN}/user`

const API_AUTH = `${API}/auth`
const API_AUTH_GAME = `${API_AUTH}/game`
const API_AUTH_DEPOSIT = `${API_AUTH}/deposit`
const API_AUTH_USER = `${API_AUTH}/user`
const API_AUTH_WITHDRAWAL = `${API_AUTH}/withdrawal`

export const Endpoint = {
  IP: 'https://api.ipify.org',

  API_LOGIN: `${API}/login`,
  API_LOGOUT: `${API}/logout`,
  API_LOGIN_CHECK: `${API}/login_check`,

  API_AUTH_GAME_ADD_TO_FAVOURITE: `${API_AUTH_GAME}/add-to-favorite/:id`,
  API_AUTH_GAME_REMOVE_FROM_FAVOURITE: `${API_AUTH_GAME}/remove-from-favorite/:id`,
  API_AUTH_GAME_PLAY: `${API_AUTH_GAME}/play/:urlSlug`,
  API_AUTH_GAME_STOP: `${API_AUTH_GAME}/stop/:urlSlug`,
  API_AUTH_GAME_RATING: `${API_AUTH_GAME}/rating`,

  API_AUTH_DEPOSIT_ADD: `${API_AUTH_DEPOSIT}/add`,
  API_AUTH_DEPOSIT_DETAILS: `${API_AUTH_DEPOSIT}/details/:token`,
  API_AUTH_DEPOSIT_EXAMPLE_AMOUNT: `${API_AUTH_DEPOSIT}/example-amount`,

  API_AUTH_PAY_AND_PLAY_SESSION_DETAILS: '/api/auth/pay-n-play/session-details',

  API_AUTH_USER_BROWSER_HISTORY: `${API_AUTH_USER}/browser-history`,
  API_AUTH_USER_CASINO_INFO: `${API_AUTH_USER}/casino-info`,
  API_AUTH_USER_CHANGE_PASSWORD: `${API_AUTH_USER}/change-password`,
  API_AUTH_USER_CONTROL: `${API_AUTH_USER}/control`,
  API_AUTH_USER_DEPOSIT: `${API_AUTH_USER}/deposits`,
  API_AUTH_USER_DEPOSIT_METHODS: `${API_AUTH_USER}/deposit/methods`,
  API_AUTH_USER_DOCUMENTS: `${API_AUTH_USER}/documents`,
  API_AUTH_USER_DOCUMENTS_TYPES: `${API_AUTH_USER}/documents/types`,
  API_AUTH_USER_EDIT: `${API_AUTH_USER}/edit`,
  API_AUTH_USER_GAME_ROUNDS: `${API_AUTH_USER}/game-rounds`,
  API_AUTH_USER_INFO: `${API_AUTH_USER}/info`,
  API_AUTH_USER_LIMITS: `${API_AUTH_USER}/limits`,
  API_AUTH_USER_LIMITS_TYPE: `${API_AUTH_USER}/limits-type/:type`,
  API_AUTH_USER_LOGOUT: `${API_AUTH_USER}/logout`,
  API_AUTH_USER_NEWSLETTER: `${API_AUTH_USER}/newsletter`,
  API_AUTH_USER_NOTIFICATION_LIST: `${API_AUTH_USER}/notifications`,
  API_AUTH_USER_REALITY_CHECK: `${API_AUTH_USER}/reality-check`,
  API_AUTH_USER_REALITY_CONFIRM: `${API_AUTH_USER}/reality-confirm`,
  API_AUTH_USER_REALITY_REJECT: `${API_AUTH_USER}/reality-reject`,
  API_AUTH_USER_TRANSACTIONS: `${API_AUTH_USER}/transactions`,
  API_AUTH_USER_SELF_EXCLUSION: `${API_AUTH_USER}/self-exclusion`,
  API_AUTH_USER_SELF_EXCLUSION_REASONS: `${API_AUTH_USER}/self-exclusion/reasons`,
  API_AUTH_USER_SELF_EXCLUSION_PERIODS: `${API_AUTH_USER}/self-exclusion/periods`,
  API_AUTH_WITHDRAWAL_ADD: `${API_AUTH_WITHDRAWAL}/add`,
  API_AUTH_WITHDRAWAL_DETAILS: `${API_AUTH_WITHDRAWAL}/details/:id`,
  API_AUTH_WITHDRAWAL_METHODS: `${API_AUTH_WITHDRAWAL}/methods`,

  API_FIXTURE_USER_TRANSACTIONS: `${API}/fixtures/user/transactions`,

  API_OPEN_CMS_TREE: `/api/open/cms/tree/:type`,
  API_OPEN_CMS_PAGE_DETAILS: '/api/open/cms/page/details/:urlSlug',
  API_OPEN_PAY_N_PLAY_DEPOSIT: '/api/open/pay-n-play/deposit',

  API_OPEN_GAME_CATEGORIES: `${API_OPEN_GAME}/categories`,
  API_OPEN_GAME_CATEGORY: `${API_OPEN_GAME}/category/:param`,
  API_OPEN_GAME_DEMO: `${API_OPEN_GAME}/demo/:urlSlug`,
  API_OPEN_GAME_FAVOURITES: `${API_OPEN_GAME}/favourites`,
  API_OPEN_GAME_FREE_PLAY: `${API_OPEN_GAME}/free-play`,
  API_OPEN_GAME_DETAILS: `${API_OPEN_GAME}/details/:slugName`,
  API_OPEN_GAME_INFO: `${API_OPEN_GAME}/info/:urlSlug`,
  API_OPEN_GAME_NEW: `${API_OPEN_GAME}/new`,
  API_OPEN_GAME_LIST: `${API_OPEN_GAME}/list`,
  API_OPEN_GAME_LIVE_CASINO: `${API_OPEN_GAME}/live-casino`,
  API_OPEN_GAME_NOTIFICATIONS: `${API_OPEN_GAME}/notifications`,
  API_OPEN_GAME_RATING_ID: `${API_OPEN_GAME}/rating/:id`,
  API_OPEN_GAME_RECENTLY_PLAYED: `${API_OPEN_GAME}/recently-played`,
  API_OPEN_GAME_RECOMMENDED: `${API_OPEN_GAME}/recommended`,
  API_OPEN_GAME_SEARCH: `${API_OPEN_GAME}/search`,
  API_OPEN_GAME_SLOTS: `${API_OPEN_GAME}/slots`,
  API_OPEN_GAME_TOP: `${API_OPEN_GAME}/top`,
  API_OPEN_GAME_VENDOR: `${API_OPEN_GAME}/vendor/:id`,
  API_OPEN_GAME_VENDORS: `${API_OPEN_GAME}/vendors`,
  API_OPEN_GAME_VENDORS_WITH_GAMES: `${API_OPEN_GAME}/vendors-with-games`,

  API_OPEN_MAIN_DEFAULT_COUNTRY_FROM_IP_LOCATION: `${API_OPEN_MAIN}/default-country-from-ip-location`,
  API_OPEN_MAIN_GAME_FILTERS: `${API_OPEN_MAIN}/game-filters`,
  API_OPEN_MAIN_LIST_OF_COUNTRIES: `${API_OPEN_MAIN}/list-of-countries`,
  API_OPEN_MAIN_MGA_VERIFICATION: `${API_OPEN_MAIN}/mga-verification`,
  API_OPEN_MAIN_PAYMENT_PROVIDERS: `${API_OPEN_MAIN}/payment-providers`,
  API_OPEN_MAIN_REGISTRATION: `${API_OPEN_MAIN}/registration`,
  API_OPEN_MAIN_SEARCH_SIDEBAR: `${API_OPEN_MAIN}/search-sidebar`,
  API_OPEN_MAIN_SLIDE_SHOW: `${API_OPEN_MAIN}/slide-show`,

  API_OPEN_OFFERS_LIST: `${API_OPEN_OFFERS}/list`,

  API_OPEN_REWARDS_CATEGORIES: `${API_OPEN_REWARDS}/categories`,
  API_OPEN_REWARDS_LIST: `${API_OPEN_REWARDS}/list`,
  API_OPEN_REWARDS_SLIDE_SHOW: `${API_OPEN_REWARDS}/slide-show`,

  API_OPEN_SUPPORT_ACCESS: `${API_OPEN}/support/access/:token`,

  API_OPEN_USER_FORGER_PASSWORD: `${API_OPEN_USER}/forget-password`,
  API_OPEN_USER_LOGIN: `${API_OPEN_USER}/login`,
  API_OPEN_USER_REGISTRATION_TOKEN_VERIFICATION: `${API_OPEN_USER}/registration/token-verification`,
  API_OPEN_USER_RESEND_CONFIRMATION_EMAIL: `${API_OPEN_USER}/resend-confirmation-email`,
  API_OPEN_USER_VALID_EMAIL: `${API_OPEN_USER}/valid-email`,

  API_OPEN_VIP_CARDS: `${API_OPEN}/vip/cards`,
}
