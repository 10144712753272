import { GameApi } from '@/api'
import {
  GAME_NOTIFICATION_LIST,
  SET_GAME_NOTIFICATION_LIST,
  IS_GAME_NOTIFICATION_PENDING,
  SET_IS_GAME_NOTIFICATION_PENDING,
  ResponseState,
  REGEX_PRE_LAST_SLASH,
  REGE_FILE_EXTENSION,
  EMPTY_STRING,
} from '@/constants'
import { map } from '@/helpers'

const listInitialValue = []
const initialIsPendingValue = false
const ICON = '-Icon'

export const gameNotification = {
  namespaced: true,
  state: {
    [GAME_NOTIFICATION_LIST]: listInitialValue,
    [IS_GAME_NOTIFICATION_PENDING]: initialIsPendingValue,
  },
  mutations: {
    [SET_GAME_NOTIFICATION_LIST](state, { value }) {
      state[GAME_NOTIFICATION_LIST] = value
    },
    [SET_IS_GAME_NOTIFICATION_PENDING](state, { value }) {
      state[IS_GAME_NOTIFICATION_PENDING] = value
    },
  },
  actions: {
    async [SET_GAME_NOTIFICATION_LIST](context) {
      try {
        context.commit(SET_IS_GAME_NOTIFICATION_PENDING, { value: true })

        const { data, state } = await GameApi.getGameNotifications()

        if (state === ResponseState.OK) {
          const { items } = data

          const payload = {
            value: map((notification) => {
              const imageName = notification.icon
                .replace(REGEX_PRE_LAST_SLASH, EMPTY_STRING)
                .replace(REGE_FILE_EXTENSION, ICON)
                .toLowerCase()

              return {
                ...notification,
                icon: imageName,
              }
            })(items),
          }

          context.commit(SET_GAME_NOTIFICATION_LIST, payload)
          context.commit(SET_IS_GAME_NOTIFICATION_PENDING, {
            value: initialIsPendingValue,
          })
        }
      } catch (e) {
        console.dir(e)
      }
    },
  },
}
